type DeepUnwrap<T> =
  T extends Array<infer V>
    ? Array<DeepUnwrap<V>>
    : T extends object
      ? { [K in keyof T]: DeepUnwrap<T[K]> }
      : T

// Helper function to unwrap refs or return plain values
export function deepUnwrap<T>(value: T): DeepUnwrap<T> {
  if (isRef(value)) {
    return deepUnwrap(value.value) as DeepUnwrap<T>
  } else if (Array.isArray(value)) {
    return value.map((item) => deepUnwrap(item)) as DeepUnwrap<T>
  } else if (value !== null && typeof value === 'object') {
    return Object.fromEntries(
      Object.entries(value).map(([key, val]) => [key, deepUnwrap(val)])
    ) as DeepUnwrap<T>
  } else {
    return value as DeepUnwrap<T>
  }
}

// Helper function to create update sets for bulk updates
export function createUpdateSet<T>(record: T) {
  const updateSet: Record<string, unknown> = {}
  for (const key in record) {
    if (Object.prototype.hasOwnProperty.call(record, key)) {
      updateSet[key] = (eb: unknown) => eb.ref(`excluded.${key}`)
    }
  }
  return updateSet
}
