<template>
  <div
    v-if="props.status"
    style="display: flex; flex-direction: row; align-items: center">
    <!-- Indicator Dot -->
    <div style="margin-right: 6px">
      <SmTooltip
        v-if="showIndicator"
        :content="props.status?.text"
        :active="!showText">
        <div class="sm-status-indicator-dot" :class="props.status?.color"></div>
      </SmTooltip>
    </div>
    <TextMultiline v-if="showText">{{ props.status?.text }}</TextMultiline>
  </div>
</template>

<script setup lang="ts">
  export interface Status {
    color: string
    text: string
  }

  const props = defineProps({
    status: {
      type: Object as PropType<Status>,
      required: true,
    },
    showText: {
      type: Boolean,
      default: true,
      required: false,
    },
    showIndicator: {
      type: Boolean,
      default: true,
      required: false,
    },
  })
</script>

<style lang="scss">
  .sm-status-indicator-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
</style>
