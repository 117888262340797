<template>
  <div class="optimization-content">
    <span>{{ i18n.t('lastLogin') }}</span>
    <div v-if="props.license?.last_login_time">
      <span>{{
        displayDate(new Date(props.license.last_login_time), {
          dateStyle: 'short',
        })
      }}</span>
      <!-- How many days ago -->
      (<span style="color: var(--sm-magenta)"
        >{{ dayjs().diff(props.license?.last_login_time, 'day') }}
        {{
          i18n.t('days', {
            count: dayjs().diff(props.license?.last_login_time, 'day'),
          })
        }}</span
      >
      <span>)</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import dayjs from 'dayjs'

  import { useI18n } from 'vue-i18n'
  import { displayDate } from '@/common/util/timeUtils'

  import { LicenceOut, Optimization } from '@/client'

  const i18n = useI18n()

  const props = defineProps({
    license: {
      type: Object as PropType<LicenceOut>,
      required: true,
    },
    optimization: {
      type: Object as PropType<Optimization>,
      required: true,
    },
  })
</script>

<style lang="scss">
  .optimization-content {
    display: grid;
    grid-template-columns: 150px auto;
    gap: 0.4rem;
  }
</style>
