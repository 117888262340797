/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginationSortOrder } from '../models/PaginationSortOrder';
import type { Task } from '../models/Task';
import type { TaskPostIn } from '../models/TaskPostIn';
import type { TaskUpdatePatchIn } from '../models/TaskUpdatePatchIn';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TasksService {
    /**
     * Get Tasks
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static getTasksApiV1TasksGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        titleExists,
        titleEq,
        titleGt,
        titleGte,
        titleIn,
        titleLt,
        titleLte,
        titleNe,
        titleNin,
        title,
        descriptionExists,
        descriptionEq,
        descriptionGt,
        descriptionGte,
        descriptionIn,
        descriptionLt,
        descriptionLte,
        descriptionNe,
        descriptionNin,
        description,
        statusExists,
        statusEq,
        statusGt,
        statusGte,
        statusIn,
        statusLt,
        statusLte,
        statusNe,
        statusNin,
        status,
        backgroundJobIdsExists,
        backgroundJobIdsEq,
        backgroundJobIdsGt,
        backgroundJobIdsGte,
        backgroundJobIdsIn,
        backgroundJobIdsLt,
        backgroundJobIdsLte,
        backgroundJobIdsNe,
        backgroundJobIdsNin,
        backgroundJobIdsAll,
        backgroundJobIdsSize,
        backgroundJobIds,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        titleExists?: (boolean | null),
        titleEq?: (string | null),
        titleGt?: (string | null),
        titleGte?: (string | null),
        titleIn?: (Array<string> | null),
        titleLt?: (string | null),
        titleLte?: (string | null),
        titleNe?: (string | null),
        titleNin?: (Array<string> | null),
        title?: (string | null),
        descriptionExists?: (boolean | null),
        descriptionEq?: (string | null),
        descriptionGt?: (string | null),
        descriptionGte?: (string | null),
        descriptionIn?: (Array<string> | null),
        descriptionLt?: (string | null),
        descriptionLte?: (string | null),
        descriptionNe?: (string | null),
        descriptionNin?: (Array<string> | null),
        description?: (string | null),
        statusExists?: (boolean | null),
        statusEq?: (string | null),
        statusGt?: (string | null),
        statusGte?: (string | null),
        statusIn?: (Array<string> | null),
        statusLt?: (string | null),
        statusLte?: (string | null),
        statusNe?: (string | null),
        statusNin?: (Array<string> | null),
        status?: (string | null),
        backgroundJobIdsExists?: (boolean | null),
        backgroundJobIdsEq?: (string | null),
        backgroundJobIdsGt?: (string | null),
        backgroundJobIdsGte?: (string | null),
        backgroundJobIdsIn?: (Array<string> | null),
        backgroundJobIdsLt?: (string | null),
        backgroundJobIdsLte?: (string | null),
        backgroundJobIdsNe?: (string | null),
        backgroundJobIdsNin?: (Array<string> | null),
        backgroundJobIdsAll?: (Array<string> | null),
        backgroundJobIdsSize?: (number | null),
        backgroundJobIds?: (string | null),
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<Task>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tasks',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'title.$exists': titleExists,
                'title.$eq': titleEq,
                'title.$gt': titleGt,
                'title.$gte': titleGte,
                'title.$in': titleIn,
                'title.$lt': titleLt,
                'title.$lte': titleLte,
                'title.$ne': titleNe,
                'title.$nin': titleNin,
                'title': title,
                'description.$exists': descriptionExists,
                'description.$eq': descriptionEq,
                'description.$gt': descriptionGt,
                'description.$gte': descriptionGte,
                'description.$in': descriptionIn,
                'description.$lt': descriptionLt,
                'description.$lte': descriptionLte,
                'description.$ne': descriptionNe,
                'description.$nin': descriptionNin,
                'description': description,
                'status.$exists': statusExists,
                'status.$eq': statusEq,
                'status.$gt': statusGt,
                'status.$gte': statusGte,
                'status.$in': statusIn,
                'status.$lt': statusLt,
                'status.$lte': statusLte,
                'status.$ne': statusNe,
                'status.$nin': statusNin,
                'status': status,
                'background_job_ids.$exists': backgroundJobIdsExists,
                'background_job_ids.$eq': backgroundJobIdsEq,
                'background_job_ids.$gt': backgroundJobIdsGt,
                'background_job_ids.$gte': backgroundJobIdsGte,
                'background_job_ids.$in': backgroundJobIdsIn,
                'background_job_ids.$lt': backgroundJobIdsLt,
                'background_job_ids.$lte': backgroundJobIdsLte,
                'background_job_ids.$ne': backgroundJobIdsNe,
                'background_job_ids.$nin': backgroundJobIdsNin,
                'background_job_ids.$all': backgroundJobIdsAll,
                'background_job_ids.$size': backgroundJobIdsSize,
                'background_job_ids': backgroundJobIds,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Task
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static createTaskApiV1TasksPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: TaskPostIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tasks',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Tasks
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countTasksApiV1TasksCountGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        titleExists,
        titleEq,
        titleGt,
        titleGte,
        titleIn,
        titleLt,
        titleLte,
        titleNe,
        titleNin,
        title,
        descriptionExists,
        descriptionEq,
        descriptionGt,
        descriptionGte,
        descriptionIn,
        descriptionLt,
        descriptionLte,
        descriptionNe,
        descriptionNin,
        description,
        statusExists,
        statusEq,
        statusGt,
        statusGte,
        statusIn,
        statusLt,
        statusLte,
        statusNe,
        statusNin,
        status,
        backgroundJobIdsExists,
        backgroundJobIdsEq,
        backgroundJobIdsGt,
        backgroundJobIdsGte,
        backgroundJobIdsIn,
        backgroundJobIdsLt,
        backgroundJobIdsLte,
        backgroundJobIdsNe,
        backgroundJobIdsNin,
        backgroundJobIdsAll,
        backgroundJobIdsSize,
        backgroundJobIds,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        titleExists?: (boolean | null),
        titleEq?: (string | null),
        titleGt?: (string | null),
        titleGte?: (string | null),
        titleIn?: (Array<string> | null),
        titleLt?: (string | null),
        titleLte?: (string | null),
        titleNe?: (string | null),
        titleNin?: (Array<string> | null),
        title?: (string | null),
        descriptionExists?: (boolean | null),
        descriptionEq?: (string | null),
        descriptionGt?: (string | null),
        descriptionGte?: (string | null),
        descriptionIn?: (Array<string> | null),
        descriptionLt?: (string | null),
        descriptionLte?: (string | null),
        descriptionNe?: (string | null),
        descriptionNin?: (Array<string> | null),
        description?: (string | null),
        statusExists?: (boolean | null),
        statusEq?: (string | null),
        statusGt?: (string | null),
        statusGte?: (string | null),
        statusIn?: (Array<string> | null),
        statusLt?: (string | null),
        statusLte?: (string | null),
        statusNe?: (string | null),
        statusNin?: (Array<string> | null),
        status?: (string | null),
        backgroundJobIdsExists?: (boolean | null),
        backgroundJobIdsEq?: (string | null),
        backgroundJobIdsGt?: (string | null),
        backgroundJobIdsGte?: (string | null),
        backgroundJobIdsIn?: (Array<string> | null),
        backgroundJobIdsLt?: (string | null),
        backgroundJobIdsLte?: (string | null),
        backgroundJobIdsNe?: (string | null),
        backgroundJobIdsNin?: (Array<string> | null),
        backgroundJobIdsAll?: (Array<string> | null),
        backgroundJobIdsSize?: (number | null),
        backgroundJobIds?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tasks/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'title.$exists': titleExists,
                'title.$eq': titleEq,
                'title.$gt': titleGt,
                'title.$gte': titleGte,
                'title.$in': titleIn,
                'title.$lt': titleLt,
                'title.$lte': titleLte,
                'title.$ne': titleNe,
                'title.$nin': titleNin,
                'title': title,
                'description.$exists': descriptionExists,
                'description.$eq': descriptionEq,
                'description.$gt': descriptionGt,
                'description.$gte': descriptionGte,
                'description.$in': descriptionIn,
                'description.$lt': descriptionLt,
                'description.$lte': descriptionLte,
                'description.$ne': descriptionNe,
                'description.$nin': descriptionNin,
                'description': description,
                'status.$exists': statusExists,
                'status.$eq': statusEq,
                'status.$gt': statusGt,
                'status.$gte': statusGte,
                'status.$in': statusIn,
                'status.$lt': statusLt,
                'status.$lte': statusLte,
                'status.$ne': statusNe,
                'status.$nin': statusNin,
                'status': status,
                'background_job_ids.$exists': backgroundJobIdsExists,
                'background_job_ids.$eq': backgroundJobIdsEq,
                'background_job_ids.$gt': backgroundJobIdsGt,
                'background_job_ids.$gte': backgroundJobIdsGte,
                'background_job_ids.$in': backgroundJobIdsIn,
                'background_job_ids.$lt': backgroundJobIdsLt,
                'background_job_ids.$lte': backgroundJobIdsLte,
                'background_job_ids.$ne': backgroundJobIdsNe,
                'background_job_ids.$nin': backgroundJobIdsNin,
                'background_job_ids.$all': backgroundJobIdsAll,
                'background_job_ids.$size': backgroundJobIdsSize,
                'background_job_ids': backgroundJobIds,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Task
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static getTaskApiV1TasksTaskIdGet({
        taskId,
        includeDeleted = false,
        xTransactionId,
    }: {
        taskId: string,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tasks/{task_id}',
            path: {
                'task_id': taskId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Task
     * @returns any Successful Response
     * @throws ApiError
     */
    public static patchTaskApiV1TasksTaskIdPatch({
        taskId,
        requestBody,
        includeDeleted = false,
        xTransactionId,
    }: {
        taskId: string,
        requestBody: TaskUpdatePatchIn,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/tasks/{task_id}',
            path: {
                'task_id': taskId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Task
     * @returns void
     * @throws ApiError
     */
    public static deleteTaskApiV1TasksTaskIdDelete({
        taskId,
        includeDeleted = false,
        xTransactionId,
    }: {
        taskId: string,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/tasks/{task_id}',
            path: {
                'task_id': taskId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
