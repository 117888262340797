<template>
  <DeleteConfirmDialog
    v-model:visible="visible"
    :title="i18n.t('abortJob')"
    :sub-title="i18n.t('abortJobConfirmation')"
    :loading="abortLoading"
    :delete-btn-label="abortBtnLabel"
    @cancel="closeDialog"
    @delete="handleAbort">
    <SmDialogMessage
      :message="abortMsg"
      :visible="showAbortResponse"
      :type="abortType"
      class="mb-4" />
    <span>{{ i18n.t('abortJobConfirmation') }}</span>
  </DeleteConfirmDialog>
</template>

<script lang="ts" setup>
  import { ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { DialogMessageTypes } from './sm/SmDialogMessage.vue'
  import { backgroundjobStore } from '@/stores/backgroundjob.store'

  const i18n = useI18n()

  // State variables
  const abortLoading = ref(false)
  const abortDone = ref(false)
  const showAbortResponse = ref(false)
  const abortMsg = ref('')
  const abortType = ref<DialogMessageTypes>('success')
  const abortBtnLabel = ref(i18n.t('confirm'))

  const visible = defineModel('visible', {
    type: Boolean,
    default: true,
  })

  const emit = defineEmits(['aborted'])

  const props = defineProps<{ jobId: string }>()

  // Methods
  function abortJob() {
    abortLoading.value = true

    backgroundjobStore
      .abortBackgroundjob(props.jobId)
      .then((res) => {
        abortMsg.value = i18n.t('abortJobSuccess')
        abortType.value = 'success'
        showAbortResponse.value = true
        abortLoading.value = false
        abortDone.value = true
        abortBtnLabel.value = i18n.t('close')

        emit('aborted', res.data)
      })
      .catch(() => {
        abortMsg.value = i18n.t('abortJobFailed')
        abortType.value = 'error'
        showAbortResponse.value = true
        abortLoading.value = false
      })
  }

  function handleAbort() {
    if (!abortDone.value) {
      abortJob()
    } else {
      closeDialog()
    }
  }

  function closeDialog() {
    visible.value = false
  }

  watch(
    () => visible.value,
    (val) => {
      if (!val) {
        resetDialogState()
      }
    }
  )

  function resetDialogState() {
    abortDone.value = false
    showAbortResponse.value = false
    abortLoading.value = false
    abortMsg.value = ''
    abortType.value = 'success'
    abortBtnLabel.value = i18n.t('confirm')
  }
</script>
