<template>
  <div class="container">
    <div
      class="quota-bar-background flex w-full flex-row overflow-hidden bg-gray-200">
      <template v-for="item in formattedItems" :key="item.id">
        <span
          class="quota-bar border-r-gray-100"
          :style="{
            width: `${getWidth(item.quota)}%`,
            backgroundColor: `var(${item.color})`,
          }">
          <SmTooltip>
            <template #content>
              <p>{{ item.name }}: {{ item.quota }}</p>
            </template>
            <span style="display: block; width: 100%; height: 12px"></span>
          </SmTooltip>
        </span>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { SoftwareLicense } from '@/client'
  import { PriceInformationChartItem } from './LicenseModelAnalytics.vue'

  export type LicenseModelFormModel = {
    license: SoftwareLicense
  }

  export type Props = {
    items: Array<PriceInformationChartItem>
    totalQuota?: number
  }

  const props = withDefaults(defineProps<Props>(), {})
  const formattedItems = computed(() => {
    if (leftoverQuota.value && fallbackItem.value) {
      // fallback price is defined, add it
      return [
        ...props.items.filter((item) => item.quota && item.quota > 0),
        {
          ...fallbackItem.value,
          quota: (props.totalQuota ?? 0) - sumAllItemQuota.value,
        },
      ]
    } else {
      // everything is assigned, retrurn w/o fallback items
      return props.items.filter((item) => item.quota && item.quota > 0)
    }
  })

  const sumAllItemQuota = computed(() => {
    return props.items.reduce((acc, item) => {
      return acc + (item.quota ?? 0)
    }, 0)
  })

  const leftoverQuota = computed(() => {
    if (!props.totalQuota) return null
    else if (sumAllItemQuota.value >= props.totalQuota) return null
    else {
      return props.totalQuota - sumAllItemQuota.value
    }
  })

  const fallbackItem = computed(() => {
    const zeroQuotaItems = props.items.filter((item) => item.quota === 0)

    if (zeroQuotaItems.length > 0) {
      return zeroQuotaItems.reduce((cheapest, current) =>
        current.costs < cheapest.costs ? current : cheapest
      )
    }
  })

  function getWidth(size: number | null) {
    return ((size ?? 0) / (props.totalQuota || 1)) * 100
  }
</script>

<style scoped lang="scss">
  .quota-bar {
    &:last-child {
      border-right: unset;
    }

    transition: width 0.3s ease;
  }

  .dark .quota-bar-background {
    background: theme('colors.gray.700');
  }

  .dark .container {
    background: theme('colors.gray.800');
  }

  .dark .quota-bar {
    border-color: theme('colors.gray.800');
  }
  .dark .quota-bar-sync {
    background-color: theme('colors.gray.600');
  }
</style>
