import { SoftwareOut, SoftwareTypes } from '@/client/index.js'
import { db } from './database.js'

export interface SoftwareOutTable {
  tags: string // JSON string representing an array of strings
  created: string // ISO 8601 formatted date-time string
  updated: string // ISO 8601 formatted date-time string
  deleted: number // Boolean stored as integer (0 or 1)
  deleted_at: string | null // Nullable ISO 8601 date-time string
  _id: string // Unique identifier, possibly a UUID
  software_name: string // Internal name of the software
  desc_short: string | null
  display_name: string // Display name of the software
  type: string | null // SoftwareTypes as a string
  read_only: number // Boolean stored as integer (0 or 1)
  desc: string | null
  default_licenses: string // JSON string representing an array of strings
  data: string | null // JSON string or null
  notes: string | null
  sub_accounts: string // JSON string representing a Record<string, SubAccount_Output>
  licence_groups: string // JSON string representing a Record<string, LicenceGroup>
  connected_account: string | null
  authentication_expired: number // Boolean stored as integer (0 or 1)
  license_features: string // JSON string representing a Record<string, SoftwareLicenseFeature_Output>
  config: string // JSON string representing SoftwareConfig
  awaits_authorization: number // Boolean stored as integer (0 or 1)
  information: string // JSON string representing SoftwareInformation
  sso_provider: number // Boolean stored as integer (0 or 1)
}

export async function createSoftwareOutTable() {
  await db.schema
    .createTable('application')
    .ifNotExists()
    .addColumn('_id', 'text', (col) => col.primaryKey().notNull())
    .addColumn('tags', 'text', (col) => col.notNull()) // JSON string
    .addColumn('created', 'text', (col) => col.notNull()) // ISO 8601 string
    .addColumn('updated', 'text', (col) => col.notNull()) // ISO 8601 string
    .addColumn('deleted', 'integer', (col) => col.notNull()) // Boolean as integer
    .addColumn('deleted_at', 'text') // Nullable ISO 8601 string
    .addColumn('software_name', 'text', (col) => col.notNull())
    .addColumn('desc_short', 'text')
    .addColumn('display_name', 'text', (col) => col.notNull())
    .addColumn('type', 'text') // Nullable string
    .addColumn('read_only', 'integer', (col) => col.notNull()) // Boolean as integer
    .addColumn('desc', 'text')
    .addColumn('default_licenses', 'text', (col) => col.notNull()) // JSON string
    .addColumn('data', 'text') // JSON string or null
    .addColumn('notes', 'text')
    .addColumn('sub_accounts', 'text', (col) => col.notNull()) // JSON string
    .addColumn('licence_groups', 'text', (col) => col.notNull()) // JSON string
    .addColumn('connected_account', 'text') // Nullable string
    .addColumn('authentication_expired', 'integer', (col) => col.notNull()) // Boolean as integer
    .addColumn('license_features', 'text', (col) => col.notNull()) // JSON string
    .addColumn('config', 'text', (col) => col.notNull()) // JSON string
    .addColumn('awaits_authorization', 'integer', (col) => col.notNull()) // Boolean as integer
    .addColumn('information', 'text', (col) => col.notNull()) // JSON string
    .addColumn('sso_provider', 'integer', (col) => col.notNull().defaultTo(0)) // Boolean as integer
    .execute()

  // Index auf der Spalte 'software_name' erstellen
  await db.schema
    .createIndex('idx_software_out_name')
    .ifNotExists()
    .on('application')
    .column('software_name')
    .execute()
}

// Helper function to serialize SoftwareOut for database storage
export function serializeSoftwareOut(software: SoftwareOut): SoftwareOutTable {
  return {
    ...software,
    tags: JSON.stringify(software.tags),
    deleted: software.deleted ? 1 : 0,
    deleted_at: software.deleted_at ?? null,
    read_only: software.read_only ? 1 : 0,
    default_licenses: JSON.stringify(software.default_licenses),
    data: software.data ? JSON.stringify(software.data) : null,
    sub_accounts: JSON.stringify(software.sub_accounts),
    licence_groups: JSON.stringify(software.licence_groups),
    authentication_expired: software.authentication_expired ? 1 : 0,
    license_features: JSON.stringify(software.license_features),
    config: JSON.stringify(software.config),
    awaits_authorization: software.awaits_authorization ? 1 : 0,
    information: JSON.stringify(software.information),
    sso_provider: software.sso_provider ? 1 : 0,
  }
}

// Helper function to deserialize database record into SoftwareOut
export function deserializeSoftwareOut(record: SoftwareOutTable): SoftwareOut {
  return {
    ...record,
    tags: JSON.parse(record.tags),
    deleted: Boolean(record.deleted),
    deleted_at: record.deleted_at ?? null,
    read_only: Boolean(record.read_only),
    default_licenses: JSON.parse(record.default_licenses),
    data: record.data ? JSON.parse(record.data) : null,
    sub_accounts: JSON.parse(record.sub_accounts),
    licence_groups: JSON.parse(record.licence_groups),
    authentication_expired: Boolean(record.authentication_expired),
    license_features: JSON.parse(record.license_features),
    config: JSON.parse(record.config),
    awaits_authorization: Boolean(record.awaits_authorization),
    information: JSON.parse(record.information),
    sso_provider: Boolean(record.sso_provider),
    type: record.type as SoftwareTypes | null, // Cast `type` to the correct type
  }
}
