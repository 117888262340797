import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n } from 'vue-i18n'
import { usePreferenceStore } from '@/stores/preferenceStore'

let store = null
const localPreferences = JSON.parse(localStorage.getItem('preferences') || '{}')

try {
  store = usePreferenceStore()
} catch (error) {
  store = null
}

const locale = computed(() => {
  if (store !== null && store.preferences?.language !== undefined)
    return store.preferences.language
  if (localPreferences.language !== undefined) return localPreferences.language
  return 'en'
})

if (messages) {
  messages['de']['activity'] = messages['activity-de']
  messages['en']['activity'] = messages['activity-en']
}

const i18n = createI18n({
  locale: locale.value,
  fallbackLocale: 'en',
  legacy: false,
  globalInjection: true,
  messages,
})

export default i18n
