import { SelectQueryBuilder } from 'kysely'
import { Database } from './database'

export type ApplicationFilter = {
  id?: string
  displayName?: string
  updatedAfter?: string
  isDeleted?: boolean
  createdAfter?: string
  createdBefore?: string
  updatedBefore?: string
  deletedBefore?: string
  deletedAfter?: string
}

export function applyApplicationFilters<T>(
  query: SelectQueryBuilder<Database, 'application', T>,
  filters: ApplicationFilter
): SelectQueryBuilder<Database, 'application', T> {
  if (filters.id) {
    query = query.where('_id', '=', filters.id)
  }
  if (filters.createdBefore) {
    query = query.where('created', '<', filters.createdBefore)
  }
  if (filters.createdAfter) {
    query = query.where('created', '>', filters.createdAfter)
  }
  if (filters.updatedBefore) {
    query = query.where('updated', '<', filters.updatedBefore)
  }
  if (filters.updatedAfter) {
    query = query.where('updated', '>', filters.updatedAfter)
  }
  if (filters.deletedBefore) {
    query = query.where('deleted_at', '<', filters.deletedBefore)
  }
  if (filters.deletedAfter) {
    query = query.where('deleted_at', '>', filters.deletedAfter)
  }
  if (filters.isDeleted !== true) {
    query = query.where('deleted', '=', filters.isDeleted ? 1 : 0)
  }
  if (filters.displayName) {
    query = query.where('display_name', 'like', `%${filters.displayName}%`)
  }

  return query
}
