<template>
  <DeleteConfirmDialog
    v-model:visible="visible"
    :title="i18n.t('archiveTask')"
    :loading="archiveLoading"
    :delete-btn-label="archiveBtnLabel"
    @cancel="visible = false"
    @delete="archiveBtnFn()">
    <SmDialogMessage
      :message="archiveMsg"
      :visible="showArchiveResponse"
      :type="archiveType"
      class="mb-4" />
    <span>{{ i18n.t('archiveJobConfirmation') }}</span>
  </DeleteConfirmDialog>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'
  import { DialogMessageTypes } from './sm/SmDialogMessage.vue'
  import { taskStore } from '@/stores/task.store'
  import { backgroundjobStore } from '@/stores/backgroundjob.store'
  import { ref, watch } from 'vue'
  import { BackgroundjobFilterInstance } from '@/stores/backgroundjob.utils'
  import { BackgroundJobStatus } from '@/client'

  const i18n = useI18n()

  const archiveLoading = ref(false)
  const archiveDone = ref(false)
  const showArchiveResponse = ref(false)
  const archiveMsg = ref('')
  const archiveType = ref<DialogMessageTypes>('success')
  const archiveBtnLabel = ref(i18n.t('confirm'))

  const activeJobs = computed(async () => {
    const filters = ref<BackgroundjobFilterInstance[]>([])
    const idFilter = new backgroundjobStore.filter.IdFilter(
      props.backgroundJobIds
    )
    const statusFilter = new backgroundjobStore.filter.StatusFilter([
      BackgroundJobStatus.ENQUEUED,
      BackgroundJobStatus.PAUSED,
    ])

    filters.value.push(idFilter, statusFilter)
    const backgroundJobs = await backgroundjobStore.getBackgroundjobs(
      filters.value
    )

    return backgroundJobs || []
  })

  const visible = defineModel('visible', {
    type: Boolean,
    default: true,
  })

  const emit = defineEmits(['archived'])

  const props = defineProps<{
    taskId: string
    backgroundJobIds: Array<string>
  }>()

  async function archiveTask() {
    archiveLoading.value = true

    await abortActiveJobs()

    taskStore
      .archiveTask(props.taskId)
      .then(() => {
        archiveMsg.value = i18n.t('archiveTaskSuccess')
        showArchiveResponse.value = true
        archiveLoading.value = false
        archiveDone.value = true
        archiveBtnLabel.value = i18n.t('close')

        emit('archived')
      })
      .catch(() => {
        archiveMsg.value = i18n.t('abortJobFailed')
        archiveType.value = 'error'
        showArchiveResponse.value = true
        archiveLoading.value = false
      })
  }

  async function abortActiveJobs() {
    if (!activeJobs.value) return

    const promises = []
    for (const job of await activeJobs.value) {
      promises.push(backgroundjobStore.abortBackgroundjob(job._id))
    }
    await Promise.allSettled(promises)
  }

  function archiveBtnFn() {
    if (!archiveDone.value) {
      archiveTask()
    } else {
      visible.value = false
    }
  }

  watch(
    () => visible.value,
    (val) => {
      if (!val) {
        archiveDone.value = false
        showArchiveResponse.value = false
        archiveLoading.value = false
      }
    }
  )
</script>
