<template>
  <!-- View Group -->
  <SmDrawer v-model:visibility="viewDialogVisible">
    <template #title>
      <h2 v-if="editGroup">{{ editGroup.name }}</h2>
    </template>
    <GroupDisplayDescription v-if="editGroup" :group="editGroup" />

    <GroupDetailsContent
      :group="editGroup!"
      :all-applications="allApplications"
      :all-accounts="allAccounts"
      :all-tags="allTags"
      :all-software-licenses
      :all-price-informations
      @group-changed="loadGroups" />
  </SmDrawer>

  <!-- New Group -->
  <GroupAddDialog
    v-model:visibility="addGroupDialogVisible"
    v-model:account-group="editGroup"
    :all-price-informations
    :all-software-licenses
    mode="edit"
    @groups-changed="loadGroups" />

  <!-- Add User -->
  <GroupAddUserDialog
    ref="groupAddUserDialog"
    v-model:visibility="addUserDialogVisible"
    v-model:account-group="editGroup"
    :all-accounts="allAccounts"
    @groups-changed="loadGroups" />

  <!-- View Content -->
  <div class="home">
    <ViewHeader :title="i18n.t('groups')" />

    <!-- Search -->
    <div v-if="groups.length > 0">
      <SmInput
        v-model="searchTerm"
        outline
        label="Search"
        style="max-width: 400px; margin-left: auto; margin-bottom: 1rem"
        size="medium" />
    </div>

    <div v-if="groups.length > 0" v-auto-animate class="group-grid">
      <GroupCard
        v-for="group in filteredGroups"
        :key="group._id"
        :group="group"
        @view="handleView"
        @add-user="handleAddUser(group)"
        @edit="handleEdit(group)" />

      <GroupAddCard
        :all-price-informations
        :all-applications="allApplications"
        :all-software-licenses
        @groups-changed="loadGroups" />
    </div>

    <GroupsEmptyState
      v-else
      v-auto-animate
      :all-software-licenses
      :all-price-informations
      @groups-changed="loadGroups" />
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import {
    AccountGroup,
    Account,
    PriceInformation,
    SoftwareLicense,
    Tag,
    TagsService,
    UserGroupsService,
    SoftwareLicensesService,
  } from '@/client'
  import { useUserStore } from '@/stores/userStore'
  import { ApplicationStore } from '@/stores/application.store'

  const i18n = useI18n()

  const { data: allApplications } = ApplicationStore.useApplications()
  const allSoftwareLicenses = ref<SoftwareLicense[]>([])
  const allPriceInformations = ref<PriceInformation[]>([])
  const allAccounts = ref<Account[]>([])
  const allTags = ref<Tag[]>([])
  const groups = ref<AccountGroup[]>([])

  const userStore = useUserStore()

  // View
  const viewDialogVisible = ref(false)

  // Edit
  const addGroupDialogVisible = ref(false)

  const editGroup = ref<AccountGroup>()
  const mode = ref<'add' | 'edit'>('add')

  // Add user
  const addUserDialogVisible = ref(false)

  function handleAddUser(group: AccountGroup) {
    editGroup.value = group
    addUserDialogVisible.value = true
  }

  function handleEdit(group: AccountGroup) {
    editGroup.value = group
    addGroupDialogVisible.value = true
    mode.value = 'edit'
  }

  function handleView(group: AccountGroup) {
    editGroup.value = group
    viewDialogVisible.value = true
  }

  const searchTerm = ref('')
  const filteredGroups = computed(() => {
    if (!searchTerm.value) return groups.value
    return groups.value.filter((group) =>
      group.name.toLowerCase().includes(searchTerm.value.toLowerCase())
    )
  })

  function loadGroups() {
    UserGroupsService.getAccountGroupsApiV1ManagementGroupsGet({}).then(
      (res) => {
        groups.value = res.sort((a, b) => a.name.localeCompare(b.name))
        if (!editGroup.value) return
        editGroup.value = res.find((g) => g._id == editGroup.value?._id)
      }
    )
  }

  onMounted(() => {
    TagsService.getTagsApiV1ManagementTagsGet({}).then((tags) => {
      allTags.value = tags
    })

    const { data } = ApplicationStore.useApplications()
    allApplications.value = data.value

    SoftwareLicensesService.getSoftwareLicensesApiV1SoftwareSoftwareLicensesGet(
      {}
    ).then((licenses) => {
      allSoftwareLicenses.value = licenses
    })

    SoftwareLicensesService.getSoftwareLicensePriceInformationsApiV1SoftwareSoftwareLicensePriceInformationsGet(
      {}
    ).then((priceInformations) => {
      allPriceInformations.value = priceInformations
    })
    loadGroups()

    userStore.getUser().then((user) => {
      if (user) allAccounts.value = user
    })
  })
</script>

<style scoped lang="scss">
  .home {
    grid-template-rows: auto auto 1fr;
  }
  .group-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1.3rem;
    grid-auto-rows: max-content;
  }
</style>
