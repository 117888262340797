<template>
  <TaskCreateActionDialog
    v-model:visible="createJobVisible"
    :backgroundjob-type="createJobType"
    @save="handleSaveJob" />

  <TaskOffboardUserDialog
    v-model:visible="offboardUserDialogVisible"
    @save="
      (requests: Request[]) => {
        for (const request of requests) {
          handleSaveJob(request)
        }
      }
    " />

  <SmDialog
    v-model:visibility="visible"
    :title="props.taskId ? i18n.t('editTask') : i18n.t('createTask')"
    class="dialog"
    size="medium">
    <SmDialogMessage
      :message="submitResponseMsg"
      :visible="showSubmitResponse"
      :type="submitResponseType"
      class="mb-4" />

    <el-form label-position="top" class="grid grid-cols-2 gap-x-3">
      <el-form-item
        :required="true"
        :label="i18n.t('title')"
        :prop="`form.fields.title`"
        :error="getErrorMessage('title')"
        class="col-span-2">
        <SmInput
          v-model="form.fields.title"
          size="large"
          design="form"
          :disabled="submitDone" />
      </el-form-item>
      <el-form-item
        class="col-span-2"
        :label="i18n.t('description')"
        :prop="`form.fields.description`"
        :error="getErrorMessage('description')">
        <SmInput
          v-model="form.fields.description"
          design="form"
          type="area"
          :disabled="submitDone"
          outline />
      </el-form-item>
    </el-form>

    <div v-if="!taskId" class="job-selection">
      <h3 class="capitalize">{{ i18n.t('jobs') }}</h3>
      <p class="mb-3">
        {{ i18n.t('createJobDescription') }}
      </p>

      <SmDialogMessage
        :message="jobResponseMsg"
        :visible="showJobResponse"
        :type="jobResponseType"
        class="mb-4" />

      <div class="mb-3 flex flex-col gap-3">
        <BackgroundjobPreview
          v-for="[key, request] in jobRequestsArray"
          :key="key"
          :type="request.type"
          :data="request.data">
          <template #right>
            <SmButtonClose v-if="!submitDone" @close="removeJob(key)" />
          </template>
        </BackgroundjobPreview>
      </div>

      <SmSelect
        v-if="!submitDone"
        v-model:selection="jobSelection"
        :options="jobSelectOptions"
        :label="i18n.t('chooseJobType')"
        :item-size="66"
        size="large"
        class="w-full"
        @change="
          (value) => {
            if (value) addJob(value[0])
          }
        ">
        <template #trigger>
          <div class="flex h-10 items-center gap-4">
            <v-icon name="hi-plus" />
            <p>{{ i18n.t('createJob') }}</p>
          </div>
        </template>

        <template #item="{ item }">
          <div class="flex flex-col leading-6">
            <span>{{ item.label }}</span>
            <span class="text-contrast-muted">{{ item.description }}</span>
          </div>
        </template>
      </SmSelect>
    </div>

    <template #footer>
      <div class="submit-buttons">
        <template v-if="!submitDone">
          <SmButton size="small" outline @click="handleCancel">
            {{ i18n.t('general.cancel') }}
          </SmButton>

          <SmButton
            size="small"
            :loading="taskSubmitLoading"
            @click="handleSave">
            {{ i18n.t('general.save') }}
          </SmButton>
        </template>

        <SmButton v-else size="small" @click="handleCancel">
          {{ i18n.t('close') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import useForm from '@/common/form'
  import { useI18n } from 'vue-i18n'
  import { ValidationResult, isRequired } from './sm/SmInput/SmInputValidator'
  import { DialogMessageTypes } from './sm/SmDialogMessage.vue'
  import { Option } from './sm/SmSelect.vue'
  import {
    BackgroundJobSoftwareUserAddData,
    BackgroundJobSoftwareUserEnableData,
    BackgroundJobSoftwareUserLicenseAddData,
    BackgroundJobSoftwareUserLicenseRemoveData,
    BackgroundJobSoftwareUserRemoveData,
    BackgroundJobType,
    TaskPostIn,
  } from '@/client'
  import { getTaskStatus, TaskStatus } from '@/stores/task.utils'
  import { taskStore } from '@/stores/task.store'
  import {
    availableBackgroundJobTypes,
    ExtendedBackgroundJobType,
  } from '@/stores/backgroundjob.utils'
  import { Status } from './sm/SmStatus.vue'
  import { backgroundjobStore } from '@/stores/backgroundjob.store'
  import { uuid4 } from '@sentry/utils'

  const i18n = useI18n()

  const props = defineProps<{
    taskId: string
  }>()
  const emit = defineEmits(['close'])

  const visible = defineModel<boolean>('visible', { default: false })

  export interface Request<
    T extends
      | BackgroundJobSoftwareUserAddData
      | BackgroundJobSoftwareUserEnableData
      | BackgroundJobSoftwareUserLicenseAddData
      | BackgroundJobSoftwareUserLicenseRemoveData
      | BackgroundJobSoftwareUserRemoveData =
      | BackgroundJobSoftwareUserAddData
      | BackgroundJobSoftwareUserEnableData
      | BackgroundJobSoftwareUserLicenseAddData
      | BackgroundJobSoftwareUserLicenseRemoveData
      | BackgroundJobSoftwareUserRemoveData,
  > {
    type: BackgroundJobType
    enqueue_in: number
    data: T
  }

  const formEl = ref()
  const taskSubmitLoading = ref(false)
  const showSubmitResponse = ref(false)
  const submitResponseMsg = ref('')
  const submitResponseType = ref<DialogMessageTypes>('success')
  const showJobResponse = ref(false)
  const jobResponseMsg = ref('')
  const jobResponseType = ref<DialogMessageTypes>('success')
  const submitDone = ref(false)
  const fallbackStatus = 'backlog'

  const jobSelection = ref<Option<ExtendedBackgroundJobType>[]>([])
  const jobSelectOptions = computed(() => {
    const options: Option<ExtendedBackgroundJobType>[] = [
      ...unref(availableBackgroundJobTypes.value),
    ]

    // Add offboard user option
    if (!options.some((option) => option.value === 'offboard_user')) {
      options.push({
        value: 'offboard_user',
        label: i18n.t('jobtype.offboardUser.label'),
        description: i18n.t('jobtype.offboardUser.description'),
        section: i18n.t('views.templates.title'),
      })
    }

    // seperate disable and delete account option
    const index = options.findIndex(
      (option) => option.value === 'software_user_add'
    )
    if (index !== -1) {
      options.splice(
        index + 1,
        0,
        {
          value: 'delete_account',
          label: i18n.t('jobtype.delete_account.label'),
          description: i18n.t('jobtype.delete_account.description'),
          section: i18n.t('jobs'),
        },
        {
          value: 'disable_account',
          label: i18n.t('jobtype.disable_account.label'),
          description: i18n.t('jobtype.disable_account.description'),
          section: i18n.t('jobs'),
        }
      )
    }

    return options.filter((option) => option.value !== 'software_user_remove')
  })

  onMounted(async () => {
    // If taskId is set, load the task
    if (props.taskId) {
      const task = await taskStore.getTask(props.taskId)
      if (task) {
        form.fields.title = task.title ?? i18n.t('newTask')
        form.fields.description = task.description ?? undefined

        form.fields.status = [
          {
            label: 'i18n.t(`taskStatus.${task.status}`)',
            value: getTaskStatus(
              (task.status as TaskStatus) || (fallbackStatus as TaskStatus)
            ),
            key: task.status,
          },
        ]
      }
    }
  })

  /**
   * Functions
   */

  function handleCancel() {
    visible.value = false
    form.reset()
    formEl.value?.resetForm()
    submitDone.value = false
    emit('close')
  }

  async function handleSave() {
    if (submitDone.value) {
      handleCancel()
      return
    }

    // remove spaces from desription
    form.fields.description = form.fields.description?.trim()

    showSubmitResponse.value = false
    taskSubmitLoading.value = true

    if (jobRequestsArray.value.length > 0) {
      const executeJobs = await executeJobRequests()

      if (executeJobs) {
        const hasAllError = executeJobs.every((job) => job?.error !== null)
        const hasSomeError = executeJobs.some((job) => job?.error !== null)

        if (hasAllError) {
          jobResponseMsg.value = i18n.t(
            'error.background_jobs.failedToCreateJobs'
          )
          jobResponseType.value = 'error'
          showJobResponse.value = true
        } else if (hasSomeError) {
          jobResponseMsg.value = i18n.t(
            'error.background_jobs.failedToCreateSomeJobs'
          )
          jobResponseType.value = 'warning'
          showJobResponse.value = true
        }

        const backgroundJobIds = executeJobs
          .filter((job) => job?.error === null && job?.data !== null)
          .map((job) => (job.data ? job.data._id : null))
          .filter((id): id is string => id !== null) // Filter out any null values just in case

        form.fields.background_job_ids = backgroundJobIds
      }
    } else {
      form.fields.background_job_ids = []
    }

    await submitForm()
    taskSubmitLoading.value = false
  }

  /**
   * Form
   */

  const defaultStatus: Option<Status>[] = [
    {
      label: i18n.t(`taskStatus.${fallbackStatus}`),
      value: getTaskStatus(fallbackStatus),
      key: fallbackStatus,
    },
  ]

  const form = useForm(
    {
      title: i18n.t('newTask'),
      description: undefined as string | undefined,
      status: defaultStatus,
      background_job_ids: [] as Array<string>,
    },
    // Form Hooks
    {
      onReset: () => {},
    },

    // Form Validators
    {
      title: [isRequired],
      status: [isRequired],
    },
    {}
  )

  async function submitForm() {
    const validation = form.validate()

    if (!validation) {
      showSubmitResponse.value = true
      submitResponseMsg.value = i18n.t('error.general.data_invalid')
      submitResponseType.value = 'error'

      return
    }

    // get status id from object
    const requestbody: TaskPostIn = {
      ...form.fields,
      status: form.fields.status[0].key ?? fallbackStatus,
    }

    await form.submit(async () => {
      if (props.taskId) {
        delete requestbody.background_job_ids
        taskStore.updateTask(props.taskId, requestbody)
        submitResponseMsg.value = i18n.t(
          'notifications.tasksView.task.editSuccess'
        )
      } else {
        taskStore.createTask(requestbody)
        submitResponseMsg.value = i18n.t(
          'notifications.tasksView.task.createSuccess'
        )
        submitDone.value = true
      }
    })

    showSubmitResponse.value = true

    submitResponseType.value = 'success'
  }

  function getErrorMessage(fieldName: keyof TaskPostIn) {
    if (!((fieldName as string) in form.errors)) return ''
    const errors = form.errors[fieldName] as ValidationResult[]
    if (!errors || errors.length === 0 || !errors[0] || !errors[0].message)
      return ''
    const message = errors[0].message as string
    return message
  }

  /**
   * Create job Functions
   */

  const createJobType = ref<ExtendedBackgroundJobType>(
    BackgroundJobType.SOFTWARE_USER_ADD
  )
  const createJobVisible = ref(false)
  const offboardUserDialogVisible = ref(false)

  function addJob(option: Option<ExtendedBackgroundJobType>) {
    const isTemplateOffboarding =
      option.section === i18n.t('views.templates.title') &&
      option.value === 'offboard_user'

    if (option.section === i18n.t('jobs')) {
      createJobType.value = option.value
      createJobVisible.value = true
    } else if (isTemplateOffboarding) {
      offboardUserDialogVisible.value = true
    }

    // reset selection
    jobSelection.value = []
  }

  const jobRequests = ref(new Map())
  const jobRequestsArray = computed<[string, Request][]>(() =>
    Array.from(jobRequests.value.entries())
  )

  async function handleSaveJob(request: Request) {
    if (!request) return
    const { type, enqueue_in, data } = request
    const key = uuid4()

    jobRequests.value.set(key, { type, enqueue_in, data })
  }

  function removeJob(key: string) {
    jobRequests.value.delete(key)
  }

  async function executeJobRequests() {
    return await Promise.all(
      jobRequestsArray.value.map(
        async ([, { type, enqueue_in, data }]: [string, Request]) => {
          const response = await backgroundjobStore.createBackgroundjob(
            type,
            data,
            enqueue_in
          )
          return response
        }
      )
    )
  }

  /**
   * Watchers
   */

  watch(
    () => visible.value,
    (val) => {
      if (!val) handleCancel()
    }
  )
</script>

<style scoped>
  .submit-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
</style>
