<template>
  <div class="rounded bg-gray-100 p-4 dark:bg-gray-800">
    <div class="flex items-center pb-4">
      <p class="font-bold">{{ i18n.t('externalQuota') }}</p>
      <SmTooltip placement="top">
        <template #content>
          <p>
            {{
              i18n.t('infoExternalQuota', {
                number: props.externalQuota,
              })
            }}
          </p>
        </template>
        <v-icon
          name="md-help-round"
          scale="1"
          class="ml-4 fill-contrast-muted" />
      </SmTooltip>
    </div>

    <div class="flex items-center justify-between pb-2">
      <p>{{ i18n.t('detectedQuota') }}:</p>
      <p>{{ props.externalQuota }}</p>
    </div>

    <div
      v-if="fallbackContracts.length === 1"
      class="flex items-center justify-between pb-4">
      <p>{{ i18n.t('assignedPriceModel') }}:</p>
      <p>
        {{ cheapestFallbackContract?.description }}
      </p>
    </div>

    <template v-else-if="fallbackContracts.length === 0">
      <SmDialogMessage
        class="mb-4"
        type="warning"
        :message="i18n.t('pricemodelMessageNoFallback')"
        visible />
      <SmButton outline @click="emit('createFallback')">
        {{ i18n.t('createFallbackPriceModel') }}
      </SmButton>
    </template>

    <SmDialogMessage
      v-else-if="fallbackContracts.length > 1"
      type="warning"
      :message="i18n.t('pricemodelMessageMultipleFallbacks')"
      visible />
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { PriceInformation, SoftwareLicenseCost } from '@/client'

  const i18n = useI18n()
  const emit = defineEmits(['createFallback'])

  const props = defineProps({
    externalQuota: {
      type: Number,
      required: true,
    },
    contracts: {
      type: Object as PropType<PriceInformation[]>,
      required: true,
    },
    costs: {
      type: Object as PropType<SoftwareLicenseCost>,
      required: true,
    },
  })

  const fallbackContracts = computed(() => {
    return props.contracts.filter((contract) => contract.quota === 0)
  })

  function getPriceInformationCost(id: string) {
    if (props.costs) {
      return props.costs.price_information_costs[id].total_cost
    }
    return 0
  }

  const cheapestFallbackContract = computed(() => {
    if (fallbackContracts.value.length > 0) {
      return fallbackContracts.value.reduce((prev, current) => {
        return getPriceInformationCost(prev._id) <
          getPriceInformationCost(current._id)
          ? prev
          : current
      })
    }
    return null
  })
</script>
