<template>
  <div class="content">
    <!-- User -->
    <div class="user">
      <!-- Single user -->
      <div v-if="user?.length === 1" style="display: flex; align-items: center">
        <div class="user__avatar">
          <SmAvatar :name="user[0].name || user[0].email || '??'" />
        </div>
        <div class="user__information">
          <div class="user__name">
            {{ user[0].name }}
          </div>
          <div class="user__email">
            {{ user[0].email }}
          </div>
        </div>
      </div>

      <!-- Multiple users -->
      <div v-else style="display: flex; align-items: center">
        <div v-if="user" class="user__avatar">
          <SmAvatar :name="user?.length.toString()" />
        </div>

        <div class="user__information">
          <div class="user__name">
            {{ user?.length }} {{ i18n.t('person', { count: user?.length }) }}
          </div>
          <div class="user__email">
            {{ i18n.t('multipleLicenceChangesWarning') }}
          </div>
        </div>
      </div>
    </div>

    <!-- Message -->
    <SmDialogMessage
      v-if="result"
      :type="result.status"
      :message="result.message">
      {{ result.message }}
      <br />
      <!-- One time password -->
      <span
        v-if="
          result.response &&
          result.response?.application_specific &&
          result.response?.application_specific.default_password
        ">
        {{ i18n.t('initialPassword') }}:
        <b>
          {{ result.response?.application_specific.default_password }}</b
        ></span
      >
    </SmDialogMessage>
    <!-- License -->
    <div
      style="
        max-height: 350px;
        overflow: scroll;
        margin-top: 1.3rem;
        overflow-x: hidden;
      ">
      <!-- License Model Edit Table -->
      <LicenseModelEditTable
        v-if="user && software"
        ref="softwareLicenseEditTable"
        :license="license"
        filter-flatrate
        :software-licenses="softwareLicenses"
        :software="software"
        :user="user"
        :sub-account-id="subAccount?.id"
        @licenses-changed="emit('editedLicense')"
        @user-added="emit('userAdded')" />
    </div>

    <!-- Button -->
    <div class="button">
      <SmButton
        :disabled="!user"
        size="large"
        :loading="state === 'loading'"
        @click="handleButtonClick">
        <span v-if="state === 'selection'">{{ i18n.t('save') }}</span>
        <span v-else-if="state === 'loading'">{{
          i18n.t('licenseAddingInProcess')
        }}</span>
        <span v-else-if="state === 'done'">{{ i18n.t('close') }}</span>
      </SmButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    LicenceOut,
    SoftwareLicense,
    SoftwareOut,
    SubAccount_Output,
  } from '@/client'

  import LicenseModelEditTable from './LicenseModelEditTable.vue'
  import SmAvatar from './sm/SmAvatar.vue'

  const i18n = useI18n()

  const softwareLicenseEditTable =
    ref<ComponentExposed<typeof LicenseModelEditTable>>()

  const emit = defineEmits<{
    (e: 'userAdded'): void
    (e: 'close'): void
    (e: 'editedLicense'): void
  }>()

  defineProps({
    softwareLicenses: {
      type: Object as PropType<SoftwareLicense[]>,
      required: true,
    },
    software: {
      type: Object as PropType<SoftwareOut>,
      required: true,
    },
    user: {
      type: Object as PropType<Account[] | null>,
      required: true,
    },
    license: {
      type: Object as PropType<LicenceOut>,
      required: false,
      default: null,
    },
    subAccount: {
      type: Object as PropType<SubAccount_Output | null>,
      required: false,
      default: null,
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  })

  const state = computed(() => {
    return softwareLicenseEditTable.value?.state
  })

  const result = computed(() => {
    return softwareLicenseEditTable.value?.result
  })

  // Main Button actions
  function handleButtonClick() {
    if (state.value === 'done') {
      emit('close')
    } else {
      softwareLicenseEditTable.value?.execute()
    }
  }
</script>

<style lang="scss" scoped>
  .user {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    max-width: 100%;

    &__name {
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__avatar {
      margin-right: 1rem;
      border-radius: var(--border-radius-base);
      background-color: var(--sm-white);
    }
  }

  .button {
    width: 70%;
    margin: 30px auto 0 auto;
  }

  .licenses {
    .license {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;
    }

    width: 100%;
    margin-top: 0.5rem;
  }

  .alert {
    $message-padding: 0.5rem;
    margin: 1.5rem 0 0 0;
    padding: $message-padding;
    width: calc(100% - #{$message-padding * 2});
    border-radius: var(--border-radius-base);
    display: flex;
    align-items: center;

    &-success {
      border: 1px solid var(--sm-primary);
    }

    &-error {
      border: 1px solid var(--sm-magenta);
    }

    .message {
      margin-left: 0.5rem;
    }
  }
</style>
