import { PreferenceLanguages } from '@/client'
import { usePreferenceStore } from '@/stores/preferenceStore'
import { DateFormatter } from '@internationalized/date'
import dayjs from 'dayjs'

export function displayDate(
  date: Date,
  options: {
    dateStyle?: Intl.ResolvedDateTimeFormatOptions['dateStyle']
    timeStyle?: Intl.ResolvedDateTimeFormatOptions['timeStyle']
  } = { dateStyle: 'long', timeStyle: undefined }
) {
  const preferenceStore = usePreferenceStore()
  const languageFormat = ref('de-De')
  const tz = dayjs.tz.guess()

  if (preferenceStore.preferences?.language === PreferenceLanguages.EN) {
    languageFormat.value = 'en-US'
  }

  const df = new DateFormatter(languageFormat.value, {
    dateStyle: options.dateStyle,
    timeStyle: options.timeStyle,
  })

  const correctedDate = dayjs(date).tz(tz).toDate()

  return df.format(correctedDate)
}
