<template>
  <div class="rounded border border-contrast-muted p-4">
    <div class="flex w-full items-center justify-between gap-3">
      <div>
        <BackgroundjobMessage
          :type="props.type"
          :data="props.data"
          :clickable="true" />
      </div>
      <div>
        <slot name="right"></slot>
      </div>
    </div>
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    BackgroundJobAccountGroupAccountsAddedData,
    BackgroundJobAccountGroupAccountsRemovedData,
    BackgroundJobSoftwareUserAddData,
    BackgroundJobSoftwareUserEnableData,
    BackgroundJobSoftwareUserLicenseAddData,
    BackgroundJobSoftwareUserLicenseRemoveData,
    BackgroundJobSoftwareUserRemoveData,
    BackgroundJobStatus,
    BackgroundJobType,
  } from '@/client'

  const props = defineProps({
    type: {
      type: String as PropType<BackgroundJobType>,
      required: true,
    },
    data: {
      type: Object as PropType<
        | BackgroundJobSoftwareUserAddData
        | BackgroundJobSoftwareUserRemoveData
        | BackgroundJobSoftwareUserLicenseRemoveData
        | BackgroundJobSoftwareUserLicenseAddData
        | BackgroundJobSoftwareUserEnableData
        | BackgroundJobAccountGroupAccountsRemovedData
        | BackgroundJobAccountGroupAccountsAddedData
      >,
      required: true,
    },
    status: {
      type: String as PropType<BackgroundJobStatus>,
      required: false,
      default: undefined,
    },
  })
</script>
