import { BackgroundJobStatus } from '@/client'

export interface BackgroundjobFilter {
  getFilter(): Record<string, unknown>
  name: string
}

export class StatusFilter implements BackgroundjobFilter {
  name = 'StatusFilter'
  status: BackgroundJobStatus[] | null // Null means all status

  constructor(status: BackgroundJobStatus[] | null) {
    this.status = status
  }

  getFilter() {
    if (this.status === null) {
      return {}
    }

    if (this.status.length === 0) {
      return {}
    }

    return {
      status: {
        $in: this.status,
      },
    }
  }
}

export class IdFilter implements BackgroundjobFilter {
  name = 'IdFilter'
  ids: string[]

  constructor(ids: string[]) {
    this.ids = ids
  }

  getFilter() {
    return {
      _id: {
        $in: this.ids,
      },
    }
  }
}

export class DateRangeFilter implements BackgroundjobFilter {
  name = 'DateRangeFilter'
  daterange: Date[] | undefined

  constructor(daterange: Date[] | undefined) {
    this.daterange = daterange
  }

  getFilter() {
    if (this.daterange === undefined) {
      return {}
    }

    const [start, end] = this.daterange
    const startDate = start?.toISOString()
    const endDate = end?.toISOString()

    return {
      $or: [
        {
          status: {
            $in: [
              'completed',
              'error',
              'aborted',
              'paused',
              'deprecated',
              'user_action_required',
            ],
          },
          updated: {
            $gte: startDate,
            $lte: endDate,
          },
        },
        {
          status: 'enqueued',
          next_execution_at: {
            $gte: startDate,
            $lte: endDate,
          },
        },
      ],
    }
  }
}

export const filters = {
  StatusFilter,
  IdFilter,
  DateRangeFilter,
}
