<template>
  <div v-if="backgroundJobs.length > 0" class="flex flex-col gap-4 pt-8">
    <div
      v-for="currentJob in backgroundJobs"
      :key="currentJob._id"
      class="border-b border-gray-200 pb-4 last:border-none">
      <BackgroundjobApprovalItem
        :job="currentJob"
        @jobs-changed="getUserActionRequiredBackgroundJobs" />
    </div>
  </div>

  <EmptyState
    v-else
    icon="md-check-round"
    :text="i18n.t('approvalsEmptyState')"
    :show-create-btn="false"
    :show-docs-btn="false" />
</template>

<script setup lang="ts">
  import { BackgroundJobStatus } from '@/client'
  import { BackgroundJobStoreTypes } from '@/stores/_db'
  import { StatusFilter } from '@/stores/backgroundjob.filter'
  import { backgroundjobStore } from '@/stores/backgroundjob.store'
  import { useI18n } from 'vue-i18n'

  const backgroundJobs = ref<BackgroundJobStoreTypes[]>([])
  const i18n = useI18n()

  async function getUserActionRequiredBackgroundJobs() {
    const filter = new StatusFilter([BackgroundJobStatus.USER_ACTION_REQUIRED])

    backgroundjobStore.getBackgroundjobs([filter]).then((result) => {
      backgroundJobs.value = result as BackgroundJobStoreTypes[]
    })
  }

  onMounted(async () => {
    await getUserActionRequiredBackgroundJobs()
  })
</script>
