/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SoftwareInformationOut } from '../models/SoftwareInformationOut';
import type { SoftwareStatus } from '../models/SoftwareStatus';
import type { SoftwareTypes } from '../models/SoftwareTypes';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SoftwareIntegrationsService {
    /**
     * Get Integrations
     * @returns SoftwareInformationOut Successful Response
     * @throws ApiError
     */
    public static getIntegrationsApiV1SoftwareIntegrationsGet({
        softwareType,
        softwareStatus,
        softwareName,
        xTransactionId,
    }: {
        /**
         * Software type
         */
        softwareType?: (SoftwareTypes | null),
        /**
         * Software status
         */
        softwareStatus?: (Array<SoftwareStatus> | null),
        softwareName?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<SoftwareInformationOut>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/integrations',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'software_type': softwareType,
                'software_status': softwareStatus,
                'software_name': softwareName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Public Integrations
     * @returns SoftwareInformationOut Successful Response
     * @throws ApiError
     */
    public static getPublicIntegrationsIntegrationsGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<SoftwareInformationOut>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/integrations',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
