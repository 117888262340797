<template>
  <SmDialog
    v-model:visibility="visible"
    :title="i18n.t(`jobtype.${props.backgroundjobType}.label`)"
    class="dialog"
    size="medium">
    <template v-if="props.backgroundjobType">
      <BackgroundjobFormSoftwareUserAdd
        v-if="props.backgroundjobType === BackgroundJobType.SOFTWARE_USER_ADD"
        ref="formEl" />
      <BackgroundjobFormSoftwareUserEnable
        v-if="
          props.backgroundjobType === BackgroundJobType.SOFTWARE_USER_ENABLE
        "
        ref="formEl" />
      <BackgroundjobFormSoftwareUserLicenseAdd
        v-if="
          props.backgroundjobType ===
          BackgroundJobType.SOFTWARE_USER_LICENSE_ADD
        "
        ref="formEl" />
      <BackgroundjobFormSoftwareUserLicenseRemove
        v-if="
          props.backgroundjobType ===
          BackgroundJobType.SOFTWARE_USER_LICENSE_REMOVE
        "
        ref="formEl" />
      <BackgroundjobFormSoftwareUserRemove
        v-if="props.backgroundjobType === 'delete_account'"
        ref="formEl" />
      <BackgroundjobFormSoftwareUserDisable
        v-if="props.backgroundjobType === 'disable_account'"
        ref="formEl" />
    </template>

    <template #footer>
      <SmButton size="small" outline @click="handleCancel">
        {{ i18n.t('general.cancel') }}
      </SmButton>
      <SmButton size="small" @click="handleSave">
        {{ i18n.t('general.save') }}
      </SmButton>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { BackgroundJobType } from '@/client'
  import { Request } from './TaskEditDialog.vue'
  import { ExtendedBackgroundJobType } from '@/stores/backgroundjob.utils'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  const props = defineProps({
    backgroundjobType: {
      type: Object as PropType<ExtendedBackgroundJobType>,
      required: true,
    },
  })

  const emit = defineEmits(['save'])

  const visible = defineModel<boolean>('visible', { default: false })
  const formEl = ref()

  async function handleSave() {
    const valid = formEl.value?.validateForm()

    if (valid) {
      const requestBody: Request = formEl.value?.createRequestBody()
      emit('save', requestBody)
      handleCancel()
    }
  }

  function handleCancel() {
    visible.value = false
    formEl.value?.resetForm()
  }

  /**
   * Watchers
   */

  watch(
    () => visible.value,
    (val) => {
      if (!val) {
        formEl.value?.resetForm()
      }
    }
  )
</script>

<style scoped>
  .submit-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
</style>
