<template>
  <div class="header">
    <h3>{{ i18n.t('informations') }}</h3>
    <div
      v-require-permission="CompanyAccessRole.ADMIN"
      style="display: flex; gap: 4px">
      <SmButton
        v-if="!editMode"
        size="small"
        outline
        @click="editMode = !editMode">
        <v-icon name="md-create-round" class="mr-2 fill-contrast" />
        {{ i18n.t('general.edit') }}
      </SmButton>
      <SmButton
        v-if="editMode"
        type="danger"
        size="small"
        @click="
          () => {
            editMode = !editMode
          }
        ">
        <v-icon name="md-close-round" scale="0.8" fill="var(--text-color)" />
        <span style="padding-left: 0.3rem">{{ i18n.t('general.cancel') }}</span>
      </SmButton>
      <SmButton
        v-if="editMode"
        type="success"
        size="small"
        data-test-id="user-detail-drawer-save-edit-button"
        @click="updateUserData">
        <v-icon name="md-done-round" scale="0.8" fill="white" />
        <span style="padding-left: 0.3rem"> {{ i18n.t('general.save') }}</span>
      </SmButton>
    </div>
  </div>

  <div class="user-information">
    <div class="user-item">
      <span class="information-heading">{{ i18n.t('firstName') }}</span>
      <span v-if="!editMode && user.first_name == ''" class="no-data">{{
        i18n.t('general.noData')
      }}</span>
      <span v-else-if="!editMode">{{ user.first_name }}</span>
      <SmInput v-else v-model="localUser.first_name" size="small" outline />
    </div>

    <div class="user-item">
      <span class="information-heading">{{ i18n.t('lastName') }}</span>
      <span v-if="!editMode && user.last_name == ''" class="no-data">{{
        i18n.t('general.noData')
      }}</span>
      <span v-else-if="!editMode">{{ user.last_name }}</span>
      <SmInput v-else v-model="localUser.last_name" size="small" outline />
    </div>

    <div class="user-item">
      <span class="information-heading">{{ i18n.t('displayName') }}</span>
      <span v-if="!editMode && user.name == ''" class="no-data">{{
        i18n.t('general.noData')
      }}</span>
      <span v-else-if="!editMode">{{ user.name }}</span>
      <SmInput v-else v-model="localUser.name" size="small" outline />
    </div>

    <div></div>
  </div>

  <!-- Contact information -->
  <h3 style="margin-top: 2rem; margin-bottom: 1rem">
    {{ i18n.t('contactData') }}
  </h3>
  <div class="user-information">
    <div class="user-item">
      <span class="information-heading">{{ i18n.t('phone') }}</span>
      <span v-if="!editMode && user.phone == ''" class="no-data">{{
        i18n.t('general.noData')
      }}</span>
      <span v-else-if="!editMode">{{ user.phone }}</span>
      <SmInput v-else v-model="localUser.phone" size="small" outline />
    </div>

    <div class="user-item">
      <span class="information-heading">{{ i18n.t('email') }}</span>
      <span v-if="!editMode && !user.email" class="no-data">{{
        i18n.t('general.noData')
      }}</span>
      <span v-else-if="!editMode">{{ user.email }}</span>
      <SmInput v-else v-model="localUser.email" size="small" outline disabled />
    </div>
  </div>

  <!-- Company internal -->
  <h3 style="margin-top: 2rem; margin-bottom: 1rem">
    {{ i18n.t('companyInternal') }}
  </h3>
  <div class="user-information">
    <div class="user-item">
      <span class="information-heading">{{ i18n.t('employeeId') }}</span>
      <span v-if="!editMode && user.employee_id == ''" class="no-data">{{
        i18n.t('general.noData')
      }}</span>
      <span v-else-if="!editMode">{{ user.employee_id }}</span>
      <SmInput v-else v-model="localUser.employee_id" size="small" outline />
    </div>

    <!-- Tags -->
    <div class="user-item">
      <span class="information-heading">{{ i18n.t('tags') }}</span>
      <!-- <div> -->
      <div class="tag-wrapper">
        <template v-if="availableTags.length > 0">
          <TagComponent
            v-for="tag in user.tags"
            :key="tag"
            :tag="
              availableTags.find((availableTag) => availableTag._id === tag)!
            " />

          <TagSelect
            :available-tags="availableTags"
            :selected="user.tags"
            @delete-tag="handleTagChange('delete', $event)"
            @add-tag="handleTagChange('add', $event)">
            <v-icon scale="0.8" name="hi-plus" class="tag-add-icon" />
          </TagSelect>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    CompanyAccessRole,
    Tag,
    TagsService,
    UsersService,
  } from '@/client'

  function handleTagChange(method: 'add' | 'delete', tagId: string) {
    const tags = [...props.user.tags]

    switch (method) {
      case 'add':
        tags.push(tagId)
        break
      case 'delete':
        tags.splice(tags.indexOf(tagId), 1)
        break
    }
    UsersService.putUserApiV1ManagementUsersPut({
      requestBody: {
        _id: props.user._id,
        tags: tags,
      },
    }).then(() => {
      emit('refetch-user')
    })
  }

  const props = defineProps({
    user: {
      type: Object as () => Account,
      required: true,
    },
  })
  const emit = defineEmits(['update:user', 'refetch-user'])

  const i18n = useI18n()

  const editMode = ref(false)

  const localUser = computed({
    // making sure, that the v-models for the inputs have a value
    get: () => {
      return {
        ...props.user,
        last_name: props.user.last_name || '',
        first_name: props.user.first_name || '',
        name: props.user.name || '',
        phone: props.user.phone || '',
        employee_id: props.user.employee_id || '',
      }
    },
    set: (value) => emit('update:user', value),
  })

  async function updateUserData() {
    editMode.value = !editMode.value
    if (!localUser.value) return
    UsersService.putUserApiV1ManagementUsersPut({
      requestBody: {
        email: localUser.value.email,
        name: localUser.value.name,
        phone: localUser.value.phone,
        employee_id: localUser.value.employee_id,
        first_name: localUser.value.first_name,
        last_name: localUser.value.last_name,
      },
    }).then(() => {
      emit('refetch-user')
    })
  }

  const availableTags = ref<Tag[]>([])

  onMounted(async () => {
    availableTags.value = await TagsService.getTagsApiV1ManagementTagsGet({})
  })
</script>

<style scoped>
  .header {
    padding-top: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .user-information {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    width: 99%;
  }

  .user-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .information-heading {
    font-weight: 300;
    color: theme('colors.gray[400]');
  }
  .tag-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    padding: 0.5rem 0 0.5rem 0;
    align-items: center;
    width: 100%;
  }
  .tag-wrapper:hover .tag-add-icon {
    opacity: 1;
  }
</style>
