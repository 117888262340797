<template>
  <div
    class="my-4 flex shrink-0 grow-0 basis-auto items-center justify-end gap-2">
    <h1 class="mr-auto">
      {{ capitalize(i18n.t('backgroundjob', { count: 2 })) }}
    </h1>

    <!-- Timespan Filter -->
    <SmDropdown
      :close-on-click-inside="false"
      max-height="70vh"
      dropdown-class="!max-w-lg">
      <template #trigger>
        <SmButton outline size="small">
          <div style="display: flex; align-items: center">
            <span class="mr-3">
              {{
                displayDate(daterange[0], 'medium') +
                ' - ' +
                displayDate(daterange[1], 'medium')
              }}
            </span>
            <v-icon name="md-expandmore-round" :scale="1.2" />
          </div>
        </SmButton>
      </template>

      <div style="padding: 1rem">
        <SmDatepicker
          v-model:daterange="daterange"
          :is-range="true"
          :is-dropdown="false"
          :quick-options="dateQuickOptions"
          class="w-full" />
      </div>
    </SmDropdown>

    <!-- Status Filter -->
    <SmDropdown :close-on-click-inside="false" max-height="30vh">
      <template #trigger>
        <SmButton outline size="small">
          <div style="display: flex; align-items: center">
            <span class="mr-3">{{ i18n.t('filter') }}</span>
            <span
              v-if="currentStatusFilter?.length"
              class="mr-2 rounded-full bg-gray-200 px-2 py-1 text-xs font-semibold text-black dark:bg-gray-700 dark:text-white">
              {{ currentStatusFilter?.length }}
            </span>
            <v-icon name="md-expandmore-round" :scale="1.2" />
          </div>
        </SmButton>
      </template>

      <div style="padding: 1rem">
        <template
          v-if="
            !isEqual(sortBy(currentStatusFilter), sortBy(defaultStatusFilter))
          ">
          <SmDropdownItem
            value="reset"
            class="flex w-48 justify-between"
            @click="resetFilter()">
            {{ i18n.t('resetFilters') }}
            <v-icon
              fill="var(--sm-text-color)"
              scale="0.9"
              name="md-replay"
              class="ml-auto" />
          </SmDropdownItem>

          <hr class="mb-4 mt-2" />
        </template>

        <SmDropdownItem
          v-for="_status in availableStatus"
          :key="_status.toString()"
          :value="_status"
          class="flex w-48 justify-between"
          @click="handleStatusFilterSelect(_status)">
          <SmTooltip :content="getStatus(_status).text" :active="false">
            <div class="flex items-center gap-2">
              <span
                class="indicator-dot shrink-0"
                :class="getStatus(_status).color"></span>
              <TextMultiline :text="getStatus(_status).text" />
            </div>
          </SmTooltip>

          <v-icon
            v-if="currentStatusFilter?.some((filter) => filter === _status)"
            fill="var(--sm-text-color)"
            scale="0.9"
            name="md-check-round"
            class="ml-auto" />
        </SmDropdownItem>
      </div>
    </SmDropdown>

    <!-- <SmButton @click="emit('createJob')">{{ i18n.t('createJob') }}</SmButton> -->
  </div>
</template>

<script setup lang="ts">
  import { BackgroundJobStatus } from '@/client'
  import dayjs from 'dayjs'
  import { useI18n } from 'vue-i18n'
  import { isEqual, sortBy } from 'lodash-es'

  import utc from 'dayjs/plugin/utc'
  import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
  import { displayDate } from '@/common/util/timeUtils'
  import localizedFormat from 'dayjs/plugin/localizedFormat'
  import { backgroundjobStore } from '@/stores/backgroundjob.store'
  import { removeFilter, updateFilter } from '@/common/util/filterUtils'
  import { BackgroundjobFilterInstance } from '@/stores/backgroundjob.utils'
  import { QuickOption } from './sm/SmDatepicker.vue'
  import { capitalize } from '@/common/util/formatter'

  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(localizedFormat)

  const emit = defineEmits(['createJob', 'updateFilter'])

  const i18n = useI18n()

  /**
   *  Backgroundjob functions
   */

  function getStatus(status: BackgroundJobStatus): Status {
    switch (status) {
      case BackgroundJobStatus.ENQUEUED:
        return {
          text: i18n.t('jobStatus.enqueued'),
          color: 'bg-contrast-muted',
        }
      case BackgroundJobStatus.ERROR:
        return { text: i18n.t('jobStatus.error'), color: 'bg-red' }
      case BackgroundJobStatus.PAUSED:
        return { text: i18n.t('jobStatus.paused'), color: 'bg-blue' }
      case BackgroundJobStatus.USER_ACTION_REQUIRED:
        return {
          text: i18n.t('jobStatus.userActionRequired'),
          color: 'bg-purple',
        }
      case BackgroundJobStatus.ABORTED:
        return { text: i18n.t('jobStatus.aborted'), color: 'bg-orange' }
      case BackgroundJobStatus.COMPLETED:
        return { text: i18n.t('jobStatus.completed'), color: 'bg-primary' }
      case BackgroundJobStatus.DEPRECATED:
        return { text: i18n.t('jobStatus.deprecated'), color: 'bg-yellow' }
    }
  }

  /**
   * Filters
   */
  const filters = ref<BackgroundjobFilterInstance[]>([])

  // DateRange Filter
  const currentDate = dayjs()
  const start = currentDate.subtract(30, 'day')
  const end = currentDate.add(30, 'day')
  const daterange = ref<Date[]>([start.toDate(), end.toDate()])

  watch(
    () => daterange.value,
    () => {
      setDateRangeFilter()
    },
    { immediate: true }
  )

  function setDateRangeFilter() {
    const newFilter = new backgroundjobStore.filter.DateRangeFilter(
      daterange.value
    )

    if (!newFilter) {
      removeFilter(filters, 'DateRangeFilter')
      return
    }

    updateFilter(filters, newFilter)
    emit('updateFilter', filters.value)
  }

  const dateQuickOptions = ref<Array<QuickOption>>([
    {
      label: i18n.t('dateQuickOptions.last30days'),
      start: currentDate.toDate(),
      end: currentDate.subtract(30, 'day').toDate(),
    },
    {
      label: i18n.t('dateQuickOptions.next30days'),
      start: currentDate.toDate(),
      end: currentDate.add(30, 'day').toDate(),
    },
    {
      label: i18n.t('dateQuickOptions.last7days'),
      start: currentDate.toDate(),
      end: currentDate.subtract(7, 'day').toDate(),
    },
    {
      label: i18n.t('dateQuickOptions.next7days'),
      start: currentDate.toDate(),
      end: currentDate.add(7, 'day').toDate(),
    },
    {
      label: i18n.t('dateQuickOptions.fromToday'),
      start: currentDate.toDate(),
      end: null,
    },
    {
      label: i18n.t('dateQuickOptions.sinceToday'),
      start: null,
      end: currentDate.toDate(),
    },
  ])

  // Statusfilter
  export interface Status {
    color: string
    text: string
  }

  const availableStatus: Array<BackgroundJobStatus> = [
    BackgroundJobStatus.ENQUEUED,
    BackgroundJobStatus.PAUSED,
    BackgroundJobStatus.COMPLETED,
    BackgroundJobStatus.USER_ACTION_REQUIRED,
    BackgroundJobStatus.DEPRECATED,
    BackgroundJobStatus.ABORTED,
    BackgroundJobStatus.ERROR,
  ]

  const defaultStatusFilter = ref<BackgroundJobStatus[]>([
    BackgroundJobStatus.ENQUEUED,
    BackgroundJobStatus.PAUSED,
    BackgroundJobStatus.ERROR,
  ])

  const currentStatusFilter = ref<BackgroundJobStatus[]>(
    defaultStatusFilter.value
  )

  function resetFilter() {
    currentStatusFilter.value = defaultStatusFilter.value
    setStatusFilter()
  }

  function handleStatusFilterSelect(status: BackgroundJobStatus) {
    if (currentStatusFilter.value.some((s) => s === status)) {
      //remove filter
      currentStatusFilter.value = currentStatusFilter.value.filter(
        (s) => s !== status
      )
    } else {
      // add filter
      currentStatusFilter.value = [...currentStatusFilter.value, status]
    }

    setStatusFilter()
  }

  function setStatusFilter() {
    const newFilter = new backgroundjobStore.filter.StatusFilter(
      currentStatusFilter.value
    )

    if (!newFilter) {
      removeFilter(filters, 'StatusFilter')
      return
    }

    updateFilter(filters, newFilter)
    emit('updateFilter', filters.value)
  }

  onMounted(async () => {
    setStatusFilter()
    setDateRangeFilter()
  })
</script>

<style lang="scss" scoped>
  .indicator-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
</style>
