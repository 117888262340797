<template>
  <GroupAddDialog
    v-model:visibility="dialogVisibility"
    v-model:account-group="group"
    :all-price-informations
    :all-software-licenses
    @groups-changed="emit('groups-changed')" />
  <el-card
    shadow="always"
    class="group-card-add flex justify-center"
    :body-style="{ height: '100%' }"
    @click="dialogVisibility = true">
    <div class="group-card-add-content">
      <v-icon name="hi-plus" class="fill-contrast" />
      <h4>{{ i18n.t('addGroup') }}</h4>
    </div>
  </el-card>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AccountGroupIn, SoftwareLicense, PriceInformation } from '@/client'

  const emit = defineEmits(['groups-changed'])
  defineProps<{
    allSoftwareLicenses: SoftwareLicense[]
    allPriceInformations: PriceInformation[]
  }>()

  const i18n = useI18n()

  const dialogVisibility = ref<boolean>(false)

  const group = ref<AccountGroupIn>({
    name: '',
    accounts: [],
    permission: {
      software_permissions: {},
    },
    color: {
      r: 0,
      g: 0,
      b: 255,
    },
  })
</script>

<style scoped lang="scss">
  .group-card-add {
    display: flex;
    cursor: pointer;
  }
  .group-card-add-content {
    display: flex;
    align-items: center;
    height: 100%;

    gap: 0.6rem;
  }
</style>
