<template>
  <el-form
    v-model="jobForm.fields"
    :model="jobForm.fields"
    label-width="150px"
    label-position="top"
    require-asterisk-position="right">
    <!-- Software -->
    <el-form-item
      v-if="softwareSelection || loadingSoftwareSelection"
      :label="i18n.t('software')"
      prop="software"
      :error="jobForm.getErrorMessage('software')">
      <SmSelect
        v-model:selection="jobForm.fields.software"
        :options="softwareSelection"
        :label="i18n.t('selectSoftware')"
        :loading="loadingSoftwareSelection"
        class="w-full"
        searchable
        @blur="jobForm.validate(['sub_account'])">
        <template #item="{ item }">
          <TextMultiline>
            {{ item.value.display_name }}
            <span v-if="item.value.information.subtitle">
              ({{ item.valiue.information.subtitle }})</span
            >
          </TextMultiline>
        </template>
      </SmSelect>
    </el-form-item>

    <!-- License -->
    <el-form-item
      v-if="licenseSelection || loadingLicenseSelection"
      :label="i18n.t('account')"
      prop="licence"
      :error="jobForm.getErrorMessage('licence')">
      <SmSelect
        v-model:selection="jobForm.fields.licence"
        :options="minifiedLicenseOptions"
        :label="i18n.t('selectAccount')"
        :item-size="65"
        :loading="loadingLicenseSelection"
        class="w-full"
        searchable
        @blur="jobForm.validate(['licence'])">
        <template #item="{ item }">
          <div class="flex flex-col leading-6">
            <TextMultiline :text="item.value.email" />
            <TextMultiline
              class="text-contrast-muted"
              :text="getSoftwareLicenseName(item.value.softwareLicenses)" />
          </div>
        </template>
      </SmSelect>
    </el-form-item>

    <!-- Licensemodel -->
    <el-form-item
      v-if="softwarelicenseSelection || loadingSoftwarelicenseSelection"
      :label="i18n.t('licensemodel')"
      prop="software_license"
      :error="jobForm.getErrorMessage('software_license')">
      <SmSelect
        v-model:selection="jobForm.fields.software_license"
        :options="softwarelicenseSelection"
        :label="i18n.t('selectLicenseModel')"
        :loading="loadingSoftwarelicenseSelection"
        class="w-full"
        searchable
        @blur="jobForm.validate(['software_license'])" />
    </el-form-item>

    <div class="flex gap-4">
      <!-- Date -->
      <el-form-item class="w-1/2">
        <template #label>
          <div class="flex gap-2">
            {{ i18n.t('jobExecutionInput.label') }}
            <SmTooltip>
              <template #content>
                {{ i18n.t('jobExecutionInput.tooltip') }}
              </template>
              <v-icon
                name="md-help-round"
                scale="0.9"
                class="fill-contrast-muted" />
            </SmTooltip>
          </div>
        </template>
        <SmDatepicker
          v-model:date="jobForm.fields.enqueue_date"
          class="w-full" />
      </el-form-item>

      <el-form-item
        :label="i18n.t('time')"
        class="w-1/2"
        :error="jobForm.getErrorMessage('enqueue_in')">
        <SmTimeInput
          v-model:time="jobForm.fields.enqueue_time"
          :disabled="!jobForm.fields.enqueue_date"
          :placeholder="
            !jobForm.fields.enqueue_date
              ? i18n.t('pleaseSelectDate')
              : undefined
          "
          class="w-full" />
      </el-form-item>
    </div>
  </el-form>
</template>

<script setup lang="ts">
  import {
    BackgroundJobSoftwareUserLicenseRemoveData,
    BackgroundJobType,
    Licence_Output,
    SoftwareLicenseMini,
    SoftwareOut,
  } from '@/client'
  import useForm from '@/common/form'
  import { useI18n } from 'vue-i18n'
  import { isDateAfterNow, isRequired } from './sm/SmInput/SmInputValidator'
  import { Option } from './sm/SmSelect.vue'
  import TextMultiline from './sm/TextMultiline.vue'
  import { getSoftwareLicenses } from '@/common/license'
  import dayjs from 'dayjs'
  import { backgroundjobStore } from '@/stores/backgroundjob.store'
  import { Request } from './TaskEditDialog.vue'
  import { AccountStore } from '@/stores/account.store'
  import { ApplicationStore } from '@/stores/application.store'

  const i18n = useI18n()
  const fallBackTime = '0600'
  const tomorrowDate = dayjs().add(1, 'day').toDate()

  /**
   * Form
   */
  const jobForm = useForm(
    {
      type: BackgroundJobType.SOFTWARE_USER_LICENSE_REMOVE,
      software: undefined, // for filtering licenses
      licence: undefined,
      software_license: undefined,
      enqueue_in: tomorrowDate as Date | null,
      enqueue_date: tomorrowDate,
      enqueue_time: fallBackTime,
    },
    // Form Hooks
    {
      onReset: () => {},
    },

    // Form Validators
    {
      software: [isRequired],
      licence: [isRequired],
      software_license: [isRequired],
      enqueue_in: [isDateAfterNow(600, true)],
    },
    {}
  )
  const selectedDateTime = computed(() => {
    if (!jobForm.fields.enqueue_date) return null

    const date = dayjs(jobForm.fields.enqueue_date)
    const time = jobForm.fields.enqueue_time || fallBackTime

    const dateTime = date
      .hour(parseInt(time.slice(0, 2)))
      .minute(parseInt(time.slice(2, 4)))

    return dateTime.toDate()
  })

  watch(
    selectedDateTime,
    (val) => {
      jobForm.fields.enqueue_in = val
    },
    { immediate: true }
  )

  // todo: use licence_mini instead?
  type simple_license = {
    _id: string
    email: string
    software_id: string
    softwareLicenses: Record<string, SoftwareLicenseMini>
  }

  const minifiedLicenseOptions = computed<Option<simple_license>[] | null>(
    () => {
      if (loadingLicenseSelection.value) return null
      if (!licenseSelection.value) return []

      return licenseSelection.value.map((option) => ({
        value: {
          _id: option.value._id,
          email: option.value.email,
          software_id: option.value.software_id,
          softwareLicenses: option.value.software_licenses,
        },
        label: option.label,
        key: option.key,
      }))
    }
  )

  function createRequestBody(): Request<BackgroundJobSoftwareUserLicenseRemoveData> {
    const formFields = JSON.parse(JSON.stringify(toRaw(jobForm).fields))
    const requestBody: Request<BackgroundJobSoftwareUserLicenseRemoveData> = {
      type: BackgroundJobType.SOFTWARE_USER_LICENSE_REMOVE,
      data: {} as BackgroundJobSoftwareUserLicenseRemoveData,
      enqueue_in: 0,
    }

    // extract ids from objects
    if (formFields.licence && typeof formFields.licence == 'object') {
      requestBody.data.licence_id = formFields.licence[0].value._id
    }
    if (formFields.software_license && formFields.software_license[0]) {
      requestBody.data.software_license_id =
        formFields.software_license[0].value
    }

    // Convert dateTime to seconds
    if (selectedDateTime.value) {
      requestBody.enqueue_in = dayjs(selectedDateTime.value).diff(dayjs(), 's')
    }

    return requestBody
  }

  function submit() {
    if (!jobForm?.dirty) if (!jobForm.validate()) return

    const requestBody = createRequestBody()
    const { type, enqueue_in, data } = requestBody

    return backgroundjobStore
      .createBackgroundjob(type, data, enqueue_in)
      .then((response) => {
        return response
      })
  }

  function resetForm() {
    jobForm?.reset()
  }

  function validateForm() {
    return jobForm?.validate()
  }

  /**
   * Selection Data
   */

  const softwarelicenseSelection = ref<Option<string>[]>()
  const loadingSoftwarelicenseSelection = ref<boolean>(false)
  const licenseSelection = ref<Option<Licence_Output>[] | undefined>([])
  const loadingLicenseSelection = ref<boolean>(false)
  const softwareSelection = ref<Option<SoftwareOut>[] | undefined>([])
  const loadingSoftwareSelection = ref<boolean>(false)

  async function loadSoftwareSelection() {
    loadingSoftwareSelection.value = true
    const allSoftwares = await ApplicationStore.getApplications()
    softwareSelection.value = allSoftwares?.map((software) => ({
      value: software,
      label: software.display_name,
      key: software._id,
    }))
    loadingSoftwareSelection.value = false
  }

  async function loadLicenceSelection(software: Option<SoftwareOut>) {
    loadingLicenseSelection.value = true

    const allLicenses = await AccountStore.getAccounts({
      softwareId: software.value._id,
    })
    licenseSelection.value = allLicenses?.map((software) => ({
      value: toRaw(software),
      label: software.email,
      key: software._id,
    }))
    loadingLicenseSelection.value = false
  }

  async function loadSoftwareLicenseSelection(softwareId: string) {
    loadingSoftwarelicenseSelection.value = true
    const allSoftwareLicenses = await getSoftwareLicenses(softwareId)
    softwarelicenseSelection.value = Object.values(allSoftwareLicenses).map(
      (softwareLicense) => ({
        value: softwareLicense.license._id,
        label: softwareLicense.license.name,
      })
    )
    loadingSoftwarelicenseSelection.value = false
  }
  /**
   * Option Display
   */

  function getSoftwareLicenseName(licenses: {
    [id: string]: { name: string }
  }) {
    const length = Object.keys(licenses).length

    if (length == 0) {
      return
    } else if (length == 1) {
      const id = Object.keys(licenses)[0]
      return licenses[id].name
    } else {
      return i18n.t('licensesCount', { count: length })
    }
  }

  /**
   * Watch functions
   */

  watch(
    () => jobForm.fields.software,
    (
      newValue: Option<SoftwareOut>[] | undefined,
      oldValue: Option<SoftwareOut>[] | undefined
    ) => {
      const deselected = !newValue?.length
      const changedSelected =
        newValue?.[0] && oldValue?.[0] && newValue[0] !== oldValue[0]
      const selected = newValue?.length && !oldValue?.length

      if (deselected || changedSelected) {
        licenseSelection.value = jobForm.fields.licence = undefined
      }
      if (changedSelected || selected) {
        loadLicenceSelection(newValue[0] as Option<SoftwareOut>)
      }
    },
    { immediate: true, deep: true }
  )

  watch(
    () => jobForm.fields.licence,
    (licence) => {
      if (licence && licence[0]) {
        // load selections depending on the selected licence
        loadSoftwareLicenseSelection(
          (licence[0] as Option<Licence_Output>).value.software_id
        )
      } else {
        softwarelicenseSelection.value = undefined

        if (jobForm.fields.software_license)
          jobForm.fields.software_license = undefined
      }
    },
    { immediate: true, deep: true }
  )

  onMounted(async () => {
    loadSoftwareSelection()
  })

  defineExpose({ submit, resetForm, validateForm, createRequestBody })
</script>

<style scoped></style>
