<template>
  <span>
    <SmTooltip :active="showToolTip && textIsChopped">
      <template #content>
        <slot>
          <div>{{ props.text }}</div>
        </slot>
      </template>

      <span v-bind="attrs" ref="textEl" class="text-content">
        <slot>{{ props.text }}</slot>
      </span>
    </SmTooltip>
  </span>
</template>

<script lang="ts">
  export default {
    inheritAttrs: false,
  }
</script>

<script setup lang="ts">
  import SmTooltip from './SmTooltip.vue'

  const textEl = ref<HTMLElement>()
  const props = defineProps({
    // Required is false here to remove the annoying warning
    // eslint-disable-next-line vue/require-default-prop
    text: {
      type: String,
      required: false,
    },
    linesToShow: {
      type: Number,
      default: 1,
    },
    showToolTip: {
      type: Boolean,
      default: true,
    },
    tooltipDelay: {
      type: Number,
      default: 800,
    },
    tooltipMaxWidth: {
      type: String,
      default: null,
    },
    tooltipPlacement: {
      type: String,
      default: 'auto',
    },
  })

  const attrs = useAttrs()

  const textIsChopped = computed(() => {
    if (!textEl.value) return false
    return textEl.value.scrollHeight != textEl.value.clientHeight
  })
</script>

<style scoped>
  .text-content {
    /* Only two lines should be shown  */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: v-bind('props.linesToShow');
    /* number of lines to show */
    line-clamp: v-bind('props.linesToShow');
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
    word-break: break-all;
  }
</style>
