import { addRxPlugin, MangoQuerySortPart } from 'rxdb'
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode'
import { RxDBUpdatePlugin } from 'rxdb/plugins/update'
import { db } from './_db'

// import filterUtils from '@/common/util/filterUtils'

import { Task, TaskPostIn, TaskUpdatePatchIn } from '@/client'
import {
  _allTasksLoaded,
  _checkTaskCollection,
  _createTask,
  _deleteTask,
  _fetchTask,
  _getQuery,
  _reloadTasks,
  _updateTask,
  TaskFilterInstance,
  TaskStatus,
} from './task.utils'
import { filters } from './task.filter'

addRxPlugin(RxDBUpdatePlugin)

if (import.meta.env.MODE !== 'production') {
  addRxPlugin(RxDBDevModePlugin)
}

/**
 * CREATE
 */

async function createTask(task: TaskPostIn) {
  await _checkTaskCollection()

  return _createTask(task).then((response) => {
    if (response.data) {
      const dataToInsert = JSON.parse(JSON.stringify(response.data))
      db.value?.tasks.insert(dataToInsert)
    }
    return response
  })
}

/**
 * READ
 */

export async function getTask(taskId: string) {
  await _checkTaskCollection()

  const cachedTask = db.value?.tasks
    .findOne({ selector: { _id: taskId } })
    .exec()

  if (!cachedTask) {
    const fetchedTask = await _fetchTask(taskId)
    return fetchedTask.data
  }

  return cachedTask
}

export async function getTasks(
  filters?: TaskFilterInstance[],
  {
    limit,
    skip,
    sort,
  }: {
    limit?: number
    skip?: number
    sort?: MangoQuerySortPart<Task>[]
  } = {}
) {
  await _checkTaskCollection()

  const query = await _getQuery(filters, { limit, skip, sort })
  const tasks = await query?.exec()

  return toRaw(tasks)
}

export async function getTasksSubscription(
  filters?: TaskFilterInstance[],
  {
    limit,
    skip,
    sort,
  }: {
    limit?: number
    skip?: number
    sort?: MangoQuerySortPart<Task>[]
  } = {}
) {
  await _checkTaskCollection()

  const query = await _getQuery(filters, { limit, skip, sort })
  const querySub = query?.$
  return querySub
}

export async function getTasksCount() {
  await _checkTaskCollection()

  if (!_allTasksLoaded.value) {
    await _reloadTasks()
  }

  const totalCount = db.value?.tasks.count().exec()

  return {
    total: await totalCount,
    // filtered: await filteredCount
  }
}

/**
 * UPDATE
 */

async function updateTask(id: string, task: TaskUpdatePatchIn) {
  await _checkTaskCollection()

  return _updateTask(id, task).then((response) => {
    db.value?.tasks
      .findOne({ selector: { _id: id } })
      .patch(toRaw(response.data))
    return response
  })
}

async function archiveTask(taskId: string) {
  await _checkTaskCollection()

  return _deleteTask(taskId).then((response) => {
    db.value?.tasks.findOne({ selector: { _id: taskId } }).remove()
    return response
  })
}

async function changeStatus(taskId: string, status: TaskStatus) {
  await _checkTaskCollection()

  return updateTask(taskId, { status })
}

/**
 * DELETE
 */

// currently not deleting tasks

export const taskStore = {
  createTask,
  getTask,
  getTasks,
  getTasksSubscription,
  getTasksCount,
  updateTask,
  archiveTask,
  changeStatus,
  filter: {
    ...filters,
  },
}
