/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LicenceFilter {
    ALL = 'all',
    ACTIVE = 'active',
    EXCLUDE_DISABLED = 'exclude_disabled',
    BILLABLE = 'billable',
}
