<template>
  <SmDialog
    :visibility="visibility"
    size="small"
    :title="title"
    @update:visibility="visibility = !visibility">
    <p v-if="software.display_name || software.software_name">
      {{
        i18n.t('softwareDisconnect.text', {
          softwareName: props.software.display_name || software.software_name,
        })
      }}
    </p>
    <p v-if="software.display_name || software.software_name">
      {{
        i18n.t('softwareDisconnect.text2', {
          softwareName: software.display_name || software.software_name,
        })
      }}
    </p>

    <template #footer>
      <div style="display: flex; gap: 1rem; margin-top: 1rem">
        <SmButton outline @click="visibility = !visibility">
          {{ i18n.t('cancel') }}
        </SmButton>
        <SmButton :loading="loading" @click="handleDisconnect">
          {{ i18n.t('Disconnect') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import { SoftwareMiniOut, SoftwareOut } from '@/client'

  import { sendToast } from './sm/SmNotification'
  import { ApplicationStore } from '@/stores/application.store'

  const i18n = useI18n()
  const router = useRouter()

  const title = computed(() =>
    i18n.t('softwareDisconnect.title', {
      softwareName: props.software.display_name || props.software.software_name,
    })
  )

  const props = defineProps({
    software: {
      type: Object as PropType<SoftwareOut | SoftwareMiniOut>,
      required: true,
    },
  })

  const visibility = defineModel('visibility', {
    type: Boolean,
    default: false,
  })

  const loading = ref(false)
  async function handleDisconnect() {
    loading.value = true
    const result = await ApplicationStore.disconnectApplication(
      props.software._id
    )

    if (result.error) {
      sendToast(
        i18n.t('softwareDisconnect.errorTitle', {
          softwareName:
            props.software.display_name || props.software.software_name,
        }),
        result.error.message,
        'error'
      )
    } else {
      sendToast(
        i18n.t('softwareDisconnect.successTitle', {
          softwareName:
            props.software.display_name || props.software.software_name,
        }),
        undefined,
        'success'
      )
      router.push({ name: 'Applications' })
    }

    // Reset loading and visibility
    loading.value = false
    visibility.value = false
  }
</script>
