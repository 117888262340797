<!-- TODO: This file Needs love -->

<script setup lang="ts">
  import { useClipboard } from '@vueuse/core'
  import { useI18n } from 'vue-i18n'

  import {
    AccessTokenService,
    CompanyAccessTokensResponse,
    CompanyManagementService,
    Scopes,
  } from '@/client'
  import { useSessionStore } from '@/stores/sessionStore'
  import { Column } from './sm/SmTable.types'
  import { displayDate } from '@/common/util/timeUtils'

  const sessionStore = useSessionStore()
  const i18n = useI18n()

  const scopes = ref([
    Scopes.ADMIN_READ,
    Scopes.ADMIN_WRITE,
    Scopes.MEMBER_READ,
    Scopes.MEMBER_READ,
  ])
  const token = ref<string>('')

  const currentTokens = ref<CompanyAccessTokensResponse | null>(null)

  const loading = ref(false)

  const { copy, copied, isSupported } = useClipboard()

  /**
   * ====================================
   *  * Table
   * ====================================
   */

  const columns: Column<
    CompanyAccessTokensResponse['company_access_tokens'][0]
  >[] = [
    {
      key: '_id',
      label: i18n.t('id'),
      sortable: true,
      width: 0.6,
    },
    {
      key: 'created',
      label: i18n.t('created'),
      sortable: true,
      width: 0.6,
    },
    {
      key: 'name',
      label: i18n.t('creator'),
      sortable: false,
      width: 1,
    },
    {
      key: 'scp',
      label: i18n.t('scopes'),
      sortable: false,
      width: 1,
    },
    {
      key: 'notes',
      label: i18n.t('description'),
      sortable: false,
      width: 1,
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      width: '24px',
    },
  ]

  function getCurrentToken() {
    CompanyManagementService.getCompanyAccessTokensApiV1CompanyAccessTokensGet({
      isLoginAccessToken: false,
    }).then((response) => {
      currentTokens.value = response
    })
  }

  function deleteToken(id: string) {
    CompanyManagementService.deleteCompanyAccessTokenByIdApiV1CompanyAccessTokensCompanyAccessTokenIdDelete(
      {
        companyAccessTokenId: id,
      }
    ).then(() => {
      getCurrentToken()
    })
  }

  onMounted(() => {
    getCurrentToken()
  })

  /**
   * ====================================
   *  * Dialog
   * ====================================
   */
  const expiresIn = ref(31535999)
  const visibility = ref(false)
  const tokenGenerated = ref(false)
  const note = ref('')

  function closeDialog() {
    visibility.value = false
    tokenGenerated.value = false
  }

  function generateNewToken() {
    if (!sessionStore.currentCompany?.company_id) return
    loading.value = true

    AccessTokenService.requestTokenTokenPost({
      requestBody: {
        company_id: sessionStore.currentCompany?.company_id,
        expires_in: expiresIn.value,
        scopes: scopes.value,
        notes: note.value,
      },
    }).then((response) => {
      token.value = response.access_token
      tokenGenerated.value = true
      loading.value = false
      getCurrentToken()
    })
  }
</script>

<template>
  <SmDialog
    v-model:visibility="visibility"
    :title="i18n.t('createToken')"
    size="small">
    <div :class="['wrapper', { open: !tokenGenerated }]">
      <div class="expandable">
        <p>
          {{ i18n.t('newTokenDescription') }}
        </p>

        <!-- Description -->
        <div class="mt-3">
          <span class="font-bold">{{ i18n.t('description') }}</span>
          <SmInput v-model="note" size="small" outline class="mt-1" />
        </div>

        <!-- Expriry Daten -->
        <div style="display: flex; gap: 1rem; align-items: center" class="mt-5">
          <span class="font-bold">{{ i18n.t('tokenExpiresIn') }}: </span>
          <SmDropdown>
            <template #trigger>
              <SmButton outline>
                {{
                  i18n.t('days', { days: Math.ceil(expiresIn / 60 / 60 / 24) })
                }}
              </SmButton>
            </template>

            <SmDropdownItem
              v-for="selectionExpiresIn in [
                365 * 60 * 60 * 24 - 1,
                30 * 60 * 60 * 24 - 1,
                7 * 60 * 60 * 24 - 1,
                3 * 60 * 60 * 24 - 1,
                1 * 60 * 60 * 24 - 1,
              ]"
              :key="selectionExpiresIn"
              :selected="expiresIn === selectionExpiresIn"
              @click="expiresIn = selectionExpiresIn">
              {{
                i18n.t('days', {
                  days: Math.ceil(selectionExpiresIn / 60 / 60 / 24),
                })
              }}
            </SmDropdownItem>
          </SmDropdown>
        </div>
      </div>
    </div>

    <div :class="['wrapper', { open: tokenGenerated }]">
      <div class="expandable">
        <div class="token-input" style="margin-bottom: 1rem">
          <SmInput
            v-model="token"
            size="small"
            outline
            label="Token"
            disabled></SmInput>
          <div>
            <SmTooltip
              v-if="isSupported && token"
              placement="right"
              :content="copied ? i18n.t('copied') : i18n.t('copy')">
              <v-icon name="io-copy" @click="copy(token)"></v-icon>
            </SmTooltip>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <SmButton
        v-if="!tokenGenerated"
        :loading="loading"
        @click="generateNewToken">
        {{ i18n.t('generateNewToken') }}
      </SmButton>
      <SmButton v-else outline @click="closeDialog">
        {{ i18n.t('close') }}
      </SmButton>
    </template>
  </SmDialog>

  <!-- settings-container defined in parent -->
  <div>
    <div class="settings-group">
      <div class="header-with-button">
        <!-- Header -->
        <h3>{{ i18n.t('sidebar.settings.api') }}</h3>
        <SmButton outline @click="visibility = true">
          {{ i18n.t('createToken') }}
        </SmButton>
      </div>
    </div>

    <SmTable
      style="margin-top: 1rem"
      :data="currentTokens?.company_access_tokens || []"
      key-field="_id"
      :columns="columns">
      <template #created="{ row }">
        <p>{{ displayDate(new Date(row.created), { dateStyle: 'short' }) }}</p>
      </template>

      <template #scp="{ row }">
        <p>{{ row.scp.join(', ') }}</p>
      </template>

      <template #actions="{ row }">
        <!-- Delete -->
        <div style="margin-right: auto">
          <v-icon
            name="md-delete-round"
            style="cursor: pointer"
            @click="deleteToken(row._id)" />
        </div>
      </template>
    </SmTable>
  </div>
</template>

<style lang="scss" scoped>
  .wrapper {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .wrapper.open {
    grid-template-rows: 1fr;
  }

  .expandable {
    min-height: 0;
  }

  .token-input {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .header-with-button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5rem;
  }
</style>
