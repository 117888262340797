<template>
  <div class="home">
    <!-- Header -->
    <ViewHeader
      :title="capitalize(i18n.t('views.automations', { count: 2 }))" />

    <!-- Content -->
    <div class="tasks-grid">
      <el-card
        shadow="always"
        body-class="flex flex-col"
        style="overflow-y: auto"
        :body-style="{
          height: '100%',
          width: '100%',
        }">
        <el-tabs v-model="activeTab" class="h-full">
          <el-tab-pane
            name="tasks"
            :label="capitalize(i18n.t('tasks'))"
            class="flex h-full flex-col">
            <TasksTable />
          </el-tab-pane>
          <el-tab-pane
            name="jobs"
            :label="capitalize(i18n.t('backgroundjob', { count: 2 }))"
            class="flex h-full flex-col"
            lazy>
            <BackgroundjobTable />
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <el-card
        shadow="always"
        body-class="flex flex-col"
        style="overflow-y: auto"
        :body-style="{
          height: '100%',
          width: '100%',
        }">
        <h2 class="border-b pb-2 pt-2">{{ i18n.t('approvals') }}</h2>
        <BackgroundjobApprovalList />
      </el-card>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { capitalize } from '@/common/util/formatter'
  import BackgroundjobApprovalList from '@/components/BackgroundjobApprovalList.vue'

  const i18n = useI18n()
  const route = useRoute()
  const router = useRouter()

  const view = route.query.tab
  const activeTab = ref('tasks')

  watch(activeTab, () => {
    if (activeTab.value === 'jobs') {
      router.replace({ query: { tab: 'jobs' } })
    } else if (activeTab.value === 'tasks') {
      router.replace({ query: { tab: 'tasks' } })
    }
  })

  onMounted(() => {
    if (view === 'jobs') {
      activeTab.value = 'jobs'
    }
  })
</script>

<style lang="scss" scoped>
  .tasks-grid {
    display: grid;
    grid-template-rows: 1fr;
    gap: 1rem;
    grid-template-columns: 1fr 400px;
  }

  .tasks-tabs {
    display: grid;
    grid-template-rows: auto 1fr;
    max-height: 100%;
  }

  .job-pane {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .tasks-grid .el-tabs__item {
    padding: 0 10px;
  }
</style>

<style lang="scss">
  .tasks-grid .el-tabs__item {
    padding: 0 12px;
  }
</style>
