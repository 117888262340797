<template>
  <div class="header">
    <!-- License Count -->
    <div style="display: flex; align-items: flex-start">
      <span id="license-count"
        >{{ i18n.t('accounts', { count: props.licenseCount }) }} ({{
          props.licenseCount
        }})</span
      >
    </div>
    <div
      :style="{
        display: 'flex',
        'flex-direction': 'row',
        'justify-content': 'end',
        'align-items': 'center',
        gap: '5px',
        width: '80%',
      }">
      <!-- Search -->
      <div>
        <SmInput
          v-model="searchQuery"
          style="width: 250px; margin-right: 1px"
          size="small"
          outline
          :label="
            i18n.t(
              'views.applicationDetails.licencesCard.table.searchPlaceholder'
            )
          " />
      </div>

      <!-- Filter -->
      <SmDropdown
        :close-on-click-inside="false"
        dropdown-class="w-[42rem] max-w-7xl">
        <template #trigger>
          <SmButton outline size="small" @click="() => emit('openFilter')">
            <div class="flex items-center gap-2">
              <v-icon name="md-filteralt-round" class="fill-contrast" />
              <span class="button-text">
                {{ i18n.t('filter') }}
              </span>
            </div>
          </SmButton>
        </template>

        <div style="padding: 1rem">
          <LicenceFilterDialog
            v-model:filter="filter"
            :software="props.software"
            :software-licenses="props.softwareLicenses"
            @apply-filter="handleApplyFilter" />
        </div>
      </SmDropdown>

      <!-- Export -->
      <div
        v-require-permission="CompanyAccessRole.ADMIN"
        class="action-buttons">
        <SmButton outline @click="emit('exportLicenses')">
          <div class="flex items-center gap-2">
            <v-icon name="md-arrowdownward-round" class="fill-contrast" />
            <span class="button-text">
              {{ i18n.t('export') }}
            </span>
          </div>
        </SmButton>

        <!-- Import -->
        <SmButton
          v-if="props.software.type == SoftwareTypes.MANUALLY"
          @click="emit('importLicenses')">
          <div class="flex items-center gap-2">
            <v-icon
              name="md-arrowdownward-round"
              class="fill-white"
              flip="vertical" />
            <span class="button-text">
              {{ i18n.t('import') }}
            </span>
          </div>
        </SmButton>

        <!-- Add user -->
        <SmTooltip
          v-if="props.software.information.implements_add_user_method"
          placement="top">
          <template #content>
            <!-- Readonly -->
            <span v-if="props.software.read_only">
              {{ i18n.t('readonlyDesc') }}
            </span>
            <span v-else-if="props.software.information.sso_provider">
              {{ i18n.t('readonlySSODesc') }}
            </span>
            <span v-else>
              {{ i18n.t('addAccount') }}
            </span>
          </template>

          <SmButton
            :disabled="
              props.software.read_only ||
              props.software.information.sso_provider
            "
            type="primary"
            size="small"
            @click="() => emit('openAddUser')">
            <v-icon name="md-personaddalt1-round" class="fill-white" />
          </SmButton>
        </SmTooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import {
    CompanyAccessRole,
    SoftwareLicense,
    SoftwareOut,
    SoftwareTypes,
  } from '@/client'
  import SmTooltip from '@/components/sm/SmTooltip.vue'
  import { AccountFilters } from '@/stores/account.filter'

  const emit = defineEmits<{
    openAddUser: []
    importLicenses: []
    exportLicenses: []
    openFilter: []
  }>()

  const props = defineProps<{
    licenseCount: number // Number of licenses/accounts
    software: SoftwareOut
    softwareLicenses: SoftwareLicense[]
  }>()

  const i18n = useI18n()

  const searchQuery = defineModel<string>('searchQuery')

  // Filter
  const filter = defineModel<AccountFilters>('filter', {
    default: {},
  })

  const visibilityFilterDialog = ref<boolean>(false)
  function handleApplyFilter() {
    visibilityFilterDialog.value = false
  }
</script>

<style lang="scss" scoped>
  #license-count {
    font-size: 0.9rem;
    font-weight: 700;
  }

  .scrollbar-flex-content {
    display: flex;
    margin-right: 5px;
  }

  .scrollbar-demo-item {
    margin: 3px 0px 3px 0px;
  }

  .action-buttons {
    .sm-button {
      margin-left: 0px !important;
    }

    display: flex;
    align-items: center;
    gap: 5px;
  }

  @media screen and (max-width: 1500px) {
    .button-text {
      display: none;
    }
  }
</style>
