<template>
  <div v-if="someError" class="flex items-center gap-3">
    <v-icon name="md-warningamber-round" scale=".9" class="fill-red" />
    <TextMultiline class="leading-none" :text="i18n.t('errorOccurred')" />
  </div>
  <div v-else-if="everythingDone" class="flex items-center gap-3">
    <v-icon name="md-check-round" scale=".9" class="fill-primary" />
    <span class="leading-none">{{ i18n.t('jobStatus.completed') }}</span>
  </div>
  <div
    v-else-if="props.backgroundJobIds.length"
    class="flex items-center gap-3">
    <SmCircleProgress
      :total="props.backgroundJobIds.length"
      :value="doneCount"
      size="xsmall" />
    <span class="leading-none">
      {{
        i18n.t('XofY', {
          x: doneCount,
          y: props.backgroundJobIds.length,
        })
      }}
    </span>
  </div>

  <TextMultiline
    v-else-if="props.showFallback"
    class="text-sm text-contrast-muted"
    :text="i18n.t('noJobsAssigned')"
    :lines-to-show="1"
    :show-tool-tip="false" />
</template>

<script setup lang="ts">
  import { BackgroundJobStatus } from '@/client'
  import { useI18n } from 'vue-i18n'
  import { backgroundjobStore } from '@/stores/backgroundjob.store'
  import { BackgroundjobFilterInstance } from '@/stores/backgroundjob.utils'

  const i18n = useI18n()
  const doneCount = ref<number>(0)
  const errorCount = ref<number>(0)

  const props = defineProps({
    backgroundJobIds: {
      type: Array<string>,
      required: true,
    },
    showFallback: {
      type: Boolean,
      default: true,
    },
  })

  watch(
    () => props.backgroundJobIds,
    async () => {
      getDoneJobCount(props.backgroundJobIds).then((count) => {
        doneCount.value = count.filtered ?? 0
      })

      getErrorJobCount(props.backgroundJobIds).then((count) => {
        errorCount.value = count.filtered ?? 0
      })
    },
    {
      immediate: true,
    }
  )

  async function getDoneJobCount(ids: Array<string>) {
    const filters = ref<BackgroundjobFilterInstance[]>([])
    const idFilter = new backgroundjobStore.filter.IdFilter(ids)
    const statusFilter = new backgroundjobStore.filter.StatusFilter([
      BackgroundJobStatus.COMPLETED,
      BackgroundJobStatus.ABORTED,
      BackgroundJobStatus.DEPRECATED,
    ])

    filters.value.push(statusFilter, idFilter)
    const count = await backgroundjobStore.getBackgroundjobCount(filters.value)
    return count
  }

  async function getErrorJobCount(ids: Array<string>) {
    const filters = ref<BackgroundjobFilterInstance[]>([])
    const idFilter = new backgroundjobStore.filter.IdFilter(ids)
    const statusFilter = new backgroundjobStore.filter.StatusFilter([
      BackgroundJobStatus.ERROR,
    ])

    filters.value.push(statusFilter, idFilter)
    const count = await backgroundjobStore.getBackgroundjobCount(filters.value)
    return count
  }

  const everythingDone = computed(() => {
    return (
      props.backgroundJobIds.length > 0 &&
      doneCount.value === props.backgroundJobIds.length
    )
  })

  const someError = computed(() => {
    return props.backgroundJobIds.length > 0 && errorCount.value
  })
</script>
