<template>
  <div class="flex flex-row items-center justify-between gap-4">
    <BackgroundjobMessage
      :data="props.job.data"
      :type="props.job.type"
      tag-style="text"
      allow-display-in-multiple-lines
      clickable />

    <div class="flex w-16 content-between items-center gap-3">
      <SmSpinner v-show="loading" class="ml-auto" />

      <div v-show="!loading" class="flex items-center gap-3">
        <SmTooltip :content="i18n.t('deny')">
          <div
            class="hover:enabled group flex h-7 w-7 items-center justify-center rounded-full bg-gray-100 hover:cursor-pointer"
            @click="cancelBackgroundJob(props.job._id)">
            <v-icon name="io-close" class="duration-150 group-hover:fill-red" />
          </div>
        </SmTooltip>

        <SmTooltip :content="i18n.t('approve')">
          <div
            class="hover:enabled group flex h-7 w-7 items-center justify-center rounded-full bg-gray-100 hover:cursor-pointer"
            @click="runBackgroundJob(props.job._id)">
            <v-icon
              name="md-check-round"
              class="duration-150 group-hover:fill-primary" />
          </div>
        </SmTooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { BackgroundJobStoreTypes } from '@/stores/_db'
  import SmTooltip from './sm/SmTooltip.vue'
  import { useI18n } from 'vue-i18n'
  import { backgroundjobStore } from '@/stores/backgroundjob.store'

  const i18n = useI18n()

  interface Props {
    job: BackgroundJobStoreTypes
  }

  const emit = defineEmits<{
    jobsChanged: [void]
  }>()

  const loading = ref(false)

  async function runBackgroundJob(id: string) {
    loading.value = true

    backgroundjobStore.enqueueBackgroundjob(id).then(() => {
      emit('jobsChanged')
      loading.value = false
    })
  }

  async function cancelBackgroundJob(id: string) {
    loading.value = true

    backgroundjobStore.abortBackgroundjob(id).then((res) => {
      emit('jobsChanged')
      loading.value = false
    })
  }

  const props = withDefaults(defineProps<Props>(), {})
</script>
