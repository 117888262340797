<template>
  <div class="content">
    <!-- User -->
    <div class="user">
      <div class="user__avatar">
        <SmAvatar :name="user?.name || user?.email || '??'" />
      </div>
      <div class="user__information">
        <div class="user__name">
          {{ user?.name }}
        </div>
        <div class="user__email">
          {{ user?.email }}
        </div>
      </div>
    </div>

    <!-- Message -->
    <el-collapse-transition>
      <div v-if="result" :class="alertClasses">
        <div class="icon">
          <v-icon
            v-if="result.status == 'success'"
            name="md-checkcircle-round"
            scale="1.5"
            class="fill-primary" />

          <v-icon
            v-else
            name="md-cancel-round"
            scale="1.5"
            class="fill-magenta" />
        </div>
        <div class="message">
          {{ result.message }}
        </div>
      </div>
    </el-collapse-transition>

    <SmInput v-model="search" label="Search" size="small" outline />

    <!-- License Groups -->
    <div class="license-group">
      <SmTable
        ref="softwareLicenseTable"
        key-field="id"
        :default-sorting="{
          by: 'name',
          asc: false,
        }"
        :columns="columns"
        :data="filteredLicenseGroups"
        :select-disabled="state !== 'selection'"
        :loading="tableLoad"
        selectable>
        <!-- Name -->
        <template #name="{ row }">
          <div
            :class="{
              'license-model-deleted': licensesToDelete.includes(row.id),
              'license-model-added': licensesToAdd.includes(row.id),
              'license-model': true,
            }">
            {{ row.name }}
          </div>
        </template>
        <template #revert_changes="{ row }">
          <div v-if="isChanged(row)">
            <SmTooltip :content="i18n.t('discardChanges')">
              <v-icon
                name="md-replay"
                scale="1.2"
                class="cursor-pointer fill-orange"
                @click="revertChange(row)" />
            </SmTooltip>
          </div>
          <div v-else></div>
        </template>
      </SmTable>
    </div>

    <!-- Button -->
    <div class="button">
      <SmButton
        :disabled="!user || changeCount === 0"
        size="large"
        :loading="state === 'loading'"
        @click="handleButtonClick">
        <template v-if="state === 'selection'">
          <span>{{ i18n.t('saveChangesCount', { count: changeCount }) }}</span>
        </template>
        <span v-else-if="state === 'loading'">{{
          i18n.t('licenseAddingProcess')
        }}</span>
        <span v-else-if="state === 'done'">{{ i18n.t('close') }}</span>
      </SmButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    LicenceGroup,
    LicenceOut,
    UserLicencesService,
  } from '@/client'
  import SmTable from '@/components/sm/SmTable.vue'
  import { Column } from './sm/SmTable.types'
  import { ApplicationStore } from '@/stores/application.store'

  const i18n = useI18n()

  const search = ref<string>('')

  const emit = defineEmits<{
    (e: 'groupEdited'): void
    (e: 'close'): void
  }>()

  const softwareLicenseTable =
    ref<ComponentExposed<typeof SmTable<LicenseGroupWithId>>>()

  const state = ref<'selection' | 'loading' | 'done'>('selection')
  const result = ref<{
    status: 'success' | 'error'
    message: string
  }>()

  const props = defineProps<{
    user: Account
    softwareId: string
    license: LicenceOut | null
  }>()

  const columns: Column<LicenceGroup>[] = [
    {
      label: 'Name',
      key: 'name',
      width: 100,
      sortable: true,
      sortFn: (a, b) => a.name.localeCompare(b.name),
    },
    {
      label: '',
      key: 'revert_changes',
      width: 10,
      sortable: false,
    },
  ]

  interface LicenseGroupWithId extends LicenceGroup {
    id: string
  }

  const licenseGroups = ref<LicenseGroupWithId[]>([])

  const filteredLicenseGroups: ComputedRef<LicenseGroupWithId[]> = computed(
    () => {
      return licenseGroups.value.filter((group) =>
        group.name.toLowerCase().includes(search.value.toLowerCase())
      )
    }
  )

  const initialSelection = ref<string[]>([])

  // #####################
  // Setup the table
  // #####################

  const tableLoad = ref(true)

  onMounted(async () => {
    await loadLicenseGroups()
    tableLoad.value = false
    setInitialSelection()
  })

  async function loadLicenseGroups() {
    const software = await loadSoftware()

    if (software === null) throw new Error('Software not found')

    licenseGroups.value = Object.entries(software.licence_groups || {}).map(
      ([id, model]) => ({ ...model, id: id })
    )
  }

  async function loadSoftware() {
    const cache = await ApplicationStore.getApplicationById(
      props.softwareId,
      true
    )

    if (cache?.licence_groups && Object.keys(cache.licence_groups).length) {
      return cache
    } else {
      return await ApplicationStore.getApplicationById(
        props.softwareId,
        false,
        {
          excludeLicenceGroups: false,
        }
      )
    }
  }

  function setInitialSelection() {
    if (props.license) {
      initialSelection.value = props.license.licence_groups
      softwareLicenseTable.value?.selectByIds(initialSelection.value)
    }
  }

  // #####################
  // Track the changes
  // #####################

  const selection: ComputedRef<LicenseGroupWithId[] | undefined> = computed(
    () => {
      return softwareLicenseTable.value?.selection
    }
  )

  const licensesToDelete = ref<string[]>([])
  const licensesToAdd = ref<string[]>([])

  watch(
    () => selection.value,
    (newSelection) => {
      // check what items was not selected before but is now
      licensesToAdd.value =
        newSelection
          ?.map((item) => {
            if (!initialSelection.value.includes(item.id)) {
              return item.id
            }
            return null
          })
          .filter((id) => id !== null) || []

      // check what items was selected before but is not now
      licensesToDelete.value = initialSelection.value
        .map((id) => {
          if (!newSelection?.some((item) => item.id === id)) {
            return id
          }
          return null
        })
        .filter((id) => id !== null)
    },
    {
      deep: true,
    }
  )

  function isChanged(group: LicenseGroupWithId) {
    return (
      licensesToDelete.value.includes(group.id) ||
      licensesToAdd.value.includes(group.id)
    )
  }

  const changeCount = computed(() => {
    return licensesToAdd.value.length + licensesToDelete.value.length
  })

  const alertClasses = computed(() => {
    return {
      alert: true,
      'alert-success': result.value?.status === 'success',
      'alert-error': result.value?.status === 'error',
    }
  })

  // #####################
  // Perform the changes
  // #####################

  async function addLicenseGroup(groupToAdd: string) {
    if (!props.license) return // Id is always present if license is given

    await UserLicencesService.assignLicenceToLicenceGroupApiV1SoftwareLicencesLicenceIdLicenceGroupsPost(
      {
        licenceId: props.license._id!,
        requestBody: {
          licence_group_id: groupToAdd,
        },
      }
    )
  }

  async function removeLicenseGroup(groupToRemove: string) {
    if (!props.license) return // Id is always present if license is given

    await UserLicencesService.unassignLicenceFromLicenceGroupApiV1SoftwareLicencesLicenceIdLicenceGroupsLicenceGroupIdDelete(
      {
        licenceId: props.license._id!,
        licenceGroupId: groupToRemove,
      }
    )
  }

  function handleButtonClick() {
    if (state.value === 'selection') {
      state.value = 'loading'
      Promise.all([
        ...licensesToAdd.value.map((licenseToAdd) =>
          addLicenseGroup(licenseToAdd)
        ),
        ...licensesToDelete.value.map((licenseToDelete) =>
          removeLicenseGroup(licenseToDelete)
        ),
      ])
        .then(() => {
          state.value = 'done'
          result.value = {
            status: 'success',
            message: i18n.t('licenseGroupEditSuccess'),
          }
        })
        .catch(() => {
          state.value = 'selection'
          result.value = {
            status: 'error',
            message: i18n.t('licenseGroupEditError'),
          }
        })
        .finally(() => {
          emit('groupEdited')
        })
    } else if (state.value === 'done') {
      emit('close')
    }
  }

  function revertChange(group: LicenseGroupWithId) {
    if (initialSelection.value.includes(group.id)) {
      softwareLicenseTable.value?.selectById(group.id)
    } else {
      softwareLicenseTable.value?.deselectById(group.id)
    }
  }
</script>

<style lang="scss" scoped>
  .user {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    max-width: 100%;

    &__name {
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__avatar {
      margin-right: 1rem;
      border-radius: var(--border-radius-base);
      box-shadow: var(--shadow);
      background-color: var(--sm-white);
    }
  }

  .button {
    width: 70%;
    margin: 30px auto 0 auto;
  }

  .alert {
    $message-padding: 0.5rem;
    margin: 1.5rem 0 1.5rem 0;
    padding: $message-padding;
    width: calc(100% - #{$message-padding * 2});
    border-radius: var(--border-radius-base);
    display: flex;
    align-items: center;

    &-success {
      border: 1px solid var(--sm-primary);
    }

    &-error {
      border: 1px solid var(--sm-magenta);
    }

    .message {
      margin-left: 0.5rem;
    }
  }

  .license-group {
    max-height: 350px;
    overflow: auto;
    margin-top: 1.3rem;
  }
</style>

<style lang="scss">
  .license-model {
    transition: all 0.2s ease-in-out;
  }

  .license-model-deleted {
    font-weight: 600;
    color: var(--sm-magenta);
  }

  .license-model-added {
    font-weight: 600;
    color: var(--sm-primary);
  }
</style>
