import { SelectQueryBuilder, sql } from 'kysely'
import { Database } from './database'
import { LicenceStatus } from '@/client'

export type AccountFilters = {
  tags?: string[]
  createdBefore?: string
  createdAfter?: string
  updatedBefore?: string
  updatedAfter?: string
  deletedBefore?: string
  deletedAfter?: string
  softwareId?: string
  status?: LicenceStatus[]
  isDeleted?: boolean
  isGuest?: boolean
  email?: string
  subAccountId?: string
  accountId?: string
  softwareLicenses?: string[] // JSON string representing a Record<string, SoftwareLicenseMini>
}

export function applyAccountFilters<T>(
  query: SelectQueryBuilder<Database, 'licence_out', T>,
  filters: AccountFilters
): SelectQueryBuilder<Database, 'licence_out', T> {
  if (filters.tags?.length) {
    query = query.where('tags', '@>', JSON.stringify(filters.tags))
  }
  if (filters.createdBefore) {
    query = query.where('created', '<', filters.createdBefore)
  }
  if (filters.createdAfter) {
    query = query.where('created', '>', filters.createdAfter)
  }
  if (filters.updatedBefore) {
    query = query.where('updated', '<', filters.updatedBefore)
  }
  if (filters.updatedAfter) {
    query = query.where('updated', '>', filters.updatedAfter)
  }
  if (filters.deletedBefore) {
    query = query.where('deleted_at', '<', filters.deletedBefore)
  }
  if (filters.deletedAfter) {
    query = query.where('deleted_at', '>', filters.deletedAfter)
  }
  if (filters.softwareId) {
    query = query.where('software_id', '=', filters.softwareId)
  }
  if (filters.subAccountId) {
    query = query.where('sub_account_id', '=', filters.subAccountId)
  }
  if (filters.status?.length) {
    query = query.where('status', 'in', filters.status) // Check if status matches any value in the array
  }
  if (filters.isDeleted !== true) {
    query = query.where('deleted', '=', filters.isDeleted ? 1 : 0)
  }
  if (filters.email) {
    query = query.where('email', 'like', `%${filters.email}%`)
  }
  if (filters.accountId) {
    query = query.where('account_id', '=', filters.accountId)
  }
  if (filters.isGuest) {
    query = query.where('is_guest', '=', filters.isGuest ? 1 : 0)
  }

  if (filters.softwareLicenses) {
    const keys = filters.softwareLicenses
    keys.forEach((key) => {
      query = query.where(
        (eb) =>
          sql`json_extract(${eb.ref('software_licenses')}, ${`$.${key}`}) IS NOT NULL`
      )
    })
  }

  return query
}
