<template>
  <div>
    <div
      v-loading="props.software === undefined"
      class="application-wrapper"
      @click="emit('createCustomSoftware', props.software, connectSettings)">
      <div class="tool-desc">
        <!-- Favicon of the tools -->
        <el-image
          lazy
          class="software-image"
          :src="`https://logos.saasmetrix.io/${props.software.name}.png`">
          <template #error>
            <v-icon name="md-hideimage-round" scale="1.6" />
          </template>
        </el-image>
        <!-- Name of the tool -->
        <div class="tool-desc-text">
          <div style="display: flex; align-items: center; gap: 10px">
            <span class="application-name">{{
              props.software.display_name
            }}</span>

            <!-- Show settings icon -->
            <div class="settings-icon">
              <v-icon
                name="md-settings-round"
                scale="0.8"
                @click.prevent.stop="handleConnectSettings" />
            </div>
          </div>
          <!-- Type of Tool OAuth/Bot/Template -->
          <div class="application-type">
            <span v-if="props.software.subtitle">
              {{ props.software.subtitle }} |
            </span>
            <span>{{
              i18n.t(
                `general.applicationTypeTag.${props.software.type || 'template'}`
              )
            }}</span>
          </div>
        </div>
      </div>

      <!-- Action Buttons -> Click to activate/add Software -->
      <div
        v-loading="props.software === undefined"
        class="center-create-button"
        content="this is content, this is content, this is content">
        <el-image
          v-if="
            props.software.type?.toLowerCase() === 'oauth' &&
            props.software.name === 'Google'
          "
          src="https://logos.saasmetrix.io/btn_google_signin_light_normal_web@2x.png"
          style="height: 36px; width: 150px" />
        <SmButton
          v-else
          size="small"
          :disabled="props.isDisabled || isDemoMode"
          @click.stop="
            emit('createCustomSoftware', props.software, connectSettings)
          ">
          {{ i18n.t('drawers.addApplicationDrawer.button.connect') }}
        </SmButton>
      </div>
    </div>

    <!-- Settings -->
    <div
      :class="['application-add-item-settings', { show: showConnectSettings }]"
      @click.stop>
      <div>
        <div class="application-add-item-settings__content">
          <!-- <hr /> -->
          <div class="application-add-item-settings__content__title">
            {{ i18n.t('connectionSettings') }}
          </div>

          <div class="application-add-item-settings__content__settings">
            <div>{{ i18n.t('readonly') }}:</div>
            <SmSwitch
              v-model="connectSettings.readonly"
              style="margin-left: auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { SoftwareInformation } from '@/client'

  const isDemoMode = computed(() => false)

  const props = defineProps({
    software: {
      type: Object as PropType<SoftwareInformation>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  })

  export interface ConnectSettings {
    readonly: boolean
  }

  const emit = defineEmits<{
    closeDrawer: []
    createCustomSoftware: [
      software: SoftwareInformation,
      settings: ConnectSettings,
    ]
  }>()

  const i18n = useI18n()

  const showConnectSettings = ref(false)
  const connectSettings = ref<ConnectSettings>({
    readonly: false,
  })
  function handleConnectSettings() {
    showConnectSettings.value = !showConnectSettings.value
  }
</script>

<style lang="scss" scoped>
  .paragraph {
    word-wrap: break-word;
  }

  .application-wrapper .settings-icon {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .application-wrapper:hover .settings-icon {
    opacity: 1;
  }
</style>

<style>
  .application-add-item-settings {
    display: grid;
    grid-template-rows: 0fr;

    transition: grid-template-rows 0.2s ease-in-out;
  }

  .application-add-item-settings > div {
    overflow: hidden;
  }

  .application-add-item-settings.show {
    grid-template-rows: 1fr;
  }

  .application-add-item-settings__content {
    padding: 10px;
    margin-left: calc(40px);
    padding-left: 1rem;

    hr {
      /* margin: 0; */
      border-top: 0.5px solid theme('colors.gray[500]');
    }
  }

  .application-add-item-settings__content__title {
    /* margin: 10px 0; */
    margin-bottom: 1px;
    font-weight: 500;
    /* color: theme('colors.gray[500]'); */
  }

  .application-add-item-settings__content__settings {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    align-items: center;

    .sm-switch {
      font-size: 0.85rem;
    }
  }
</style>
