<template>
  <!-- <ChatButton /> -->

  <SettingsDialog v-model:visibility="dialogStates.settings" />
  <FeedbackDialog v-model:visibility="dialogStates.feedback" />
  <SmNotificationList />
  <BaseOnboardingDialog
    v-if="store.$state.showOnboardingDialog"
    v-model:visibility="store.$state.showOnboardingDialog" />
  <DebuggingModal v-if="store.state.showDebugModal" />
  <SoftwareReauthenticateDialog
    v-if="applicationReauthStore.reauthSoftwareToHandle"
    v-model:visibility="store.$state.showReauthenticateDialog"
    :software="applicationReauthStore.reauthSoftwareToHandle?.software"
    @authentication-completed="
      applicationReauthStore.clearReauthSoftwareToHandle
    " />
  <SoftwareAuthFlowDialog
    v-if="applicationReauthStore.twoFactorAuthToHandle"
    v-model:visibility="store.$state.showAuthFlowDialog"
    :software-name="applicationReauthStore.twoFactorAuthToHandle.softwareName"
    :software-id="applicationReauthStore.twoFactorAuthToHandle.softwareId"
    :software-type="applicationReauthStore.twoFactorAuthToHandle.softwareType"
    :auth-type="applicationReauthStore.twoFactorAuthToHandle.authType"
    @authentication-completed="
      applicationReauthStore.clear2FaSoftwareToHandle()
    " />
  <el-config-provider
    key="configProvider"
    :locale="language === PreferenceLanguages.DE ? el_de : el_en">
    <!-- Maintenance Banner -->
    <div v-if="showMaintenanceBanner" class="maintenance-banner">
      <div></div>
      <div>
        {{ i18n.t('maintenance-mode') }}
      </div>
      <div @click="showMaintenanceBanner = false">X</div>
    </div>

    <div
      :class="{
        'main-wrapper': true,
        'hide-on-mobile': showMobileNotSupported,
      }">
      <component :is="currentLayout" class="no-print" />
      <router-view :key="route.path" v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>
    <div v-if="route.meta.requireLogin === true && isMobile" class="mobile">
      <img
        src="https://logos.saasmetrix.io/SM/saasmetrix@4x.png"
        class="mobile-icon" />
      <p>{{ i18n.t('mobileDisclaimer') }}</p>
    </div>
  </el-config-provider>
</template>

<script setup lang="ts">
  import {
    breakpointsTailwind,
    computedAsync,
    useBreakpoints,
    useIntervalFn,
    useMagicKeys,
  } from '@vueuse/core'
  import dayjs from 'dayjs'
  import duration from 'dayjs/plugin/duration'
  import isBetween from 'dayjs/plugin/isBetween'
  import el_de from 'element-plus/es/locale/lang/de'
  import el_en from 'element-plus/es/locale/lang/en'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'

  import BaseOnboardingDialog from '@/components/BaseOnboardingDialog/BaseOnboardingDialog.vue'

  import { OpenAPI, PreferenceLanguages } from '@/client'
  import { dialogStates } from './common/dialogManager'
  import { applyPreference } from './common/preferences/applyPreference'
  import DebuggingModal from './components/DebuggingModal.vue'
  import SmNotificationList from './components/sm/SmNotification/SmNotificationList.vue'
  import SoftwareAuthFlowDialog from './components/SoftwareAuthFlowDialog.vue'
  import TheSidebar from './components/TheSidebar.vue'
  import { useGlobalStore } from './stores/globalStore'
  import { usePreferenceStore } from './stores/preferenceStore'
  import { useSessionStore } from './stores/sessionStore'
  import { useApplicationReauthStore } from './stores/applicationReauthStore'
  import { updateAllCaches } from './stores/database'

  dayjs.locale('de')
  dayjs.extend(isBetween)
  dayjs.extend(duration)

  const store = useGlobalStore()
  const applicationReauthStore = useApplicationReauthStore()

  const route = useRoute()
  const i18n = useI18n()

  const showMaintenanceBanner = ref(false)

  const sessionStore = useSessionStore()

  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isMobile = breakpoints.smaller('sm')

  OpenAPI.BASE = store.getIp
  OpenAPI.CREDENTIALS = 'include'
  OpenAPI.WITH_CREDENTIALS = true

  // This is false if the user is in mobile and is on a route which is not requiring a login. Otherwise it is true.
  // Example:
  // Mobile Dashboard => true
  // Mobile Login => false
  const showMobileNotSupported = computed(
    () => isMobile.value && route.meta.requireLogin
  )

  const layouts: Record<string, unknown> = {
    Sidebar: TheSidebar,
  }

  const currentLayout = computed(() => {
    return layouts[route.meta.layout as string]
  })

  // *************** User Preferences ***************
  // This should endsure, that the user preferences are loaded before the app is mounted
  // or if the user is not logged in, that the default preferences are loaded

  // Update preferences on login or opening the app
  watch(sessionStore, () => {
    if (sessionStore.$state.currentUser) {
      const preferenceStore = usePreferenceStore()
      preferenceStore.getPreferences
    }
  })

  store.$subscribe((mutation) => {
    // Check if the jwt changed and user NOT logged out
    if (
      mutation.type === 'patch object' &&
      mutation.payload?.user?.jwt !== undefined &&
      mutation.payload.user.jwt !== null
    ) {
      // Load user preferences
      const preferenceStore = usePreferenceStore()
      preferenceStore.getPreferences
      // Load company info
      store.refetchCompanyInfo()
    }
  })

  // When preferences are loaded, or changed, apply them
  const preferenceStore = usePreferenceStore()
  preferenceStore.$subscribe((mutation) => {
    // Since we currently only have two preference, we can just apply it here
    if (mutation.type === 'patch object' && mutation.payload.preferences) {
      for (const [key, value] of Object.entries(mutation.payload.preferences)) {
        applyPreference(key, value)
      }
    }
  })

  const language = computedAsync(async () => {
    await preferenceStore.getPreferences()
    const currentLanguage = preferenceStore.preferences?.language

    if (currentLanguage) {
      i18n.locale.value = currentLanguage
    }
    return currentLanguage
  })

  // This watch sets the document title
  watch(route, async (route) => {
    document.title =
      `${
        String(route.name).charAt(0).toUpperCase() + String(route.name).slice(1)
      } | saasmetrix` || 'saasmetrix'
  })

  // This watch checks if the onboarding dialog needs to be shown
  watch(
    () => route.path,
    async () => {
      // Don´t check subscription if the route don`t require the user to be logged in
      if (route.meta.requireLogin !== true) {
        store.state.showOnboardingDialog = false
        return
      }

      preferenceStore.getPreferences().then((prefs) => {
        if (prefs.show_onboarding_dialog) {
          store.state.showOnboardingDialog = true
        } else store.state.showOnboardingDialog = false
      })
    }
  )

  onMounted(async () => {
    sessionStore.fetchCompanyInfo()
    sessionStore.checkSession()
  })

  // Update Caches
  useIntervalFn(
    async () => {
      updateAllCaches()
    },
    30_000,
    { immediate: true }
  )
  updateAllCaches()

  // Debugging Modals
  const { j, o, s } = useMagicKeys()
  watchEffect(() => {
    if (j.value && o.value && s.value) {
      store.state.showDebugModal = !store.state.showDebugModal
    }
  })
</script>

<style scoped>
  /* If the User uses an mobile enddevice */
  .hide-on-mobile {
    display: none;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    width: fit-content;
  }

  .mobile-icon {
    width: 80%;
  }

  .maintenance-banner {
    background-color: var(--sm-orange);
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;

    display: flex;
    justify-content: space-between;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    z-index: 10000;
  }
</style>

<style>
  @media print {
    .no-print {
      display: none !important;
    }

    .print-only {
      display: block;
    }
  }
</style>
