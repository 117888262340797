import { defineStore } from 'pinia'

import { SoftwareLicenseCost, SoftwareLicensesService } from '@/client'

export const useLicenseStore = defineStore('licenseStore', () => {
  const softwareLicenseCosts = ref<
    Map<string, Record<string, SoftwareLicenseCost>>
  >(new Map())

  /**
   * SoftwareLicenseService
   */
  async function getSoftwareLicenseCosts({
    softwareId,
    useCached = true,
  }: {
    softwareId: string
    useCached?: boolean
  }): Promise<Record<string, SoftwareLicenseCost> | undefined> {
    const isCached = softwareLicenseCosts.value.get(softwareId)
    if (isCached && useCached) return isCached
    else {
      return SoftwareLicensesService.getSoftwareLicensesCostsForSoftwareApiV1SoftwareSoftwareSoftwareIdLicensesCostsGet(
        {
          softwareId: softwareId,
        }
      ).then((response) => {
        softwareLicenseCosts.value.set(softwareId, response)
        return response
      })
    }
  }

  return {
    getSoftwareLicenseCosts,
  }
})
