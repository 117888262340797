<template>
  <div
    v-if="showCosts || showQuota"
    class="rounded bg-gray-100 p-4 dark:bg-gray-800">
    <!-- Cost Division -->
    <template v-if="showCosts">
      <h4 class="mb-4">{{ i18n.t('compositionOfCosts') }}</h4>
      <div class="flex items-center justify-between gap-4">
        <div>
          <div class="mb-3 flex items-end gap-2">
            <i18n-t
              keypath="XPerMonth"
              tag="p"
              class="leading-8 text-contrast-muted">
              <template #x>
                <span class="text-3xl font-medium text-contrast">
                  {{ getCorrectedPrice(props.costs.total_cost ?? 0) }}
                </span>
              </template>
            </i18n-t>
          </div>
          <div>
            <p
              v-for="data in costsItems"
              :key="data.id"
              class="flex items-center">
              <span
                class="block h-2 w-2 shrink-0 rounded"
                :style="{ backgroundColor: `var(${data.color})` }"></span>
              <TextMultiline class="pl-2">{{ data.name }}</TextMultiline>
              <span class="whitespace-nowrap pl-3 font-bold">{{
                getCorrectedPrice(data.costs)
              }}</span>
            </p>
          </div>
        </div>
        <DoughnutChart
          ref="doughnutRef"
          class="w-1/3 shrink-0 p-4"
          :chart-data="costsChartData"
          :options="costsChartOptions" />
      </div>
    </template>

    <hr v-if="showCosts && showQuota" class="my-4 border-contrast-muted" />

    <!-- Quota Division -->
    <div v-if="showQuota" class="pb-4">
      <h4 class="mb-4">{{ i18n.t('priceDivision.title') }}</h4>
      <i18n-t
        keypath="XTotalUnits"
        tag="p"
        class="leading-8 text-contrast-muted">
        <template #x>
          <span class="pr-1 text-3xl font-medium text-contrast">{{
            props.totalQuota
          }}</span>
        </template>
      </i18n-t>

      <PriceInformationDivision
        :items="quotaItems"
        :total-quota="props.totalQuota"
        class="my-4 overflow-hidden rounded" />

      <div class="flex items-center gap-3">
        <div>
          <p
            v-for="data in quotaItems"
            :key="data.id"
            class="flex items-center">
            <span
              class="block h-2 w-2 shrink-0 rounded"
              :style="{ backgroundColor: `var(${data.color})` }"></span>
            <TextMultiline class="pl-2" :text="data.name" />
            <span
              v-if="data.quota != null"
              class="whitespace-nowrap pl-3 font-bold">
              {{
                data.quota > 0
                  ? data.quota
                  : `${
                      props.totalQuota - props.manualActiveQuota
                    } (${i18n.t('leftoverDetectedQuota')})`
              }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'
  import { PriceInformation, SoftwareLicenseCost } from '@/client'
  import { DoughnutChart } from 'vue-chart-3'
  import { getCorrectedPrice } from '@/common/licenceModel'

  const i18n = useI18n()

  const props = defineProps({
    softwareId: {
      type: String,
      required: true,
    },
    licenseId: {
      type: String,
      required: true,
    },
    activeContracts: {
      type: Object as PropType<PriceInformation[]>,
      required: true,
    },
    totalQuota: {
      type: Number,
      required: true,
    },
    manualActiveQuota: {
      type: Number,
      required: true,
    },
    externalQuota: {
      type: Number,
      required: true,
    },
    costs: {
      type: Object as PropType<SoftwareLicenseCost>,
      required: true,
    },
  })

  function getPriceInformationCost(id: string) {
    if (props.costs && props.costs.price_information_costs[id]) {
      return props.costs.price_information_costs[id].total_cost
    }
    return 0
  }

  const showCosts = computed(() => costsItems.value?.length > 1)

  const showQuota = computed(
    () =>
      props.activeContracts.filter((c) => c.quota !== null).length > 1 ||
      (props.activeContracts.filter((c) => c.quota !== null).length === 1 &&
        props.externalQuota > 0)
  )

  /*
   * Chart
   */

  export interface PriceInformationChartItem {
    id: string
    name: string
    costs: number
    quota: number | null
    color: string
  }

  const randomColors = [
    '--sm-primary',
    '--sm-blue',
    '--sm-purple',
    '--sm-magenta',
    '--sm-orange',
    '--sm-yellow',
  ]

  const chartItems = computed<PriceInformationChartItem[]>(() =>
    props.activeContracts.map((priceInformation, index) => ({
      id: priceInformation._id,
      name: priceInformation.description,
      costs: getPriceInformationCost(priceInformation._id),
      quota: priceInformation.quota,
      color: getColorByIndex(index),
    }))
  )

  const costsItems = computed(() => {
    // only show items that have a price
    return chartItems.value
      .sort((a, b) => a.costs - b.costs)
      .filter((item) => item.costs > 0)
  })

  const quotaItems = computed(() => {
    const itemsWithQuota = chartItems.value
      .filter((item) => item.quota != null)
      .sort((a, b) => (b.quota ?? 0) - (a.quota ?? 0))
    const fallbackItems = itemsWithQuota.filter((item) => item.quota === 0)

    if (fallbackItems.length > 1) {
      //if multiple return the cheapest one
      return itemsWithQuota.filter((item) => {
        if (item.quota === 0) {
          return (
            item.costs === Math.min(...fallbackItems.map((item) => item.costs))
          )
        }
        return true
      })
    } else {
      return itemsWithQuota
    }
  })

  const costsChartData = computed(() => {
    const input = costsItems.value.filter((item) => item.costs > 0)
    const style = getComputedStyle(document.body)

    return {
      labels: input.map((item) => item.name),
      datasets: [
        {
          data: input.map((item) => item.costs),
          backgroundColor: input.map((item) =>
            style.getPropertyValue(item.color)
          ),
        },
      ],
    }
  })

  const costsChartOptions = ref({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: '70%',
    elements: {
      arc: {
        borderWidth: 0,
        borderRadius: 1,
        borderColor: 'white',
      },
    },
  })

  function getColorByIndex(index: number): string {
    if (randomColors.length === 0) {
      return '--sm-primary'
    }
    const adjustedIndex = index % randomColors.length
    return randomColors[adjustedIndex]
  }
</script>
