export default {
  properties: {
    _id: {
      title: 'Id',
      type: 'string',
      maxLength: 50,
    },
    created: {
      format: 'date-time',
      title: 'Created',
      type: 'string',
    },
    updated: {
      format: 'date-time',
      title: 'Updated',
      type: 'string',
    },
    is_deleted: {
      format: 'string',
      title: 'Deleted',
      type: 'boolean',
    },
    deleted_at: {
      anyOf: [
        {
          format: 'date-time',
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Deleted At',
    },
    title: {
      title: 'Title',
      type: 'string',
      maxLength: 100,
    },
    description: {
      title: 'Description',
      type: 'string',
    },
    status: {
      enum: ['backlog', 'upcoming', 'progress', 'review', 'done'],
      title: 'Status',
      type: 'string',
      maxLength: 100,
    },
    background_job_ids: {
      title: 'Background IDs',
      type: 'array',
      items: {
        type: 'string',
      },
    },
    created_by: {
      title: 'created_by',
      anyOf: [
        {
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      maxLength: 50,
    },
    updated_by: {
      title: 'updated_by',
      anyOf: [
        {
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      maxLength: 50,
    },
  },
  required: ['_id', 'title', 'status'],
  title: 'TaskBase',
  type: 'object',
  version: 0,
  primaryKey: '_id',
  indexes: ['title'],
} as const
