<template>
  <!-- License Drawer -->
  <DrawerLicense
    v-if="licenseDetailDrawerLicense?._id"
    :key="licenseDetailDrawerLicense?._id"
    v-model:visibility="licenseDetailDrawerVisibility"
    v-model:license="licenseDetailDrawerLicense"
    :software-licenses="licenseDetailSoftwareLicenses"
    :price-informations="priceInformations"
    :sub-account-id="props.subAccountId"
    @license-changed="handleLicenceChanged" />

  <!-- User Drawer -->
  <UsersDrawer
    v-if="userDetailsDrawerVisibility && userDetailsDrawerUserId"
    v-model:drawer-visibility="userDetailsDrawerVisibility"
    :user-id="userDetailsDrawerUserId"
    @close="userDetailsDrawerUserId = null" />

  <!-- Deactivate License -->
  <LicenseDeactivateDialog
    v-if="licenseToDeactivate"
    v-model:visibility="deactivateLicenseDialogVisible"
    :license="licenseToDeactivate"
    @close="deactivateLicenseDialogVisible = false" />

  <!-- Deleting a single license -->
  <DeleteConfirmDialog
    v-if="licenseToDelete"
    v-model:visible="deleteLicenseDialogVisible"
    :loading="deleteLicenseLoading"
    :title="i18n.t('deleteLicence')"
    :sub-title="
      i18n.t('views.applicationDetails.deleteLicenseOf', {
        email: licenseToDelete?.email,
      })
    "
    @cancel="deleteLicenseDialogVisible = false"
    @delete="deleteLicence(licenseToDelete!)" />

  <!-- Edit license -->
  <SmDialog
    v-model:visibility="visibilityEditDialog"
    size="medium"
    :title="
      editLicenseSoftware?.display_name || editLicenseSoftware?.software_name
    ">
    <ContentLicenseEdit
      v-if="editLicenseSoftware && editLicenseUser && editLicenseLicense"
      :user="[editLicenseUser]"
      :software-licenses="editLicenseSoftwareLicenses"
      :software="editLicenseSoftware"
      :license="editLicenseLicense"
      @close="handleLicenceEditClose" />
  </SmDialog>

  <!-- Edit License Group -->
  <SmDialog
    v-model:visibility="editLicenseGroupDialog"
    :title="i18n.t('editGroup')">
    <LicenseGroupEditContent
      v-if="editLicenseSoftware && editLicenseUser && editLicenseLicense"
      :user="editLicenseUser"
      :license="editLicenseLicense"
      :software-id="editLicenseSoftware._id"
      @close="editLicenseGroupDialog = false"
      @group-edited="() => updateLicenses({ allowCached: false })" />
  </SmDialog>

  <!-- Table -->
  <SmTable
    ref="table"
    :data="data"
    :columns="columns"
    :loading="loadingLicences"
    :selectable="props.select"
    :style="{
      height: `calc(100% - ${currentSoftware?.sub_accounts ? 50 : 0}px)`,
    }"
    key-field="_id"
    :load-all-fn="getAllLicences"
    @scroll-end-reached="loadMoreLicences"
    @cell-click="handleCellClick">
    <template #software_id="{ row }">
      <div class="software_name">
        <el-image
          loading="lazy"
          class="integration_icon"
          :src="`https://logos.saasmetrix.io/${
            softwareSingleComputed(row.software_id).value?.software_name
          }.png`"
          style="width: 23px; height: 23px; min-width: 23px">
          <template #error>
            <v-icon name="md-hideimage-round" scale="1.2" />
          </template>
        </el-image>

        <TextMultiline
          :text="
            softwareSingleComputed(row.software_id).value?.display_name ||
            softwareSingleComputed(row.software_id).value?.software_name
          "
          :show-tool-tip="false" />
      </div>
    </template>

    <!-- Email -->
    <template #email="{ row }">
      <!-- Alias Selector -->

      <AliasSelect
        v-if="!row.account_id"
        :license="row"
        :users="users"
        @license-changed="updateLicenses()" />

      <!-- Email -->
      <TextMultiline
        v-else
        :text="row.email"
        class="email-text"
        :show-tool-tip="false" />
      <!-- Guest -->
      <span v-if="row?.is_guest" class="guest"> ({{ i18n.t('guest') }})</span>
    </template>

    <!-- Phone Number -->
    <template #phone="{ row }">
      <span v-if="row.application_specific?.phone_number">{{
        row.application_specific.phone_number
      }}</span>
    </template>

    <template #software_licenses="{ row }">
      <LicenceModelNamesDisplay
        :software-licenses="Object.values(row.software_licenses || {})" />
    </template>

    <!-- SubAccount -->
    <template #sub_account_id="{ row }">
      <span
        v-if="
          row.sub_account_id &&
          softwares.find((s) => s._id === row.software_id)?.sub_accounts
        ">
        {{
          // @ts-ignore-next-line undefined check is made in the v-if
          softwares.find((s) => s._id === row.software_id)?.sub_accounts[
            row.sub_account_id
          ].name || row.sub_account_id
        }}
      </span>
    </template>

    <!-- Status -->
    <template #status="{ row }">
      <LicenceStatusSmall v-if="row.status" :status="row.status" />
    </template>

    <!-- Last Login -->
    <template #last_login_time="{ row }">
      <span v-if="row.last_login_time">
        {{ displayDate(new Date(row.last_login_time), { dateStyle: 'short' }) }}
      </span>
      <span v-else class="no-data">
        {{ i18n.t('views.licences.table.neverLoggedIn') }}
      </span>
    </template>

    <!-- Price -->
    <template #price="{ row }">
      <span v-if="row._id! in costs">
        {{ getCorrectedPrice(costs[row._id!]) }}</span
      >
      <span v-else>-</span>
    </template>

    <!-- statusIndicator -->
    <template #statusIndicator="{ row }">
      <LicenceStatusSmall
        v-if="row.status"
        :status="row.status"
        :show-text="false" />
    </template>

    <!-- License Count -->
    <template #license_count="{ row }">
      <span
        v-if="row.software_licenses"
        :class="{ 'no-data': Object.keys(row.software_licenses).length === 0 }">
        {{
          i18n.t('general.licenses', Object.keys(row.software_licenses).length)
        }}
      </span>
      <span v-else class="no-data">
        {{ i18n.t('general.licenses', 0) }}
      </span>
    </template>

    <!-- Custom Columns -->
    <template v-for="column in props.customColumns" #[column.key]="{ row }">
      <slot :name="column.key" :row="row" />
    </template>

    <template #action="{ row }">
      <SmDropdown trigger="click">
        <!-- Show -->
        <SmDropdownItem
          icon="md-description-round"
          @item-click="showLicense(row)">
          {{ i18n.t('showAccount') }}
        </SmDropdownItem>
        <!-- Edit -->
        <SmDropdownItem
          v-if="softwareLicenseAssignable && row.account_id"
          icon="md-modeedit-round"
          @item-click="showEditLicence(row)">
          {{ i18n.t('editLicence') }}
        </SmDropdownItem>

        <!-- Edit Groups -->
        <SmDropdownItem
          v-if="isGroupEdit(row.software_id)"
          v-require-permission="CompanyAccessRole.ADMIN"
          :disabled="softwareSingleComputed(row.software_id)?.value?.read_only"
          :tooltip="readonlyTooltip"
          icon="md-group-round"
          @item-click="showEditGroup(row)">
          {{ i18n.t('editGroup') }}
        </SmDropdownItem>

        <!-- Show User -->
        <SmDropdownItem
          v-if="row.account_id"
          icon="md-person-round"
          @item-click="row.account_id ? showUser(row.account_id) : null">
          {{ i18n.t('showUser') }}
        </SmDropdownItem>

        <!-- Activate License -->
        <SmDropdownItem
          v-if="
            softwareSingleComputed(row.software_id)?.value?.information
              .implements_disable_licence_method && row.status === 'disabled'
          "
          v-require-permission="CompanyAccessRole.ADMIN"
          icon="md-key-round"
          :disabled="softwareSingleComputed(row.software_id)?.value?.read_only"
          :tooltip="readonlyTooltip"
          hover-color="var(--sm-primary)"
          @item-click="handleLicenseDeactivate(row)">
          {{ i18n.t('activateAccount') }}
        </SmDropdownItem>

        <!-- Deactivate License -->
        <SmDropdownItem
          v-if="
            softwareSingleComputed(row.software_id)?.value?.information
              .implements_disable_licence_method && row.status !== 'disabled'
          "
          v-require-permission="CompanyAccessRole.ADMIN"
          :disabled="softwareSingleComputed(row.software_id)?.value?.read_only"
          :tooltip="readonlyTooltip"
          icon="md-keyoff-round"
          hover-color="var(--sm-orange)"
          @item-click="handleLicenseDeactivate(row)">
          {{ i18n.t('deactivateAccount') }}
        </SmDropdownItem>

        <!-- Delete License -->
        <SmDropdownItem
          v-if="
            softwareSingleComputed(row.software_id)?.value?.information
              .implements_delete_user_method
          "
          v-require-permission="CompanyAccessRole.ADMIN"
          icon="md-delete-round"
          hover-color="var(--sm-magenta)"
          :tooltip="readonlyTooltip"
          :disabled="softwareSingleComputed(row.software_id)?.value?.read_only"
          @item-click="handleLicenseDelete(row)">
          {{ i18n.t('deleteLicence') }}
        </SmDropdownItem>
      </SmDropdown>
    </template>

    <template v-if="selection && selection?.length > 0" #footer>
      <LicenseTableBulkEdit
        :selection="selection"
        :available-licenses="filteredLicenseModelRows"
        :total-accounts="initialFilteredCount ?? 0"
        @request-licences-refetch="updateLicenses()"
        @reset-selection="resetSelection()" />
    </template>
  </SmTable>
</template>

<script lang="ts" setup>
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    ApiError,
    CompanyAccessRole,
    Licence_Output,
    LicenceOut,
    PriceInformation,
    SoftwareCostService,
    SoftwareLicense,
    SoftwareLicenseMini,
    SoftwareLicensesService,
    SoftwareOut,
  } from '@/client'
  import { getCorrectedPrice } from '@/common/licenceModel'
  import DeleteConfirmDialog from '@/components/DeleteConfirmDialog.vue'
  import DrawerLicense from '@/components/DrawerLicense.vue'
  import SmTable from '@/components/sm/SmTable.vue'
  import UsersDrawer from '@/components/UsersDrawer.vue'
  import { useApplicationReauthStore } from '@/stores/applicationReauthStore'
  import { useUserStore } from '@/stores/userStore'
  import { useApplicationDetailsStore } from '@/views/ApplicationsDetail/ApplicationDetailsView.store'
  import { displayDate } from '@/common/util/timeUtils'
  import { useGlobalStore } from '@/stores/globalStore'
  import { useRoute } from 'vue-router'
  import ContentLicenseEdit from './ContentLicenseEdit.vue'
  import LicenceStatusSmall from './LicenceStatusSmall.vue'
  import LicenseDeactivateDialog from './LicenseDeactivateDialog.vue'
  import LicenseGroupEditContent from './LicenseGroupEditContent.vue'
  import { sendToast } from './sm/SmNotification'
  import { Column } from './sm/SmTable.types'
  import LicenseTableBulkEdit from './LicenseTableBulkEdit.vue'
  import { capitalize } from '@/common/util/formatter'
  import { AccountStore } from '@/stores/account.store'
  import { ApplicationStore } from '@/stores/application.store'
  import { AccountFilters, applyAccountFilters } from '@/stores/account.filter'

  export type LicenseFilterType = {
    softwareLicenses: string[]
    hideGuests: boolean
    onlyShowActive: boolean
    tags?: string[]
    softwareId?: string
  }

  const i18n = useI18n()
  const route = useRoute()
  const userStore = useUserStore()
  const softwareStore = useApplicationReauthStore()
  const table = ref<ComponentExposed<typeof SmTable<Licence_Output>>>()

  // Edit License
  const editLicenseUser = ref<Account>()
  const editLicenseSoftware = ref<SoftwareOut>()
  const editLicenseLicense = ref<Licence_Output>()
  const visibilityEditDialog = ref(false)
  const editLicenseGroupDialog = ref(false)
  const deleteLicenseDialogVisible = ref(false)
  const licenseToDelete = ref<Licence_Output>()
  const deleteLicenseLoading = ref(false)
  const isSSO = computed(() => route.query.sso === 'true')
  const currentSubaccount = computed(() => route.params.subAccountId as string)

  // Deactivate License
  const deactivateLicenseDialogVisible = ref(false)
  const licenseToDeactivate = ref<Licence_Output>()

  // Filter Dialog
  const visibilityFilterDialog = ref<boolean>(false)

  const softwareLicenseAssignable = computed(() => {
    if (!props.subAccountId || !currentSoftware.value?.sub_accounts) return true

    return true
  })

  interface Props {
    searchQuery?: string
    softwareId?: string
    subAccountId?: string
    accountId?: string
    displaySoftwareName?: boolean
    displayStatus?: boolean
    filter?: AccountFilters
    displayPrice?: boolean
    displayStatusIndicator?: boolean
    displayLicenceCount?: boolean
    displayLastLoggedIn?: boolean
    displayPhoneNumber?: boolean
    select?: boolean
    displaySubAccount?: boolean
    displayEmail?: boolean
    onlyCached?: boolean
    displayName?: boolean
    customColumns?: Column<Licence_Output>[]
    displayActions?: boolean
    clickAction?: (row: Licence_Output) => void
  }

  //   Props with default values
  const props = withDefaults(defineProps<Props>(), {
    searchQuery: undefined,
    softwareId: undefined,
    subAccountId: undefined,
    accountId: undefined,
    displaySoftwareName: false,
    displayStatus: false,
    displayPrice: false,
    displayStatusIndicator: false,
    displayLicenceCount: false,
    displayLastLoggedIn: true,
    select: false,
    displaySubAccount: false,
    displayEmail: true,
    displayName: false,
    displayPhoneNumber: false,
    customColumns: () => [],
    displayActions: true,
    clickAction: undefined,
    filter: undefined,
    onlyCached: false,
  })

  const emit = defineEmits<{ licensesChanged: [] }>()
  const applicationDetailsStore = useApplicationDetailsStore()

  function handleCellClick(_: number, columnKey: string, row: Licence_Output) {
    // Index is not used
    if (columnKey === 'selection') {
      // do nothing
    } else if (columnKey === 'action') {
      // do nothing
    } else if (
      columnKey === 'email' &&
      currentSoftware.value?.information.returns_usernames &&
      !row.account_id
    ) {
      // do nothing
    } else {
      if (props.clickAction) {
        props.clickAction(row)
      } else {
        showLicense(row)
      }
    }
  }

  const columns = computed(() => {
    let _columns: Column<Licence_Output>[] = []

    if (props.displayStatusIndicator) {
      //   Add status column as first column
      _columns.push({
        key: 'statusIndicator',
        label: '',
        width: '10px',
        sortable: false,
      })
    }

    if (props.displayEmail) {
      if (!_columns.find((c) => c.key === 'email')) {
        _columns.push({
          key: 'email',
          label: i18n.t('views.licences.table.email') as string,
          width: 2,
          sortFn: (a: Licence_Output, b: Licence_Output) => {
            return a.email.toLowerCase().localeCompare(b.email.toLowerCase())
          },
          footer: ref(
            i18n.t('accountCount', { count: countFilteredAccounts.value })
          ),
        })
      }
    }

    if (props.displaySoftwareName) {
      _columns.push({
        key: 'software_id',
        label: capitalize(i18n.t('application', { count: 2 })),
        width: 1,
        sortFn: (a: Licence_Output, b: Licence_Output) => {
          const nameA =
            softwareSingleComputed(
              a.software_id
            ).value?.software_name.toLowerCase() || ''
          const nameB =
            softwareSingleComputed(
              b.software_id
            ).value?.software_name.toLowerCase() || ''
          return nameA.localeCompare(nameB)
        },
      })
    }

    if (props.displayStatus) {
      //   Add status column as third last column
      _columns.push({
        key: 'status',
        label: 'Status',
        width: 1,
      })
    }

    if (props.displaySubAccount && atLeastOneSubAccount.value) {
      //   Add sub account column as second last column
      _columns.push({
        key: 'sub_account_id',
        label: i18n.t('subAccount') as string,
        width: 1,
      })
    } else {
      _columns = _columns.filter((c) => c.key !== 'sub_account')
    }

    // If name is in any license, add it
    if (props.displayName && data.value.some((l) => l.name)) {
      _columns.push({
        key: 'name',
        label: i18n.t('name') as string,
        width: 1,
      })
    }

    if (props.displayPhoneNumber) {
      _columns.push({
        key: 'phone',
        label: i18n.t('phone') as string,
        width: 1,
      })
    }

    if (props.displayPrice) {
      //   Add price column as second last column
      _columns.push({
        key: 'price',
        label: i18n.t('price'),
        sortable: false,
        width: 1,
        sortFn: (a: Licence_Output, b: Licence_Output) => {
          return costs.value[a._id!] - costs.value[b._id!]
        },
        footer: ref(totalCosts.value),
      })
    }

    if (displayLastLogin.value && props.displayLastLoggedIn) {
      //   Add last login column as second last column
      _columns.push({
        key: 'last_login_time',
        label: i18n.t('views.licences.table.lastLogin') as string,
        width: 1,
      })
    }

    if (props.displayLicenceCount) {
      //   Add licence count column as second last column
      _columns.push({
        key: 'license_count',
        label: i18n.t('views.licences.table.licenseCount') as string,
        width: 1,
        sortable: true,
        sortFn: (a: Licence_Output, b: Licence_Output) => {
          return (
            Object.keys(a.software_licenses || {}).length -
            Object.keys(b.software_licenses || {}).length
          )
        },
      })
    }

    if (softwareLicenseAssignable.value) {
      _columns.push({
        key: 'software_licenses',
        label: i18n.t('views.licences.table.licenceModel') as string,
        width: 1,
        sortable: false,
      })
    }

    _columns = [..._columns, ...props.customColumns]

    if (props.displayActions) {
      //   Add action column as last column
      _columns.push({
        key: 'action',
        label: '',
        width: '60px',
        sortable: false,
      })
    }

    return _columns
  })

  const licenseDetailDrawerVisibility = ref(false)
  const licenseDetailDrawerLicense = ref<Licence_Output>()

  const userDetailsDrawerVisibility = ref(false)
  const userDetailsDrawerUserId = ref<string | null>(null)

  const softwares = ApplicationStore.useApplications().data
  const loadingLicences = ref<boolean>(false)
  const licences = ref<Licence_Output[]>([])
  const softwareLicenses = ref<SoftwareLicense[]>([])
  const priceInformations = ref<PriceInformation[]>([])

  const readonlyTooltip = computed(() => {
    if (currentSoftware.value?.read_only) {
      return i18n.t('readonlyDesc')
    }
    return false
  })

  // ######################################
  // ############# Pagination #############
  // ######################################

  const INITIAL_ROW_LIMIT = 300
  const ROW_EXPAND_COUNT = 100

  const filter = computed(() => {
    return {
      softwareId: props.softwareId,
      subAccountId: props.subAccountId,
      ...props.filter,
    }
  })

  watch(filter, () => {
    updateParameters(filter.value)
  })

  watch(AccountStore.loadingFullReload, () => {
    loadingLicences.value = AccountStore.loadingFullReload.value
  })

  const { data, updateParameters } = AccountStore.useAccounts(
    filter,
    { limit: INITIAL_ROW_LIMIT },
    { column: 'email' }
  )

  const { count: countFilteredAccounts } = AccountStore.useAccountsCount(filter)

  async function loadMoreLicences() {
    updateParameters({}, { limit: data.value.length + ROW_EXPAND_COUNT })
  }

  async function getAllLicences(): Promise<Licence_Output[]> {
    const query = AccountStore.getDatabase().selectAll()
    const newQuery = applyAccountFilters(query, filter.value)
    const allLicenses = await newQuery.execute()

    return allLicenses
  }

  const { count: totalLicensesCount } = AccountStore.useAccountsCount()
  const initialFilteredCount = ref<number | undefined>()

  AccountStore.getAccountsCount(filter.value).then((r) => {
    initialFilteredCount.value = r
  })

  // Watch for changes in the sorting
  const sorting = computed(() => {
    return {
      column: table.value?.sorting.by as keyof LicenceOut,
      direction: table.value?.sorting.asc ? 'asc' : ('desc' as 'asc' | 'desc'),
    }
  })

  watch(
    () => sorting.value,
    (newVal) => {
      updateParameters({}, {}, newVal)
    }
  )

  // ######################################
  // ############# Cost Calculation #######
  // ######################################

  const costs = ref<Record<string, number>>({})

  const filteredIds = ref<{ _id: string | null }[]>([])

  watch(
    filter,
    async () => {
      const query = AccountStore.getDatabase().select('_id')
      const newQuery = applyAccountFilters(query, filter.value)
      filteredIds.value = await newQuery.execute()
    },
    { immediate: true }
  )

  const filteredCosts = computed(() => {
    const ids = new Set(filteredIds.value.map(({ _id }) => _id))

    return Object.fromEntries(
      Object.entries(costs.value).filter(([key]) => ids.has(key))
    )
  })

  const totalCosts = computed(() => {
    return getCorrectedPrice(
      Object.values(filteredCosts.value).reduce((a, b) => a + b, 0)
    )
  })

  const atLeastOneSubAccount = computed(() => {
    // Check if in licenses at least one sub account is present
    return licences.value.some((licence) => licence.sub_account_id)
  })

  const users = ref<Account[]>([])

  // Check if last Login should be displayed
  // PERF: This could be faster if we just take the first one with a last login
  const displayLastLogin: Ref<boolean> = ref(true)
  watch(data, async () => {
    const query = AccountStore.getDatabase().select(({ fn }) => [
      fn.count<number>('last_login_time').as('count'),
    ])

    const newQuery = applyAccountFilters(query, filter.value)

    const result = await newQuery.execute()

    displayLastLogin.value = result[0].count > 0
  })

  function handleLicenseDelete(l: Licence_Output) {
    deleteLicenseDialogVisible.value = true
    licenseToDelete.value = l
  }

  function handleLicenseDeactivate(l: Licence_Output) {
    deactivateLicenseDialogVisible.value = true
    licenseToDeactivate.value = l
  }

  function showLicense(licence: Licence_Output) {
    licenseDetailDrawerLicense.value = licence
    licenseDetailDrawerVisibility.value = true
  }

  function showUser(id: string) {
    userDetailsDrawerUserId.value = id
    userDetailsDrawerVisibility.value = true
  }

  const filteredLicenseModelRows = computed(() => {
    if (isSSO.value) {
      return softwareLicenses.value.filter(
        (license) => license.sub_account_id == currentSubaccount.value || null
      )
    }
    return softwareLicenses.value
  })

  const editLicenseSoftwareLicenses = computed(() => {
    if (!editLicenseSoftware.value) return []
    return softwareLicenses.value.filter((sl) => {
      return (
        (sl.software_id === editLicenseSoftware.value?._id &&
          sl.sub_account_id == editLicenseLicense.value?.sub_account_id) ||
        sl.sub_account_id == null
      )
    })
  })

  const licenseDetailSoftwareLicenses = computed(() => {
    if (!licenseDetailDrawerLicense.value) return softwareLicenses.value
    return softwareLicenses.value.filter(
      (sl) => sl.software_id === licenseDetailDrawerLicense.value!.software_id
    )
  })

  const currentSoftware = computed(() => {
    if (!props.softwareId) return
    return softwares.value.find((software) => {
      return software._id == props.softwareId
    })
  })

  function isGroupEdit(software_id: string) {
    if (
      currentSoftware?.value?.information
        .implements_assign_licence_to_licence_group_method
    )
      return true

    const software = softwares.value.find((software) => {
      return software._id == software_id
    })

    if (software?.information.implements_assign_licence_to_licence_group_method)
      return true
  }

  function softwareSingleComputed(softwareId: string) {
    return computed(() => {
      return softwares.value.find((software) => {
        return software._id == softwareId
      })
    })
  }

  function deleteLicence(licence: Licence_Output) {
    if (!licence?._id) return // _id should always be set
    deleteLicenseLoading.value = true

    const updateQuotaObject: Record<string, SoftwareLicenseMini | null> = {}

    for (const key in licence.software_licenses) {
      if (
        Object.prototype.hasOwnProperty.call(licence.software_licenses, key)
      ) {
        updateQuotaObject[key] = null
      }
    }

    AccountStore.deleteAccount(licence._id)
      .then(async () => {
        applicationDetailsStore.updateQuota(updateQuotaObject)
        sendToast(i18n.t('deleteLicenseSuccess'), '', 'success')
        deleteLicenseDialogVisible.value = false
      })
      .finally(() => {
        deleteLicenseLoading.value = false
        emit('licensesChanged')
      })
  }

  function resetSelection() {
    table.value?.resetSelection()
  }

  // #region Update Licenses
  async function updateLicenses({ allowCached = props.onlyCached } = {}) {
    AccountStore.refreshAccountCacheFromApi(
      {
        softwareId: props.softwareId,
        subAccountId: props.subAccountId,
      },
      allowCached
    )
      .then(async ({ error }) => {
        if (error instanceof ApiError && props.softwareId) {
          switch (error?.body?.detail?.error) {
            case 'error.integration.authentication_required':
            case 'error.integration.bot.state_expired':
            case 'error.integration.bot.invalid_csrf_token':
            case 'error.integration.invalid_oauth_token':
            case 'error.integration.invalid_bot_credentials':
            case 'error.integration.missing_credentials':
            case 'error.integration.required_mfa':
            case 'error.integration.invalid_token_credentials': {
              if (props.softwareId) openReauthDialog(props.softwareId)
              break
            }
          }
        } else {
          loadUsers()
        }
      })
      .finally(() => {
        emit('licensesChanged')
      })
  }

  async function openReauthDialog(softwareId: string) {
    const software = await ApplicationStore.getApplicationById(softwareId)
    if (software === null) throw new Error('Software not found')
    softwareStore.reauthSoftwareToHandle = {
      software: software,
      callback: () => {
        updateLicenses()
      },
    }
    const store = useGlobalStore()
    store.state.showReauthenticateDialog = true
  }

  // Load User function
  function loadUsers() {
    userStore.getUser().then((response) => {
      users.value = response as Account[]
    })
  }

  const selection = computed(() => {
    return table.value?.selection
  })

  async function showEditLicence(licence: Licence_Output) {
    // Get user
    if (!licence.account_id) return
    const user = await userStore.getUserByID(licence.account_id)
    // Get software
    const software = await ApplicationStore.getApplicationById(
      licence.software_id
    )
    if (software === null) throw new Error('Software not found')
    editLicenseLicense.value = licence
    editLicenseSoftware.value = software
    editLicenseUser.value = user
    visibilityEditDialog.value = true
  }

  async function refetchSoftwareLicenses() {
    // replace with store
    priceInformations.value =
      await SoftwareLicensesService.getSoftwareLicensePriceInformationsApiV1SoftwareSoftwareLicensePriceInformationsGet(
        {}
      )

    if (props.softwareId)
      softwareLicenses.value =
        await SoftwareLicensesService.getSoftwareLicensesForSoftwareApiV1SoftwareSoftwareSoftwareIdLicensesGet(
          { softwareId: props.softwareId }
        )
    else
      softwareLicenses.value =
        await SoftwareLicensesService.getSoftwareLicensesApiV1SoftwareSoftwareLicensesGet(
          {}
        )
  }

  async function showEditGroup(licence: Licence_Output) {
    // Get user
    if (!licence.account_id) return
    const user = await userStore.getUserByID(licence.account_id)
    // Get software
    const software = await ApplicationStore.getApplicationById(
      licence.software_id
    )
    if (software === null) throw new Error('Software not found')
    editLicenseLicense.value = licence
    editLicenseSoftware.value = software
    editLicenseUser.value = user
    editLicenseGroupDialog.value = true
  }

  onMounted(async () => {
    // Load costs
    SoftwareCostService.getLicencesCostsApiV1SoftwareLicencesCostsGet({}).then(
      (response) => {
        costs.value = response
      }
    )

    refetchSoftwareLicenses()

    // Set first and last columns
    if (props.softwareId && props.subAccountId && route.query.sso !== 'true') {
      updateLicenses()
    } else {
      loadUsers()
    }
  })

  function openFilterDialog() {
    visibilityFilterDialog.value = true
  }

  // Exposes
  defineExpose({
    updateLicenses,
    selection,

    resetSelection,
    licences: data,
    openFilterDialog,
    totalLicensesCount,
    softwareLicenses,
    refetchSoftwareLicenses,

    // Counts
    filteredCount: countFilteredAccounts,
  })

  function handleLicenceEditClose() {
    visibilityEditDialog.value = false
  }

  watch(visibilityEditDialog, (newVal) => {
    if (!newVal) {
      updateLicenses()
    }
  })

  function handleLicenceChanged() {
    updateLicenses({ allowCached: false })
    emit('licensesChanged')
  }
  //#endregion
</script>

<style lang="scss" scoped>
  .delete-user {
    color: var(--el-color-danger);
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .integration_icon {
    height: 23px;
    width: 23px;
  }

  .software_name {
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 500;
  }

  .alias-select-wrapper {
    width: clamp(150px, 90%, 250px);
  }

  .guest {
    color: theme('colors.gray[400]');
    margin-left: 5px;
    font-style: italic;
    // No Line Break
    white-space: nowrap;
    word-wrap: break-word;
  }
</style>
