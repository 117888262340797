<template>
  <el-form
    :model="softwareForm"
    label-width="150px"
    label-position="top"
    require-asterisk-position="right"
    @submit.prevent="handleSubmit">
    <div data-tutorial-id="application-inputs">
      <!-- Name -->
      <el-form-item
        :label="i18n.t('name')"
        prop="display_name"
        :error="getErrorMessage('display_name')">
        <el-input
          v-model="softwareForm.fields.display_name"
          style="max-width: 313px"
          :placeholder="i18n.t('name')"
          data-test-id="software-name"
          @blur="softwareForm.validate(['display_name'])" />
      </el-form-item>
      <!-- Short Description -->
      <el-form-item
        :label="
          i18n.t('drawers.editSoftwareDrawer.form.shortDescription.label')
        "
        prop="description_short">
        <el-input
          v-model="softwareForm.fields.desc_short"
          maxlength="150"
          show-word-limit
          :placeholder="
            i18n.t(
              'drawers.editSoftwareDrawer.form.shortDescription.placeholder'
            )
          "
          data-test-id="software-short-description" />
      </el-form-item>

      <!-- Description -->
      <el-form-item
        :label="i18n.t('drawers.editSoftwareDrawer.form.description.label')"
        prop="description">
        <el-input
          v-model="softwareForm.fields.desc"
          :autosize="{ minRows: 2, maxRows: 10 }"
          type="textarea"
          :placeholder="
            i18n.t('drawers.editSoftwareDrawer.form.description.placeholder')
          "
          data-test-id="software-description" />
      </el-form-item>

      <!-- Notes -->
      <el-form-item
        :label="i18n.t('drawers.editSoftwareDrawer.form.notes.label')"
        prop="notes">
        <el-input
          v-model="softwareForm.fields.notes"
          :autosize="{ minRows: 2, maxRows: 10 }"
          type="textarea"
          :placeholder="
            i18n.t('drawers.editSoftwareDrawer.form.notes.placeholder')
          "
          data-test-id="software-note" />
      </el-form-item>
    </div>
    <div class="button-wrapper">
      <SmButton
        outline
        :loading="softwareForm.processing"
        @click="handleCancelButtonClick">
        {{ i18n.t('general.cancel') }}
      </SmButton>
      <SmButton
        type="primary"
        :loading="softwareForm.processing"
        @click="handleSaveButtonClick">
        {{ i18n.t('general.save') }}
      </SmButton>
    </div>
  </el-form>
</template>
<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import {
    PriceInformationPostIn,
    PriceInformationUpdate,
    SoftwareLicense,
    SoftwareLicensePostIn,
    SoftwareOut,
    SoftwarePostIn,
    SoftwareTypes,
  } from '@/client'
  import useForm from '@/common/form'
  import { saveNewSoftwareLicenseAndPriceInfo } from '@/common/license'

  import { getApiErrorMessage } from '@/common/util/apiError'
  import { sendToast } from './sm/SmNotification'
  import { ValidationResult, isRequired } from './sm/SmInput/SmInputValidator'
  // import { ComponentExposed } from 'vue-component-type-helpers'
  // import LicenseModelEditDialog from './LicenseModelEditDialog.vue'
  import { ApplicationStore } from '@/stores/application.store'

  const i18n = useI18n()

  // Props and Emits
  const props = defineProps({
    softwareName: {
      type: String,
      default: undefined,
    },
    softwareId: {
      type: String,
      default: undefined,
    },
    softwareType: {
      type: String as PropType<SoftwareTypes>,
      default: undefined,
    },
    softwareData: {
      type: Object as PropType<SoftwareOut>,
      default: undefined,
    },
    // used to enable/disable the licenceModelControl
    showSoftwareLicenseControl: {
      type: Boolean,
      default: false,
    },
    // used to display the right elNotification ('aktualisiert/hinzugefügt')
    isAlreadyExistingSoftware: {
      type: Boolean,
      default: true,
    },
    isNameEqualsToSearch: {
      type: Boolean,
      default: false,
    },
  })

  const showDrawer = defineModel('showDrawer', {
    type: Boolean,
    default: true,
  })

  type SoftwareLicenseToSave = {
    license: Partial<SoftwareLicense> & { _id: string }
    priceInformations: PriceInformationPostIn[] | PriceInformationUpdate[]
  }

  const softwareToSave = ref<SoftwarePostIn>({
    software_name: props.softwareName || '',
    display_name:
      props.softwareData?.display_name ||
      props.softwareData?.software_name ||
      '',
    desc_short: props.softwareData?.desc_short || '',
    desc: props.softwareData?.desc || '',
    notes: props.softwareData?.notes || '',
    type: SoftwareTypes.MANUALLY,
  })
  const softwareLicensesToSave = ref<SoftwareLicenseToSave[]>([])

  const softwareForm = useForm(
    {
      ...softwareToSave.value,
    },
    // Form Hooks
    {},
    // Form Validators
    {
      display_name: [isRequired],
    },
    {
      display_name: i18n.t('name'),
    }
  )

  function getErrorMessage(fieldName: keyof SoftwarePostIn) {
    if (!((fieldName as string) in softwareForm.errors)) return ''
    const errors = softwareForm.errors[fieldName] as ValidationResult[]
    if (!errors || errors.length === 0 || !errors[0] || !errors[0].message)
      return ''
    const message = errors[0].message as string
    return message
  }

  async function handleSubmit() {
    if (props.isAlreadyExistingSoftware) {
      await updateSoftware()
    } else {
      await createSoftware()
    }
    // Software Saved
    await ApplicationStore.updateApplicationCacheFromApi()
  }

  // if the licenceModelControl is shown, check the licenceModelEditingState
  async function handleSaveButtonClick() {
    if (softwareForm.validate()) {
      await handleSubmit()
      showDrawer.value = false
    }
  }

  const handleCancelButtonClick = async () => {
    softwareLicensesToSave.value = []
    showDrawer.value = false
  }

  /**
   * Software Related Functions
   */

  async function updateSoftware() {
    await softwareForm.submit(async (fields) => {
      if (!props.softwareId) throw new Error('Application not found')
      return await ApplicationStore.updateApplication(
        props.softwareId,
        fields
      ).then((software) => {
        softwareLicensesToSave.value.forEach((l) => {
          if (!software.data || software.error) {
            throw new Error('Error updateing Application')
          }
          saveNewSoftwareLicenseAndPriceInfo(
            software.data._id,
            l.license as SoftwareLicensePostIn,
            l.priceInformations[0] as PriceInformationPostIn
          ).catch((err) => {
            sendToast(
              getApiErrorMessage(err, i18n as ReturnType<typeof useI18n>),
              undefined,
              'error'
            )
          })
        })
      })
    })
  }

  async function createSoftware() {
    await softwareForm.submit(async (fields) => {
      const result = await ApplicationStore.createApplication({
        requestBody: {
          ...fields,
          type: SoftwareTypes.MANUALLY, // Currently only manually created software is supported
          software_name:
            (props.softwareName as string) || (fields.display_name as string),
        },
      })

      if (result.error) {
        sendToast(
          getApiErrorMessage(result.error, i18n as ReturnType<typeof useI18n>),
          undefined,
          'error'
        )
      } else {
        sendToast(
          i18n.t('notifications.editSoftwareForm.updateSuccess.title'),
          i18n.t('notifications.editSoftwareForm.updateSuccess.message', {
            softwareName: softwareForm.fields.display_name,
          }),
          'success'
        )
      }
    })
  }
</script>

<style scoped>
  .button-wrapper {
    display: flex;
    justify-content: flex-end;

    margin-top: 2rem;
  }

  .licence-model-actions {
    display: flex;
    justify-content: flex-start;
  }

  .licence-model-table {
    padding-bottom: 1rem;
  }
</style>
