/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account } from '../models/Account';
import type { AccountIn } from '../models/AccountIn';
import type { AccountUpdateIn } from '../models/AccountUpdateIn';
import type { CurrencyCode } from '../models/CurrencyCode';
import type { DeleteUserResponse } from '../models/DeleteUserResponse';
import type { LicenceOut } from '../models/LicenceOut';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersService {
    /**
     * Get Users
     * @returns Account Successful Response
     * @throws ApiError
     */
    public static getUsersApiV1ManagementUsersGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        emailOrAlias,
        email,
        alias,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        emailOrAlias?: (string | null),
        email?: (string | null),
        alias?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<Account>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/users',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'email_or_alias': emailOrAlias,
                'email': email,
                'alias': alias,
            },
            errors: {
                404: `User not found`,
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put User
     * @returns Account Successful Response
     * @throws ApiError
     */
    public static putUserApiV1ManagementUsersPut({
        requestBody,
        xTransactionId,
    }: {
        requestBody: AccountUpdateIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<Account> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/management/users',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add User
     * @returns Account Successful Response
     * @throws ApiError
     */
    public static addUserApiV1ManagementUsersPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: AccountIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<Account> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/management/users',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Users Count
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getUsersCountApiV1ManagementUsersCountGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        emailOrAlias,
        email,
        alias,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        emailOrAlias?: (string | null),
        email?: (string | null),
        alias?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/users/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'email_or_alias': emailOrAlias,
                'email': email,
                'alias': alias,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Users Cost
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getUsersCostApiV1ManagementUsersCostsGet({
        targetCurrencyCode = 'EUR',
        userCostIgnoreUnassignedQuota = false,
        xTransactionId,
    }: {
        targetCurrencyCode?: CurrencyCode,
        userCostIgnoreUnassignedQuota?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/users/costs',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'target_currency_code': targetCurrencyCode,
                'user_cost_ignore_unassigned_quota': userCostIgnoreUnassignedQuota,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User
     * @returns Account Successful Response
     * @throws ApiError
     */
    public static getUserApiV1ManagementUsersEmailOrIdGet({
        emailOrId,
        xTransactionId,
    }: {
        emailOrId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<Account> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/users/{email_or_id}',
            path: {
                'email_or_id': emailOrId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                404: `User not found`,
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete User
     * @returns DeleteUserResponse Successful Response
     * @throws ApiError
     */
    public static deleteUserApiV1ManagementUsersEmailOrIdDelete({
        emailOrId,
        xTransactionId,
    }: {
        emailOrId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<DeleteUserResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/management/users/{email_or_id}',
            path: {
                'email_or_id': emailOrId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Cost
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getUserCostApiV1ManagementUsersEmailOrIdCostGet({
        emailOrId,
        targetCurrencyCode = 'EUR',
        userCostIgnoreUnassignedQuota = false,
        xTransactionId,
    }: {
        emailOrId: string,
        targetCurrencyCode?: CurrencyCode,
        userCostIgnoreUnassignedQuota?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/users/{email_or_id}/cost',
            path: {
                'email_or_id': emailOrId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'target_currency_code': targetCurrencyCode,
                'user_cost_ignore_unassigned_quota': userCostIgnoreUnassignedQuota,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Licences
     * @returns LicenceOut Successful Response
     * @throws ApiError
     */
    public static getUserLicencesApiV1ManagementUsersEmailOrIdLicencesGet({
        emailOrId,
        xTransactionId,
    }: {
        emailOrId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<LicenceOut>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/users/{email_or_id}/licences',
            path: {
                'email_or_id': emailOrId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
