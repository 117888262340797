<template>
  <TagManageDialog
    v-model:visibility="dialogManageVisible"
    @tag-changed="loadTags" />

  <UsersDrawer
    v-if="userDetailsDrawerId"
    :key="userDetailsDrawerId"
    v-model:drawer-visibility="userDetailsDrawerVisibility"
    :user-id="userDetailsDrawerId"
    @close="userDetailsDrawerId = null" />

  <UserCreateDialog
    v-model:visible="createUserDrawerVisibility"
    :all-software-licenses
    :all-price-informations
    :all-apps="allApplications"
    :all-tags="availableTags" />

  <UsersDeleteDialog
    v-model:visibility="deleteUsersDialogVisibility"
    :user-count="usersToDelete.length"
    :users="usersToDelete"
    @refetch-users="
      () => {
        fetchUsers()
        tableRef?.resetSelection()
      }
    " />

  <UserDeleteDialog
    v-model:visibility="deleteUsersDialogVisibility"
    :user="usersToDelete[0]"
    @license-deleted="
      () => {
        fetchUsers()
        AccountStore.updateAccountCacheFromApi()
      }
    " />

  <div class="home">
    <!-- Header -->
    <ViewHeader
      :title="
        i18n.t('person', { count: 2 }) + ' (' + userHeaderCountDisplay + ')'
      " />

    <!-- Content -->
    <div class="users-grid">
      <div style="display: flex; justify-content: flex-end">
        <div
          class="table-controls-container"
          style="
            display: flex;
            flex-direction: row;
            column-gap: 10px;
            align-items: center;
            margin-bottom: 10px;
          ">
          <!-- Selected user Actions -->
          <el-dropdown
            v-if="
              tableRef?.selection?.length && tableRef?.selection?.length > 0
            "
            ref="actionDropdownRef"
            trigger="click"
            :disabled="loadUsers">
            <el-button
              :disabled="loadUsers || tableRef?.selection?.length === 0"
              :type="!loadUsers ? 'primary' : ''"
              :class="loadUsers ? 'loading-select-button' : ''">
              {{
                i18n.t('views.users.table.bulkSelectButton.text', {
                  count: tableRef?.selection?.length,
                })
              }}
            </el-button>
            <template #dropdown>
              <div class="selection-edit-dropdown-wrapper">
                <el-button
                  type="danger"
                  size="small"
                  style="margin: 0"
                  @click="
                    () => {
                      deleteUsersDialogVisibility = true
                      usersToDelete = tableRef?.selection || []
                    }
                  ">
                  {{
                    i18n.t(
                      'views.users.table.bulkSelectButton.deleteButtonText',
                      {
                        count: tableRef?.selection?.length,
                      }
                    )
                  }}
                </el-button>
              </div>
            </template>
          </el-dropdown>

          <!-- Filter displayed users -->
          <UsersFilter v-model:model-value="usersFilter" />

          <!-- Search -->
          <SmInput
            v-model="search"
            outline
            style="width: 300px"
            size="medium"
            :label="i18n.t('views.users.table.searchPlaceholder')" />

          <SmButton
            v-require-permission="CompanyAccessRole.ADMIN"
            data-tutorial-id="users-import-users-button"
            @click="createUserDrawerVisibility = true">
            {{ i18n.t('views.users.userAddButton') }}
          </SmButton>
        </div>
      </div>

      <el-card
        shadow="always"
        data-tutorial-id="users-list"
        style="overflow-y: auto"
        :body-style="{
          height: '100%',
          width: '100%',
        }">
        <!-- Users Table -->
        <SmTable
          ref="tableRef"
          :data="filteredUser"
          :columns="columns"
          :loading="loadUsers"
          key-field="_id"
          :selected="selectedUsers"
          @cell-click="handleRowClick">
          <!-- Application Count -->
          <template #applicationCount="{ row }">
            <div class="application-count">
              <span v-if="licenseMap[row._id]"
                >{{ licenseMap[row._id].size || 0 }} {{ i18n.t('apps') }}</span
              >
              <span v-else>0 {{ i18n.t('apps') }}</span>
            </div>
          </template>
          <template #action="{ row }">
            <SmDropdown trigger="click">
              <!-- Show User -->
              <SmDropdownItem icon="md-person-round" @click="selectUser(row)">
                {{ i18n.t('showUser') }}
              </SmDropdownItem>

              <!-- Edit User -->
              <SmDropdownItem icon="md-modeedit-round" @click="selectUser(row)">
                {{ i18n.t('editUser') }}
              </SmDropdownItem>

              <!-- Delete License -->
              <SmDropdownItem
                v-require-permission="CompanyAccessRole.ADMIN"
                icon="md-delete-round"
                hover-color="var(--sm-magenta)"
                @click="handleDeleteUserClick(row)">
                {{ i18n.t('deleteUser') }}
              </SmDropdownItem>
            </SmDropdown>
          </template>

          <!-- Groups -->
          <template #groups="{ row }">
            <div class="tag-wrapper">
              <TagComponent
                v-for="group in getGroupsByAccountId(row._id)"
                :key="group._id"
                :tag="{
                  name: group.name,
                  color: {
                    ...generateFallbackColor(),
                    ...group.color,
                  },
                }"
                :selected="selectedGroups.includes(group)"
                @click.capture="handleGroupsClick(group)" />

              <TagSelect
                show-create-tag
                :available-tags="availableGroups"
                :selected="getSelectedGroupsIds(row._id)"
                @delete-tag="(groupID) => removeUserFromGroup(groupID, row._id)"
                @add-tag="(groupID) => addUserToAccountGroup(groupID, row._id)"
                @create-tag="handleCreateGroup">
                <v-icon scale="0.8" name="hi-plus" class="tag-add-icon" />
              </TagSelect>
            </div>
          </template>

          <!-- Tags -->
          <template #header-tags>
            <div>
              {{ i18n.t('tags') }}
              <v-icon
                name="md-settings-round"
                scale="0.8"
                hover
                style="cursor: pointer"
                @click="dialogManageVisible = true" />
            </div>
          </template>

          <template #tags="{ row }">
            <div class="tag-wrapper">
              <TagComponent
                v-for="tag in getTagsByIds(row.tags)"
                :key="tag._id"
                :tag="tag"
                :selected="selectedTags.includes(tag)"
                class="max-w-[90%]"
                @click.capture="handleTagClick(tag)" />
              <TagSelect
                show-create-tag
                :available-tags="availableTags"
                :selected="row.tags"
                @delete-tag="removeTagFromUser($event, row.tags, row._id)"
                @add-tag="addTagToUser($event, row.tags, row._id)"
                @create-tag="dialogManageVisible = true">
                <v-icon scale="0.8" name="hi-plus" class="tag-add-icon" />
              </TagSelect>
            </div>
          </template>

          <!-- Created -->
          <template #created="{ row }">
            <span v-if="row.created">
              {{ displayDate(new Date(row.created), { dateStyle: 'short' }) }}
            </span>
            <span
              v-else
              style="font-weight: 500; color: var(--el-text-color-disabled)">
              {{ i18n.t('views.licences.table.neverLoggedIn') }}
            </span>
          </template>
        </SmTable>
      </el-card>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'
  import { generateFallbackColor } from '@/common/colors'

  import {
    AccountGroup,
    Account,
    AccountUpdateIn,
    ApiError,
    CompanyAccessRole,
    LicenceStatus,
    PriceInformation,
    SoftwareLicense,
    Tag,
    TagsService,
    UserGroupsService,
    UsersService,
    SoftwareLicensesService,
  } from '@/client'
  import { getApiErrorMessage } from '@/common/util/apiError'
  import { useSortTableColumn } from '@/common/util/sortTableColumn'
  import { UserFilterType } from '@/components/UsersFilter.vue'
  import { sendToast } from '@/components/sm/SmNotification'
  import SmTable from '@/components/sm/SmTable.vue'
  import router from '@/router'
  import { useUserStore } from '@/stores/userStore'
  import { computedAsync } from '@vueuse/core'
  import { Column } from '@/components/sm/SmTable.types'
  import { AccountStore } from '@/stores/account.store'
  import { ApplicationStore } from '@/stores/application.store'
  import { displayDate } from '@/common/util/timeUtils'

  const route = useRoute()
  const i18n = useI18n()

  const allApplications = ApplicationStore.useApplications().data
  const allSoftwareLicenses = ref<SoftwareLicense[]>([])
  const allPriceInformations = ref<PriceInformation[]>([])

  const tableRef = ref<ComponentExposed<typeof SmTable<Account>> | null>(null)
  const usersFilter = ref<UserFilterType>({
    hideUsersWithoutApp: true,
    hideUsersWithApp: false,
    includeDeactivatedLicenses: true,
    tags: [],
  })

  /**
   * Table related stuff
   */
  const loadUsers = ref(false)
  const users = ref<Account[]>([])

  // Load users
  const userStore = useUserStore()

  function fetchSoftwareLicenses() {
    SoftwareLicensesService.getSoftwareLicensesApiV1SoftwareSoftwareLicensesGet(
      {}
    ).then((res) => {
      allSoftwareLicenses.value = res
    })
    SoftwareLicensesService.getSoftwareLicensePriceInformationsApiV1SoftwareSoftwareLicensePriceInformationsGet(
      {}
    ).then((res) => {
      allPriceInformations.value = res
    })
  }

  async function fetchUsers() {
    const response = await userStore.fetchUser()
    loadUsers.value = response.loading.value

    users.value = response.data.value || []
    loadUsers.value = response.loading.value
  }

  function handleRowClick(iRow: number, columnKey: string, row: Account) {
    if (columnKey === 'selection') return
    if (columnKey === 'action') return
    if (columnKey === 'templates') return
    if (columnKey === 'tags') return
    if (columnKey === 'groups') return
    selectUser(row)
  }

  const search = ref('')
  const selectedUsers = ref<Account[]>([])

  /**
   * Tag Filter
   */
  const currentTagFilter = ref()
  currentTagFilter.value = route.query.tag_filter
    ? route.query.tag_filter
    : false

  /**
   * Table Button Functions
   */

  const selectUser = (clickedUser: Account) => {
    userDetailsDrawerId.value = clickedUser._id
    userDetailsDrawerVisibility.value = true
  }

  const usersToDelete = ref<Account[]>([])
  const handleDeleteUserClick = (user: Account) => {
    usersToDelete.value = [user]
    deleteUsersDialogVisibility.value = true
  }

  const userHeaderCountDisplay = computed(() => {
    if (filteredUser.value.length != users.value.length)
      return `${filteredUser.value.length}/${users.value.length}`
    else return `${users.value.length}`
  })

  const userFooterCount = computed(() => {
    if (filteredUser.value.length != users.value.length)
      return `${i18n.t('XofY', { x: filteredUser.value.length, y: users.value.length })}  ${i18n.t('person', { count: filteredUser.value.length })}`
    else
      return `${users.value.length} ${i18n.t('person', { count: users.value.length })}`
  })

  const filteredUser = computed(() => {
    const hideUsersWithoutApp = usersFilter.value.hideUsersWithoutApp
    const hideUsersWithApp = usersFilter.value.hideUsersWithApp
    const searchTerm = search.value.toLowerCase()

    return users.value.filter((user) => {
      const userId = user._id
      const userLicenses = licenseMap.value[userId]

      if (hideUsersWithoutApp && (!userLicenses || userLicenses.size <= 0)) {
        return false
      }

      if (hideUsersWithApp && userLicenses && userLicenses.size > 0) {
        return false
      }

      if (selectedGroups.value.length > 0) {
        const userGroups = getGroupsByAccountId(userId)
        const hasSelectedGroup = selectedGroups.value.every((group) =>
          userGroups.includes(group)
        )

        if (!hasSelectedGroup) {
          return false
        }
      }

      if (selectedTags.value.length > 0) {
        const userTags = getTagsByIds(user.tags)
        const hasSelectedTag = selectedTags.value.every((tag) =>
          userTags.includes(tag)
        )
        if (!hasSelectedTag) {
          return false
        }
      }

      const email = user.email.toString().toLowerCase()
      const name = user.name ? user.name.toLowerCase() : ''

      if (name.includes(searchTerm) || email.includes(searchTerm)) {
        return true
      }

      return false
    })
  })

  watch(
    () => (tableRef.value ? tableRef.value.selection : []),
    (selection: Account[]) => {
      selectedUsers.value = selection
    }
  )

  /**
   * Drawer Visibilities and the custom data for  drawer / dialog
   */
  const createUserDrawerVisibility = ref(false)
  const userDetailsDrawerVisibility = ref(false)
  const userDetailsDrawerId = ref<string | null>(null)
  const deleteUsersDialogVisibility = ref<boolean>(false)

  watchEffect(() => {
    if (route.query['open-add-user-drawer']) {
      createUserDrawerVisibility.value = route.query['open-add-user-drawer']
        ? route.query['open-add-user-drawer'] === 'true'
        : false
    }

    if (route.query['open-detail-drawer']) {
      userDetailsDrawerVisibility.value = route.query['open-detail-drawer']
        ? route.query['open-detail-drawer'] === 'true'
        : false
    }
    if (route.query['open-detail-drawer-email']) {
      userDetailsDrawerId.value =
        route.query['open-detail-drawer-email'].toString()
    }
  })

  // Watch the drawer visibilities to refetch the users
  watch([userDetailsDrawerVisibility, createUserDrawerVisibility], () => {
    if (
      !userDetailsDrawerVisibility.value &&
      !createUserDrawerVisibility.value
    ) {
      fetchUsers()
    }
  })

  const allLicenses = computedAsync(async () => {
    return await AccountStore.getAccounts()
  })

  // Account-Licenses Map (for application count)
  const licenseMap = ref<Record<string, Set<string>>>({})
  async function createLicenseMap() {
    const newLicenseMap: Record<string, Set<string>> = {}

    if (allLicenses.value) {
      for (const license of allLicenses.value) {
        // Filter out deactivated licenses and licenses without an account
        if (
          !license.account_id ||
          (!usersFilter.value.includeDeactivatedLicenses &&
            license.status === LicenceStatus.DISABLED)
        ) {
          continue
        }

        // Add to the map
        const accountId = license.account_id
        if (!newLicenseMap[accountId]) {
          newLicenseMap[accountId] = new Set()
        }

        newLicenseMap[accountId].add(license.software_id)
      }
    }

    licenseMap.value = newLicenseMap
  }

  // ##############################################
  // Group related stuff
  // ##############################################

  const availableGroups = ref<AccountGroup[]>([])
  const selectedGroups = ref<AccountGroup[]>([])

  function loadGroups() {
    UserGroupsService.getAccountGroupsApiV1ManagementGroupsGet({}).then(
      (groups) => {
        availableGroups.value = groups
      }
    )
  }

  function getGroupsByAccountId(accountId: string): AccountGroup[] {
    return availableGroups.value.filter((group) =>
      group.accounts.includes(accountId)
    )
  }

  function handleGroupsClick(group: AccountGroup) {
    if (selectedGroups.value.includes(group)) {
      selectedGroups.value.splice(selectedGroups.value.indexOf(group), 1)
    } else {
      selectedGroups.value.push(group)
    }
  }

  function getSelectedGroupsIds(accountId: string): string[] {
    return getGroupsByAccountId(accountId).map((group) => group._id)
  }

  async function removeUserFromGroup(groupId: string, userId: string) {
    await UserGroupsService.removeAccountFromAccountGroupApiV1ManagementGroupsAccountGroupIdAccountsAccountIdDelete(
      {
        accountGroupId: groupId,
        accountId: userId,
      }
    )
    loadGroups()
  }

  async function addUserToAccountGroup(groupId: string, userId: string) {
    await UserGroupsService.addAccountToAccountGroupApiV1ManagementGroupsAccountGroupIdAccountsAccountIdPost(
      {
        accountGroupId: groupId,
        accountId: userId,
      }
    )
    loadGroups()
  }

  function handleCreateGroup() {
    router.push({
      name: 'Groups',
      query: {
        create: 'true',
      },
    })
  }

  // ##############################################
  // Tag related stuff
  // ##############################################

  const availableTags = ref<Tag[]>([])
  const selectedTags = ref<Tag[]>([])
  const dialogManageVisible = ref(false)

  function updateUser(acc: AccountUpdateIn) {
    UsersService.putUserApiV1ManagementUsersPut({
      requestBody: acc,
    })
      .then(() => {
        fetchUsers()
      })
      .catch((err: ApiError) => {
        sendToast(
          getApiErrorMessage(err, i18n as ReturnType<typeof useI18n>),
          undefined,
          'error'
        )
      })
  }

  function removeTagFromUser(
    tagId: string,
    tagsBefore: string[],
    userId: string
  ) {
    const updateTags = tagsBefore.filter((existing) => existing != tagId)
    const acc: AccountUpdateIn = { tags: updateTags, _id: userId }
    updateUser(acc)
  }

  function addTagToUser(tagId: string, tagsBefore: string[], userId: string) {
    const updateTags = new Array(...tagsBefore)
    updateTags.push(tagId)
    const acc: AccountUpdateIn = { tags: updateTags, _id: userId }
    updateUser(acc)
  }

  function handleTagClick(tag: Tag) {
    if (selectedTags.value.includes(tag)) {
      selectedTags.value.splice(selectedTags.value.indexOf(tag), 1)
    } else {
      selectedTags.value.push(tag)
    }
  }

  function getTagsByIds(ids: string[]): Tag[] {
    return availableTags.value.filter((tag) => ids.includes(tag._id))
  }

  function loadTags() {
    TagsService.getTagsApiV1ManagementTagsGet({}).then((tags) => {
      availableTags.value = tags
    })
  }

  const columns = computed<Column<Account>[]>(() => [
    {
      key: 'name',
      label: i18n.t('views.users.table.name'),
      sortable: true,
      sortFn: (a: Account, b: Account) =>
        useSortTableColumn(a.name, b.name, 'desc'),
      width: 0.9,
      footer: userFooterCount,
    },
    {
      key: 'email',
      label: i18n.t('views.users.table.email'),
      sortable: true,
      sortFn: (a: Account, b: Account) =>
        useSortTableColumn(a.email, b.email, 'asc'),
      width: 1,
    },
    {
      key: 'groups',
      label: i18n.t('groups'),
      sortable: false,
      width: 0.8,
    },
    {
      key: 'tags',
      label: i18n.t('tags'),
      sortable: false,
      width: 0.8,
    },
    {
      key: 'applicationCount',
      label: i18n.t('apps'),
      sortable: true,
      width: '100px',
    },
    {
      key: 'action',
      label: '',
      width: '50px',
      sortable: false,
    },
  ])

  onMounted(() => {
    loadTags()
    fetchSoftwareLicenses()
  })

  onMounted(() => {
    fetchUsers()
    createLicenseMap()
    loadGroups()
  })

  watch(
    () => usersFilter.value.includeDeactivatedLicenses,
    () => {
      createLicenseMap()
    }
  )

  watch(
    () => allLicenses.value,
    () => {
      createLicenseMap()
    }
  )
</script>
<style scoped>
  .users-grid {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  .tag-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    padding: 0.5rem 0 0.5rem 0;
    align-items: center;
    width: 100%;
  }

  .welcome-text {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .el-scrollbar__view {
    width: 100%;
  }

  .selection-edit-dropdown-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px 10px;
  }

  .loading-select-button {
    color: var(--el-disabled-text-color);
  }

  .loading-select-button :hover {
    color: var(--el-disabled-text-color);
  }

  .template-wrapper {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    max-height: 45px;
    overflow: auto;
  }

  .tag {
    cursor: pointer;
  }

  .delete-user {
    color: var(--el-color-danger);
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .application-count {
    width: 100%;
    text-align: left;
  }

  .tag-add-icon {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .tag-wrapper:hover .tag-add-icon {
    opacity: 1;
  }
</style>
