<template>
  <div class="rounded bg-gray-100 p-4 dark:bg-gray-800">
    <div class="flex items-center justify-between gap-2">
      <h3>{{ props.license.name }}</h3>
      <p v-if="props.license.subtitle" class="font-bold">
        {{ props.license.subtitle }}
      </p>
      <v-icon
        name="md-modeedit-round"
        scale="1"
        class="hover:cursor-pointer hover:text-gray-900 hover:dark:text-gray-400"
        @click="emit('edit', props.license)" />
    </div>
    <p v-if="license?.notes" class="mt-2">{{ props.license.notes }}</p>

    <!-- Managed / Automated Infos -->
    <div class="mb-2 mt-4 flex items-center gap-2">
      <v-icon
        name="md-bolt-round"
        scale="1.1"
        class="mr-2"
        :class="
          props.license.external_id !== null
            ? 'fill-primary'
            : 'fill-contrast-muted'
        " />
      <p v-if="props.license.external_id !== null">
        {{ i18n.t('automatedLicenseModel') }}
      </p>
      <p v-else>{{ i18n.t('manualLicenseModel') }}</p>
    </div>

    <!-- Auto Assign Infos -->
    <div
      v-if="props.license.auto_assign.add_to_new_user"
      class="mb-2 flex items-center gap-2">
      <v-icon
        name="md-check-round"
        scale="1.1"
        class="mr-2 fill-contrast-muted" />
      <p>{{ i18n.t('assignToNewUsers') }}</p>
    </div>

    <div
      v-if="props.license.auto_assign.add_to_new_guest"
      class="mb-2 flex items-center gap-2">
      <v-icon
        name="md-check-round"
        scale="1.1"
        class="mr-2 fill-contrast-muted" />
      <p>{{ i18n.t('assignToNewGuests') }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { SoftwareLicense } from '@/client'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  const props = defineProps({
    license: {
      type: Object as PropType<SoftwareLicense>,
      required: true,
    },
  })

  const emit = defineEmits(['edit'])
</script>
