import {
  Licence_Input,
  LicenceOut,
  LicenceUpdateIn,
  UserLicencesService,
} from '@/client'
import {
  deserializeLicenceOut,
  LicenceOutTable,
  serializeLicenceOut,
} from './account.models'
import { db } from './database'
import {
  _disableAccount,
  _enableAccount,
  _getAccounts,
  _postLicense,
  _patchLicense,
  _deleteLicense,
} from './account.requests'
import { createUpdateSet, deepUnwrap } from './util'
import { CacheStateRepository, CacheStateTable } from './cacheState.store'
import dayjs from 'dayjs'
import { type AccountFilters, applyAccountFilters } from './account.filter'

const lastCacheChange = ref<Date>()
const loadingFullReload = ref<boolean>(false)

type Pagination = {
  limit?: number
  offset?: number
}

type Sort = {
  column?: keyof LicenceOutTable
  direction?: 'asc' | 'desc'
}

export const AccountStore = {
  loadingFullReload,

  async bulkInsertLicenceOuts(
    licences: LicenceOut[],
    batchSize: number = 1000
  ) {
    const serializedLicences = licences.map(serializeLicenceOut)

    await db.transaction().execute(async (trx) => {
      for (let i = 0; i < serializedLicences.length; i += batchSize) {
        const batch = serializedLicences.slice(i, i + batchSize)
        await trx
          .insertInto('licence_out')
          .values(batch)
          .onConflict((oc) =>
            oc.column('_id').doUpdateSet(createUpdateSet(serializedLicences[0]))
          )
          .execute()
      }
    })
    if (licences) {
      lastCacheChange.value = new Date()
    }
    return licences
  },

  async getAllLicenceOuts(): Promise<LicenceOut[]> {
    const records = await db.selectFrom('licence_out').selectAll().execute()
    return records.map(deserializeLicenceOut)
  },

  async getAccountsCount(
    filters: AccountFilters = { isDeleted: false }
  ): Promise<number> {
    const unwrappedFilters = deepUnwrap(filters)

    let query = db
      .selectFrom('licence_out')
      .select(db.fn.count('_id').as('count'))
    query = applyAccountFilters(query, unwrappedFilters)

    const result = await query.executeTakeFirst()
    return result ? Number(result.count) : 0
  },

  async deleteAllLicenceOuts(): Promise<void> {
    await db.deleteFrom('licence_out').execute()
    lastCacheChange.value = new Date()
  },

  getDatabase() {
    return db.selectFrom('licence_out')
  },

  async refreshAccountCacheFromApi(
    filter: AccountFilters = {},
    useServerCache: boolean = true,
    includeDeleted: boolean = false
  ) {
    const results = await _getAccounts({
      softwareId: filter.softwareId,
      subAccountId: filter.subAccountId,
      status: filter.status ? filter.status[0] : undefined, // WARNING: This is not what we want
      updatedGte: filter.updatedAfter,
      updatedLt: filter.updatedBefore,
      deletedAtGte: filter.deletedAfter,
      cached: useServerCache,
      includeDeleted: includeDeleted,
    })

    if (!results.error && results.data) {
      await this.bulkInsertLicenceOuts(results.data)
    }
    return results
  },

  async updateAccountCacheFromApi(fullReload: boolean = false) {
    let isFullReload = true

    const lastRefresh = await CacheStateRepository.getCacheStateById('accounts')

    const filter: AccountFilters = {}
    if (lastRefresh && lastRefresh.lastUpdate && !fullReload) {
      filter.updatedAfter = lastRefresh.lastUpdate
      isFullReload = false
    }

    loadingFullReload.value = isFullReload

    // Update updated licenses
    await this.refreshAccountCacheFromApi(filter)

    // Update deleted licenses
    if (lastRefresh && lastRefresh.lastUpdate && !fullReload) {
      await this.refreshAccountCacheFromApi(
        {
          deletedAfter: lastRefresh?.lastUpdate,
        },
        true, // use serverSide Cache
        true // includeDeleted
      )
    }

    type UpdateCacheStateTable = Partial<Omit<CacheStateTable, 'id'>> & {
      id: string
    }
    const update: UpdateCacheStateTable = {
      id: 'accounts',
      lastUpdate: dayjs().toISOString(),
    }

    if (isFullReload) {
      update.lastCompleteLoad = dayjs().toISOString()
    }

    await CacheStateRepository.upsertCacheState(update)
    loadingFullReload.value = false

    lastCacheChange.value = new Date()
  },

  async getAccounts(
    filters: AccountFilters = { isDeleted: false },
    pagination: Pagination = {},
    sort: Sort = {}
  ): Promise<LicenceOut[]> {
    let query = db.selectFrom('licence_out').selectAll()

    query = applyAccountFilters(query, filters)

    if (pagination.limit) {
      query = query.limit(pagination.limit)
    }
    if (pagination.offset) {
      query = query.offset(pagination.offset)
    }
    if (sort.column) {
      query = query.orderBy(sort.column, sort.direction ?? 'asc')
    }

    const results = await query.execute()
    return results.map(deserializeLicenceOut)
  },

  async createAccount(newAccount: Licence_Input) {
    return _postLicense(newAccount).then(async (response) => {
      if (!response.error && response.data) {
        const serializedLicence = serializeLicenceOut(response.data)
        await db.insertInto('licence_out').values(serializedLicence).execute()
      }
      return response
    })
  },

  async deactivateAccount(id: string) {
    return _disableAccount(id).then(async (response) => {
      if (!response.error && response.data) {
        await this.bulkInsertLicenceOuts([response.data])
      }
      return response
    })
  },

  async activateAccount(id: string) {
    return _enableAccount(id).then(async (response) => {
      if (!response.error && response.data) {
        await this.bulkInsertLicenceOuts([response.data])
      }
      return response
    })
  },

  async getAccountById(id: string): Promise<LicenceOut | null> {
    const record = await db
      .selectFrom('licence_out')
      .selectAll()
      .where('licence_out._id', '=', id)
      .executeTakeFirst()

    let result = record ? deserializeLicenceOut(record) : record

    if (!record) {
      await UserLicencesService.getLicenceApiV1SoftwareLicencesLicenceIdGet({
        includeDeleted: true,
        licenceId: id,
      }).then((response) => {
        if (response) {
          result = response
          this.bulkInsertLicenceOuts([response])
        }
      })
    }

    return result || null
  },

  async updateAccount(id: string, updates: LicenceUpdateIn) {
    return await _patchLicense(id, updates).then(async (response) => {
      if (response.error) return response

      const serializedUpdates = serializeLicenceOut({
        ...response.data,
        _id: id,
      } as LicenceOut)

      await db
        .updateTable('licence_out')
        .set(serializedUpdates)
        .where('licence_out._id', '=', id)
        .execute()

      lastCacheChange.value = new Date()
      return response
    })
  },

  async deleteAccount(id: string) {
    return _deleteLicense(id).then(async (response) => {
      await db
        .deleteFrom('licence_out')
        .where('licence_out._id', '=', id)
        .execute()
      lastCacheChange.value = new Date()
      return response
    })
  },
  useAccountsCount(initialFilters: AccountFilters | Ref<AccountFilters> = {}) {
    const count = ref<number>(0)

    const fetchCount = async () => {
      const filters = deepUnwrap(initialFilters) as AccountFilters
      count.value = await this.getAccountsCount(filters)
    }

    const stopWatch = watch(
      [() => unref(initialFilters), lastCacheChange],
      async () => {
        await fetchCount()
      },
      { deep: true }
    )

    fetchCount()

    return {
      count,
      unsubscribe: stopWatch,
      lastCacheUpdate: lastCacheChange,
    }
  },

  useAccounts(
    initialFilters:
      | AccountFilters
      | Ref<AccountFilters>
      | ComputedRef<AccountFilters> = {},
    initialPagination:
      | Pagination
      | Ref<Pagination>
      | ComputedRef<Pagination> = {},
    initialSort: Sort | Ref<Sort> | ComputedRef<Sort> = {}
  ) {
    const data = ref<LicenceOut[]>([])

    const fetchData = async () => {
      const filters = deepUnwrap(initialFilters) as AccountFilters
      const pagination = deepUnwrap(initialPagination) as Pagination
      const sort = deepUnwrap(initialSort) as Sort
      data.value = await this.getAccounts(filters, pagination, sort)
    }

    const stopWatch = watch(
      [
        () => unref(initialFilters),
        () => unref(initialPagination),
        () => unref(initialSort),
        lastCacheChange,
      ],
      async () => {
        await fetchData()
      },
      { deep: true }
    )

    const updateParameters = (
      newFilters: Partial<AccountFilters> = {},
      newPagination: Partial<Pagination> = {},
      newSort: Partial<Sort> = {}
    ) => {
      const cloneAndMerge = <T extends object>(
        source: T | Ref<T>,
        updates: Partial<T>
      ): T => {
        if (isRef(source)) {
          return { ...source.value, ...updates }
        } else {
          return { ...source, ...updates }
        }
      }

      initialFilters = cloneAndMerge(initialFilters, newFilters)
      initialPagination = cloneAndMerge(initialPagination, newPagination)
      initialSort = cloneAndMerge(initialSort, newSort)

      fetchData()
    }

    fetchData()

    return {
      data,
      unsubscribe: stopWatch,
      updateParameters,
      lastCacheUpdate: lastCacheChange,
    }
  },
}
