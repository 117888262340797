<template>
  <ActivityDetailDialog
    v-model:visible="detailDialogVisible"
    :all-user="allUser"
    :activity="detailedActivity!"
    :all-software-licenses="allSoftwareLicenses"
    :all-price-informations="allPriceInformations"
    :user-profiles="userProfiles" />

  <div v-loading="loading">
    <div
      v-for="(_activities, date) in dateSortedActivities"
      :key="date"
      class="day-activity">
      <h3>{{ translateDate(date) }}</h3>
      <el-timeline class="timeline">
        <el-timeline-item
          v-for="(activity, index) in _activities"
          :key="index"
          class="activity-item"
          :timestamp="dateToString(activity.created)"
          :color="index == 0 ? 'var(--sm-primary)' : undefined"
          placement="top"
          @click="handleActivityClick(activity)">
          <span>
            {{ activityMessages[activity._id] }}
          </span>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>
<script setup lang="ts">
  import dayjs from 'dayjs'

  import utc from 'dayjs/plugin/utc'
  import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
  import localizedFormat from 'dayjs/plugin/localizedFormat'

  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(localizedFormat)

  import { useI18n } from 'vue-i18n'

  import {
    Account,
    ActivityBaseModel,
    PriceInformation,
    SoftwareLicense,
    SoftwareLicensesService,
    UserProfilePublic,
    UsersService,
  } from '@/client'
  import { getI18nArgs } from '@/common/util/activityUtil'

  import ActivityDetailDialog from './ActivityDetailDialog.vue'
  import { displayDate } from '@/common/util/timeUtils'

  type SortedActivites = Record<string, ActivityBaseModel[]>

  const { t } = useI18n()
  const props = defineProps<{
    activities: ActivityBaseModel[]
    userProfiles: Record<string, UserProfilePublic>
  }>()
  const allUser = ref<Account[]>([])
  const allSoftwareLicenses = ref<SoftwareLicense[]>([])
  const allPriceInformations = ref<PriceInformation[]>([])
  const detailedActivity = ref<ActivityBaseModel | null>(null)
  const detailDialogVisible = ref(false)
  const loading = ref(true)

  const dateSortedActivities = computed(() => {
    const structuredActivities: SortedActivites = {}

    // structuring the activities by date-keys
    props.activities.forEach((a) => {
      if (!a.created) return
      const activityDate = new Date(a.created)
      const formattedDate = dayjs(activityDate).format('DD.MM.YYYY')
      if (structuredActivities[formattedDate])
        structuredActivities[formattedDate].push(a)
      else structuredActivities[formattedDate] = [a]
    })
    return structuredActivities
  })

  function dateToString(date: string): string {
    const dateObject = new Date(date)
    return displayDate(dateObject, { timeStyle: 'short' })
  }

  function translateDate(date: string): string {
    const dateString = `${date.split('.')[2]}-${date.split('.')[1]}-${
      date.split('.')[0]
    }`

    // const dateObject = dayjs(dateString).tz(tz)
    const dateObject = new Date(dateString)

    if (dayjs(dateObject).isSame(dayjs(), 'day'))
      return t('views.dashboard.lastActivity.today')

    if (dayjs(dateObject).isSame(dayjs().subtract(1, 'day'), 'day')) {
      return t('views.dashboard.lastActivity.yesterday')
    }

    return displayDate(dateObject, { dateStyle: 'short' })
  }

  function handleActivityClick(activity: ActivityBaseModel) {
    detailedActivity.value = activity
    detailDialogVisible.value = true
  }

  // Fetching all needed resources ({includeDeleted: true} is necessary and not yet implemented in stores, to display emails of 'deleted' licenses, etc.)
  // Applying loading effect until all requests are finished
  onMounted(() => {
    const p: Promise<void | object>[] = []
    p.push(
      UsersService.getUsersApiV1ManagementUsersGet({
        includeDeleted: true,
      }).then((u) => {
        if (u) allUser.value = u
      })
    )
    p.push(
      SoftwareLicensesService.getSoftwareLicensesApiV1SoftwareSoftwareLicensesGet(
        {
          includeDeleted: true,
        }
      ).then((s) => {
        if (s) allSoftwareLicenses.value = s
      })
    )
    p.push(
      SoftwareLicensesService.getSoftwareLicensePriceInformationsApiV1SoftwareSoftwareLicensePriceInformationsGet(
        { includeDeleted: true }
      ).then((p) => {
        if (p) allPriceInformations.value = p
      })
    )
    Promise.all(p).finally(() => {
      loading.value = false
    })
  })

  // Load all activity i18n strings asyncronously
  const activityMessages: Ref<Record<string, string>> = ref({})

  async function getI18nString(activity: ActivityBaseModel) {
    const args = await getI18nArgs(
      activity,
      allUser.value,
      allSoftwareLicenses.value,
      allPriceInformations.value,
      props.userProfiles[activity.identity_id]
    )

    return t(`activity.${activity.action}`, {
      ...args,
    })
  }

  async function mapActivityMessages() {
    loading.value = true
    const translations: Record<string, string> = {}

    for (const activity of props.activities) {
      translations[activity._id] = await getI18nString(activity)
    }

    activityMessages.value = translations
    loading.value = false
  }

  watch(() => props.activities, mapActivityMessages)
</script>

<style scoped>
  .day-activity {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    margin-left: 5px;
  }

  .timeline {
    padding-left: 0;
  }

  .activity-item {
    padding-top: 8px;
    font-size: 0.9rem;
    cursor: pointer;
  }
</style>
