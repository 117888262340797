import schema from '@/stores/task.schema'

import { useFetch } from '@/common/useFetch'
import { filters } from './task.filter'
import { db } from './_db'
import { MangoQuerySortPart } from 'rxdb'
import filterUtils from '@/common/util/filterUtils'
import { until } from '@vueuse/shared'
import { Task, TaskPostIn, TasksService, TaskUpdatePatchIn } from '@/client'
import { useI18n } from 'vue-i18n'
import { Status } from '@/components/sm/SmStatus.vue'

/**
 * Variables
 */
export type TaskFilterType = (typeof filters)[keyof typeof filters]
export type TaskFilterInstance = InstanceType<TaskFilterType>

export const _currentLoadingAllTasks: Ref<boolean> = ref(false)
export const _allTasksLoaded: Ref<Date> | Ref<undefined> = ref(undefined)
export const collectionInitiated = ref(false)

/**
 * Initialize
 */

export async function _initTaskCollection() {
  // check if db is initialized
  if (!db.value) {
    return
  }

  // Check if collection is already existing in DB
  const collections = db.value?.collections
  if (collections.tasks) {
    collectionInitiated.value = true
    return
  }

  await db.value
    .addCollections({
      tasks: {
        schema: schema,
      },
    })
    .catch((error) => {
      console.error(error)
    })

  collectionInitiated.value = true
}

export async function _checkTaskCollection() {
  if (!collectionInitiated.value) {
    await _initTaskCollection()
  }
}

/**
 * RXDB Helper
 */

export async function _getQuery(
  filters?: TaskFilterInstance[],
  {
    limit,
    skip,
    sort,
  }: {
    limit?: number
    skip?: number
    sort?: MangoQuerySortPart<Task>[]
  } = {}
) {
  // For now, we just take the first filter
  const mergedFilter = await filterUtils.mergeFilters(filters || [])

  if (_currentLoadingAllTasks.value === true) {
    await until(_currentLoadingAllTasks).toBe(false)
  }

  if (!_allTasksLoaded.value) {
    await _reloadTasks()
  }

  return db.value?.tasks.find({
    selector: mergedFilter,
    limit,
    skip,
    sort,
  })
}

export async function _reloadTasks(filter?: TaskFilterInstance[]) {
  if (filter === undefined) {
    _currentLoadingAllTasks.value = true
  }
  const { data, error, loading } = await _fetchTasks(filter)
  if (data) {
    toRaw(data)

    await db.value?.tasks.bulkUpsert(toRaw(data))

    _currentLoadingAllTasks.value = false
    _allTasksLoaded.value = new Date()
  }
  return { data, error, loading }
}

/**
 * Filter Helper
 */

export type TaskStatus = 'backlog' | 'upcoming' | 'progress' | 'review' | 'done'
export const taskStatus: TaskStatus[] = [
  'backlog',
  'upcoming',
  'progress',
  'review',
  'done',
]

export function getTaskStatus(status: TaskStatus): Status {
  const i18n = useI18n()
  switch (status) {
    case 'backlog':
      return {
        text: i18n.t('taskStatus.backlog'),
        color: 'bg-contrast-muted',
      }
    case 'upcoming':
      return { text: i18n.t('taskStatus.upcoming'), color: 'bg-blue' }
    case 'progress':
      return { text: i18n.t('taskStatus.progress'), color: 'bg-purple' }
    case 'review':
      return {
        text: i18n.t('taskStatus.review'),
        color: 'bg-orange',
      }
    case 'done':
      return { text: i18n.t('taskStatus.done'), color: 'bg-primary' }
  }
}

/**
 * API
 */

export async function _fetchTask(taskId: string) {
  const { data, error, fetchData, loading } = useFetch(
    () => TasksService.getTaskApiV1TasksTaskIdGet({ taskId }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _fetchTasks(filter?: TaskFilterInstance[]) {
  const _filter = {
    filters: filter || [],
    includeDeleted: false,
  }

  const { data, error, fetchData, loading } = useFetch(
    () => TasksService.getTasksApiV1TasksGet(_filter),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _createTask(task: TaskPostIn) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      TasksService.createTaskApiV1TasksPost({
        requestBody: task,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _updateTask(id: string, task: TaskUpdatePatchIn) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      TasksService.patchTaskApiV1TasksTaskIdPatch({
        taskId: id,
        requestBody: task,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _deleteTask(id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      TasksService.deleteTaskApiV1TasksTaskIdDelete({
        taskId: id,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}
