<template>
  <SmDialog
    v-model:visibility="visible"
    :title="i18n.t('scheduleJob')"
    class="dialog"
    size="medium">
    <SmDialogMessage
      :message="submitResponseMsg"
      :visible="showSubmitResponse"
      :type="submitResponseType"
      class="mb-4" />
    <el-form
      label-width="150px"
      label-position="top"
      require-asterisk-position="right">
      <div class="flex gap-4">
        <!-- Date -->
        <el-form-item class="w-1/2">
          <template #label>
            <div class="flex gap-2">
              {{ i18n.t('jobExecutionInput.label') }}
              <SmTooltip>
                <template #content>
                  {{ i18n.t('jobExecutionInput.tooltip') }}
                </template>
                <v-icon
                  name="md-help-round"
                  scale="0.9"
                  class="fill-contrast-muted" />
              </SmTooltip>
            </div>
          </template>
          <SmDatepicker v-model:date="scheduleDate" class="w-full" />
        </el-form-item>

        <el-form-item :label="i18n.t('time')" class="w-1/2">
          <SmTimeInput
            v-model:time="scheduleTime"
            :disabled="!scheduleDate"
            :placeholder="
              !scheduleDate ? i18n.t('pleaseSelectDate') : undefined
            "
            class="w-full" />
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <div class="submit-buttons">
        <SmButton size="small" outline @click="handleCancel">
          {{ i18n.t('general.cancel') }}
        </SmButton>

        <SmButton
          v-if="!actionsDone"
          size="small"
          :loading="submitLoading"
          @click="handleSave">
          {{ i18n.t('general.save') }}
        </SmButton>
        <SmButton
          v-if="actionsDone"
          size="small"
          :loading="submitLoading"
          @click="handleSave">
          {{ i18n.t('close') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { backgroundjobStore } from '@/stores/backgroundjob.store'
  import dayjs from 'dayjs'
  import { useI18n } from 'vue-i18n'
  import { DialogMessageTypes } from './sm/SmDialogMessage.vue'
  import {
    BackgroundJobAccountGroupAccountsAdded,
    BackgroundJobAccountGroupAccountsRemoved,
    BackgroundJobSoftwareUserAdd,
    BackgroundJobSoftwareUserEnable,
    BackgroundJobSoftwareUserLicenseAdd,
    BackgroundJobSoftwareUserLicenseRemove,
    BackgroundJobSoftwareUserRemove,
  } from '@/client'

  const i18n = useI18n()

  const props = defineProps<{
    visible: boolean
    jobId: string
  }>()

  const emit = defineEmits<{
    'update:visible': [visible: boolean]
    scheduled: [
      res:
        | BackgroundJobAccountGroupAccountsAdded
        | BackgroundJobAccountGroupAccountsRemoved
        | BackgroundJobSoftwareUserAdd
        | BackgroundJobSoftwareUserEnable
        | BackgroundJobSoftwareUserLicenseAdd
        | BackgroundJobSoftwareUserLicenseRemove
        | BackgroundJobSoftwareUserRemove,
    ]
  }>()

  const visible = computed({
    get: () => props.visible,
    set: (val) => emit('update:visible', val),
  })

  const fallBackTime = '0600'
  const tomorrowDate = dayjs().add(1, 'day').toDate()
  const scheduleDate = ref(tomorrowDate)
  const scheduleTime = ref(fallBackTime)
  const scheduleIn = ref<Date | null>(tomorrowDate)
  const submitLoading = ref(false)
  const showSubmitResponse = ref(false)
  const submitResponseMsg = ref('')
  const submitResponseType = ref<DialogMessageTypes>('success')
  const actionsDone = ref(false)

  const selectedDateTime = computed(() => {
    if (!scheduleDate) return null

    const date = dayjs(scheduleDate.value)
    const time = scheduleTime || fallBackTime

    const dateTime = date
      .hour(parseInt(time.value.slice(0, 2)))
      .minute(parseInt(time.value.slice(2, 4)))

    return dateTime.toDate()
  })

  watch(
    selectedDateTime,
    (val) => {
      scheduleIn.value = val
    },
    { immediate: true }
  )

  async function handleSave() {
    let scheduleDateInSeconds

    if (actionsDone.value) {
      handleCancel()
      return
    }

    showSubmitResponse.value = false
    submitLoading.value = true

    // convert dateTime to seconds
    if (scheduleIn.value != null) {
      scheduleDateInSeconds = dayjs(selectedDateTime.value).diff(dayjs(), 's')
    } else {
      scheduleDateInSeconds = null
    }

    backgroundjobStore
      .enqueueBackgroundjob(props.jobId, scheduleDateInSeconds as number)
      .then((res) => {
        submitResponseMsg.value = i18n.t('scheduledJobSuccess')
        showSubmitResponse.value = true
        submitLoading.value = false
        actionsDone.value = true

        emit('scheduled', res.data)
      })
      .catch(() => {
        submitResponseMsg.value = i18n.t('scheduledJobFailed')
        submitResponseType.value = 'error'
        showSubmitResponse.value = true
        submitLoading.value = false
      })
  }

  function handleCancel() {
    visible.value = false
    showSubmitResponse.value = false
  }

  watch(
    visible,
    (value: boolean) => {
      if (!value) {
        scheduleDate.value = tomorrowDate
        scheduleTime.value = fallBackTime
        showSubmitResponse.value = false
        actionsDone.value = false
      }
    },
    { immediate: true }
  )
</script>

<style scoped>
  .submit-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
</style>
