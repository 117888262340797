import { defineStore } from 'pinia'

import { SoftwareOut, SoftwareTypes } from '@/client'

export enum AuthType {
  AUTHENTICATE = 'authenticate',
  REAUTHENTICATE = 'reauthenticate',
}

export type SoftwareReauthenticateInfo = {
  software: SoftwareOut
  callback: () => any
}

export type Software2FaInfo = {
  softwareName: string
  softwareId: string
  softwareType: SoftwareTypes
  authType: AuthType
  callback: () => any
}

export const useApplicationReauthStore = defineStore('softwareStore', () => {
  const twoFactorAuthToHandle = ref<Software2FaInfo>()
  const reauthSoftwareToHandle = ref<SoftwareReauthenticateInfo>()

  function setReauthSoftwareToHandle(information: SoftwareReauthenticateInfo) {
    reauthSoftwareToHandle.value = information
  }

  function clearReauthSoftwareToHandle() {
    if (reauthSoftwareToHandle.value) {
      reauthSoftwareToHandle.value.callback()
      delete reauthSoftwareToHandle.value
    }
  }

  function set2FaSoftwareToHandle(information: Software2FaInfo) {
    twoFactorAuthToHandle.value = information
  }

  function clear2FaSoftwareToHandle() {
    if (twoFactorAuthToHandle.value) twoFactorAuthToHandle.value.callback()
    delete twoFactorAuthToHandle.value
  }

  return {
    twoFactorAuthToHandle,
    reauthSoftwareToHandle,
    setReauthSoftwareToHandle,
    clearReauthSoftwareToHandle,
    set2FaSoftwareToHandle,
    clear2FaSoftwareToHandle,
  }
})
