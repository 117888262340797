import { Licence_Input, LicenceUpdateIn, UserLicencesService } from '@/client'
import { useFetch } from '@/common/useFetch'

export async function _disableAccount(id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UserLicencesService.disableLicenceApiV1SoftwareLicencesLicenceIdDisablePost(
        {
          licenceId: id,
        }
      ),
    null,
    { sendToast: true }
  )

  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _enableAccount(id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UserLicencesService.enableLicenceApiV1SoftwareLicencesLicenceIdEnablePost(
        {
          licenceId: id,
        }
      ),
    null,
    { sendToast: true }
  )

  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _deleteLicense(id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UserLicencesService.deleteApiV1SoftwareLicencesLicenceIdDelete({
        licenceId: id,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _postLicense(license: Licence_Input) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UserLicencesService.postLicenceApiV1SoftwareLicencesPost({
        requestBody: license,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _patchLicense(id: string, license: LicenceUpdateIn) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UserLicencesService.patchLicenceApiV1SoftwareLicencesLicenceIdPatch({
        licenceId: id,
        requestBody: license,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _getAccounts(
  args: Parameters<
    typeof UserLicencesService.getLicencesApiV1SoftwareLicencesGet
  >[0] // Correctly type the argument
) {
  const { data, error, fetchData, loading } = useFetch(
    () => UserLicencesService.getLicencesApiV1SoftwareLicencesGet(args),
    null,
    { sendToast: false }
  )

  await fetchData()

  return { data: data.value, error: error.value, loading: loading.value }
}
