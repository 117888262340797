<template>
  <div class="wrapper">
    <span class="sm-circle-progress-border"></span>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    size: {
      type: String,
      default: 'small',
    },
    total: {
      type: Number,
      default: 100,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
      required: true,
    },
    progressColor: {
      type: String,
      default: '#e53b3b', // Default progress color
    },
  })

  const size = computed(() => {
    switch (props.size) {
      case 'xsmall':
        return '16px'
      case 'small':
        return '22px'
      case 'medium':
        return '30px'
      case 'large':
        return '40px'
    }
  })

  const degree = computed(() => {
    return `${(props.value / props.total) * 360}deg`
  })

  const progressColor = computed(() => props.progressColor)
</script>

<style lang="scss">
  $size: v-bind(size);
  $degree: v-bind(degree);
  $progressColor: v-bind(progressColor);

  .wrapper {
    width: $size;
    height: $size;
    aspect-ratio: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sm-circle-progress-border {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: conic-gradient(
      var(--sm-primary) $degree,
      theme('colors.gray[200]') 0deg
    );
    mask: radial-gradient(circle, transparent 50%, black 52%);
  }

  .dark .sm-circle-progress-border {
    background: conic-gradient(
      var(--sm-primary) $degree,
      theme('colors.gray[600]') 0deg
    );
  }
</style>
