import { TaskStatus } from './task.utils'

export interface TaskFilter {
  getFilter(): Record<string, unknown>
  name: string
}

export class StatusFilter implements TaskFilter {
  name = 'StatusFilter'
  status: TaskStatus[] | null // Null means all status

  constructor(status: TaskStatus[] | null) {
    this.status = status
  }

  getFilter() {
    if (this.status === null) {
      return {}
    }

    if (this.status.length === 0) {
      return {}
    }

    return {
      status: {
        $in: this.status,
      },
    }
  }
}

export class IdFilter implements TaskFilter {
  name = 'IdFilter'
  ids: string[]

  constructor(ids: string[]) {
    this.ids = ids
  }

  getFilter() {
    return {
      _id: {
        $in: this.ids,
      },
    }
  }
}

export const filters = {
  StatusFilter,
  IdFilter,
}
