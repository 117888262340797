import {
  SoftwareAuthorizationService,
  SoftwareMiniOut,
  SoftwareOut,
  SoftwareService,
} from '@/client'
import { useFetch } from '@/common/useFetch'

export async function _getApplications(
  args: Parameters<
    typeof SoftwareService.getSoftwareListApiV1SoftwareSoftwareGet
  >[0] // Correctly type the argument
): Promise<{
  data: SoftwareOut[] | SoftwareMiniOut[]
  error: ReturnType<typeof useFetch>['error']['value']
  loading: boolean
}> {
  const { data, error, fetchData, loading } = useFetch(
    () => SoftwareService.getSoftwareListApiV1SoftwareSoftwareGet(args),
    null,
    { sendToast: false }
  )

  await fetchData()

  // Refine the return type based on `args.extended`
  if (args.extended === false) {
    return {
      data: data.value as SoftwareMiniOut[],
      error: error.value,
      loading: loading.value,
    }
  }

  return {
    data: data.value as SoftwareOut[],
    error: error.value,
    loading: loading.value,
  }
}

export async function _deleteApplication(
  args: Parameters<
    typeof SoftwareService.deleteSoftwareApiV1SoftwareSoftwareSoftwareIdDelete
  >[0] // Correctly type the argument
) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      SoftwareService.deleteSoftwareApiV1SoftwareSoftwareSoftwareIdDelete(args),
    null,
    { sendToast: false }
  )

  await fetchData()

  return { data: data.value, error: error.value, loading: loading.value }
}

export async function _putApplication(
  args: Parameters<
    typeof SoftwareService.putSoftwareApiV1SoftwareSoftwareSoftwareIdPut
  >[0] // Correctly type the argument
) {
  const { data, error, fetchData, loading } = useFetch(
    () => SoftwareService.putSoftwareApiV1SoftwareSoftwareSoftwareIdPut(args),
    null,
    { sendToast: false }
  )

  await fetchData()

  return { data: data.value, error: error.value, loading: loading.value }
}

export async function _getApplicationById(
  args: Parameters<
    typeof SoftwareService.getSoftwareApiV1SoftwareSoftwareSoftwareIdGet
  >[0] // Correctly type the argument
) {
  const { data, error, fetchData, loading } = useFetch(
    () => SoftwareService.getSoftwareApiV1SoftwareSoftwareSoftwareIdGet(args),
    null,
    { sendToast: false }
  )

  await fetchData()

  return { data: data.value, error: error.value, loading: loading.value }
}

export async function _addApplication(
  args: Parameters<
    typeof SoftwareAuthorizationService.postSoftwareApiV1SoftwareSoftwarePost
  >[0]
) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      SoftwareAuthorizationService.postSoftwareApiV1SoftwareSoftwarePost(args),
    null,
    { sendToast: false }
  )

  await fetchData()

  return { data: data.value, error: error.value, loading: loading.value }
}
