<template>
  <div
    class="sm-table-row border-b border-gray-200 dark:border-gray-700"
    :class="[
      {
        '!border-b-0': props.isLastRow,
        'cursor-pointer': props.isClickable,
      },
    ]">
    <slot name="selection" :row="props.data">
      <div
        v-if="props.selectable"
        class="sm-table-row-select"
        @click="handleCellClick(-1, props.data, 'selection')">
        <input
          type="checkbox"
          :checked="isChecked"
          :value="props.data[keyField as string]"
          :disabled="props.selectDisabled"
          @change="(e) => handleSelectionChange(e, props.data)" />
      </div>
    </slot>

    <div v-if="props.expandable" class="self-center">
      <v-icon
        ref="expandIcon"
        name="md-expandmore-round"
        scale="1.5"
        class="cursor-pointer transition-all duration-300 ease-in-out"
        :class="{ invisible: !hasExpandContent, '-rotate-90': !isExpanded }"
        @click="expandRow()" />
    </div>

    <div v-if="props.draggable" class="handle sm-table-drag-handler">
      <v-icon
        name="md-dragindicator"
        scale="1.4"
        hover
        fill="var(--el-text-color-disabled)" />
    </div>

    <div
      v-for="(column, idx) in props.columns"
      :key="column.key"
      :class="[`cell-${column.key}`, 'sm-table-row-cell']"
      :style="{
        ...(idx === 0 && !selectable ? { paddingLeft: '10px' } : {}),
        ...cellStyle(column),
      }"
      @click="handleCellClick(idx, props.data, column.key)">
      <slot :name="column.key" :row="props.data">
        <span
          v-if="
            props.data[column.key] && typeof props.data[column.key] === 'string'
          ">
          <TextMultiline
            :text="props.data[column.key] as string"
            tooltip-max-width="300px" />
        </span>
        <span v-else class="no-data">{{ i18n.t('noData') }}</span>
      </slot>
    </div>
  </div>
  <div
    ref="exapandWrapper"
    class="sm-tablerow-expand-wrapper max-h-0 overflow-hidden transition-all duration-300 ease-in-out"
    :class="{ 'border-b': isExpanded }">
    <slot name="expand" :row="props.data" :active="true"> </slot>
  </div>
</template>

<script setup generic="T extends TableData" lang="ts">
  import { useI18n } from 'vue-i18n'
  import { Column } from './SmTable.types'
  import TextMultiline from './TextMultiline.vue'
  import { TableData } from './SmTable.vue'

  const i18n = useI18n()
  const slots = useSlots()

  interface Props {
    keyField: keyof T
    data: T
    selectable?: boolean
    selectDisabled?: boolean
    columns: Column<T>[]
    isChecked?: boolean
    draggable?: boolean
    isLastRow?: boolean
    isClickable?: boolean
    expandable?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    selectable: false,
    draggable: false,
    isChecked: false,
    isLastRow: false,
    isClickable: false,
    expandable: false,
  })

  const emit = defineEmits<{
    'handle-cell-click': [{ iRow: number; columnKey: string; row: T }]
    'handle-selection-change': [{ event: Event; item: T }]
  }>()

  function handleCellClick(iRow: number, row: T, columnKey: string) {
    emit('handle-cell-click', { iRow, columnKey, row })
  }

  function handleSelectionChange(event: Event, item: T) {
    emit('handle-selection-change', { event, item })
  }

  const columnStyleCache: Record<string, Record<string, number | string>> = {}
  function cellStyle(column: Column<T>) {
    // Check cache
    if (columnStyleCache[column.key]) {
      return columnStyleCache[column.key]
    }

    const style: Record<string, number | string> = {}

    // Get width
    if (typeof column.width === 'number') {
      style['flex'] = `${column.width}`
    } else {
      style['width'] = column.width
    }

    // set alignment
    if (column.align) {
      style['justify-content'] = column.align
    }

    // Cache style
    if (!columnStyleCache[column.key]) {
      columnStyleCache[column.key] = {}
    }
    columnStyleCache[column.key] = style

    // Return style
    return style
  }

  // Expander Slot

  const exapandWrapper = ref<HTMLElement | null>(null)
  const isExpanded = ref<boolean>(false)

  function expandRow() {
    if (exapandWrapper.value) {
      if (
        exapandWrapper.value.style.maxHeight &&
        exapandWrapper.value.style.maxHeight !== '0px'
      ) {
        isExpanded.value = false
        exapandWrapper.value.style.maxHeight = '0'
      } else {
        isExpanded.value = true
        exapandWrapper.value.style.maxHeight =
          exapandWrapper.value.scrollHeight + 'px'
      }
    }
  }

  const hasExpandContent = computed(() => {
    if (slots.expand) {
      const expandSlot = slots.expand()
      return !!expandSlot[0].children?.length
    }
  })
</script>

<style lang="scss">
  $gap: 1.5rem;
  $select-width: 40px;

  .sm-table-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border-bottom: 1px solid var(--el-border-color-lighter);
    min-height: 52px;

    // Remove link underline
    text-decoration: none;
    color: inherit;

    &:hover {
      background-color: var(--el-fill-color-light);
    }
  }

  .sm-table-row-select {
    width: $select-width;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sm-table-row-cell {
    display: flex;
    align-items: center;
    justify-content: left;
    color: var(--el-table-text-color);
    padding: 0 calc($gap / 2);
  }

  .sm-table-drag-handler {
    cursor: grab !important;
    display: flex;
    align-items: center;
  }

  .sm-table-row.sortable-chosen {
    cursor: grabbing !important;
  }
</style>
