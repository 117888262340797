<template>
  <DeleteConfirmDialog
    v-if="licenseToDeleteInfo"
    v-model:visible="deleteLicenseDialogVisible"
    :loading="deleteLicenseLoading"
    :title="i18n.t('deleteLicense')"
    :sub-title="
      i18n.t('licenseModelDeleteConfirmText', {
        name: duplicateLicenses[licenseToDeleteInfo.index].value.email,
      })
    "
    @cancel="deleteLicenseDialogVisible = false"
    @delete="deleteLicense()" />
  <el-card
    v-for="(license, index) in duplicateLicensesUnrefed"
    :key="license._id"
    shadow="never">
    <div class="optimization-content">
      <div class="heading">{{ i18n.t('general.user') }}</div>
      <div v-if="usersOfLicenses" class="content">
        {{ usersOfLicenses[index].name || usersOfLicenses[index].email }}
      </div>
      <div class="heading">{{ i18n.t('email') }}</div>
      <div class="content">
        {{ license.email }}
      </div>
      <div v-if="props.softwareName" class="heading">
        {{ i18n.t('software') }}
      </div>
      <div v-if="props.softwareName" class="content">
        {{ props.softwareName }}
      </div>
      <div class="heading">{{ i18n.t('licenses') }}</div>
      <div class="content">
        <LicenceModelNamesDisplay
          v-if="license.software_licenses"
          :software-licenses="Object.values(license.software_licenses)" />
      </div>
      <div class="action">
        <Transition mode="out-in">
          <SmButton
            v-if="!duplicateLicenseInfos[index].deleted"
            @click="handleLicenseDeleteClick(index, license._id)">
            {{ i18n.t('deleteLicense') }}
          </SmButton>
          <SmDialogMessage
            v-else
            type="success"
            :message="i18n.t('deleteLicenseSuccess')"
            :visibility="duplicateLicenseInfos[index].deleted === true" />
        </Transition>
      </div>
    </div>
  </el-card>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { Account, LicenceOut, Optimization } from '@/client'
  import LicenceModelNamesDisplay from '@/components/LicenceModelNamesDisplay.vue'
  import { useUserStore } from '@/stores/userStore'

  import { computedAsync } from '@vueuse/core'
  import { sendToast } from './sm/SmNotification'
  import { AccountStore } from '@/stores/account.store'

  type DuplicateLicenseInfo = {
    deleted: boolean
  }

  const i18n = useI18n()

  const licenseToDeleteInfo = ref<{ index: number; id: string } | undefined>()
  const userStore = useUserStore()

  const deleteLicenseDialogVisible = ref(false)
  const deleteLicenseLoading = ref(false)

  const usersOfLicenses = ref<Account[]>()

  const props = defineProps({
    softwareName: {
      type: String,
      required: true,
    },
    optimization: {
      type: Object as PropType<Optimization>,
      required: true,
    },
  })

  const duplicateLicenseInfos = ref<DuplicateLicenseInfo[]>([])

  const duplicateLicenses = computed(() => {
    if (!props.optimization.data?.duplicate_licences) return []
    const l = []
    for (const license of props.optimization.data.duplicate_licences) {
      l.push(
        computedAsync(async () => {
          return (await AccountStore.getAccountById(license._id)) as LicenceOut
        })
      )
    }
    return l
  })

  const duplicateLicensesUnrefed = computed(() => {
    const l: LicenceOut[] = []
    for (const license of duplicateLicenses.value) {
      if (!license.value) return
      l.push(license.value)
    }
    return l
  })

  function handleLicenseDeleteClick(index: number, id?: string) {
    if (!id) return
    licenseToDeleteInfo.value = { index, id }
    deleteLicenseDialogVisible.value = true
  }

  function deleteLicense() {
    if (!licenseToDeleteInfo.value) return
    const index = licenseToDeleteInfo.value.index
    const id = licenseToDeleteInfo.value.id
    deleteLicenseLoading.value = true
    AccountStore.deleteAccount(id)
      .then((res) => {
        if (res) {
          sendToast(i18n.t('deleteLicenseSuccess'), undefined, 'success')
          duplicateLicenseInfos.value[index].deleted = true
        }
      })
      .finally(() => {
        delete licenseToDeleteInfo.value
        deleteLicenseLoading.value = false
        deleteLicenseDialogVisible.value = false
      })
  }

  watch(
    () => props.optimization.data?.duplicate_licences,
    async () => {
      if (!props.optimization.data?.duplicate_licences) return
      usersOfLicenses.value = []
      duplicateLicenseInfos.value = []
      for (const license of props.optimization.data.duplicate_licences) {
        if (!license.account_id) continue
        const user = await userStore.getUserByID(license.account_id)
        if (user) usersOfLicenses.value.push(user)
        duplicateLicenseInfos.value.push({ deleted: false })
      }
    },
    { immediate: true }
  )
</script>

<style lang="scss" scoped>
  .optimization-content {
    display: grid;
    grid-template-columns: 80px auto auto;
    grid-auto-rows: auto;
    gap: 0.8rem;
  }
  .heading {
    grid-column: 1;
  }
  .content {
    grid-column: 2;
    padding-right: 1rem;
  }
  .action {
    grid-column: 3;
    margin-left: auto;
    grid-row: 1 / 4;
    display: flex;
    align-items: center;
  }
  .alert {
    margin: 0 !important;
  }
</style>
