<template>
  <!-- Content -->
  <div class="flex h-full w-full flex-col items-center justify-center gap-4">
    <div class="relative p-3">
      <slot name="icon">
        <v-icon
          :name="props.icon"
          scale="2"
          class="fill-primary"
          style="z-index: 4; position: relative" />

        <div
          class="absolute left-0 top-0 h-full w-full rounded bg-primary opacity-10"></div>
      </slot>
    </div>

    <div class="text-center">
      <slot name="content">
        <h3 v-if="props.title">{{ props.title }}</h3>
        <p v-if="props.text" class="max-w-xl">{{ props.text }}</p>
      </slot>
    </div>

    <div class="grid grid-cols-2">
      <SmButton
        v-if="showDocsBtn"
        type="primary"
        :class="{ 'col-span-2': !showCreateBtn }"
        outline
        @click="emit('showDocs')">
        {{ props.docsLabel ?? i18n.t('readDocs') }}
      </SmButton>
      <SmButton
        v-if="showCreateBtn"
        type="primary"
        :class="{ 'col-span-2': !showDocsBtn }"
        @click="emit('create')">
        {{ props.createLabel ?? i18n.t('create') }}
      </SmButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()
  const emit = defineEmits(['create', 'showDocs'])

  const props = defineProps({
    icon: {
      type: String,
      default: 'md-cancel-round',
    },
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    createLabel: {
      type: String,
      required: false,
    },
    docsLabel: {
      type: String,
      required: false,
    },
    showDocsBtn: {
      type: Boolean,
      default: true,
    },
    showCreateBtn: {
      type: Boolean,
      default: true,
    },
  })
</script>
