<template>
  <SmDialog
    v-model:visibility="visible"
    :title="i18n.t('editLicense')"
    class="dialog"
    size="medium">
    <div style="padding-right: 12px">
      <LicenseModelForm
        ref="form"
        v-model:software-license="model"
        label-width="12rem"
        :license-model-id="model.license._id"
        :custom-validators="customValidators" />
    </div>

    <template #footer>
      <div class="submit-buttons">
        <SmButton size="small" outline @click="handleCancel">
          {{ i18n.t('general.cancel') }}
        </SmButton>
        <SmButton size="small" @click="handleSave">
          {{ i18n.t('general.save') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { SoftwareLicense } from '@/client'
  import { CustomValidator } from '@/common/util/licenseModel'
  import { sendToast } from './sm/SmNotification'

  import LicenseModelForm from './LicenseModelForm.vue'
  import { ComponentExposed } from 'vue-component-type-helpers'

  const props = defineProps<{
    visible: boolean
    customValidators?: CustomValidator[]
    preventSubmit?: boolean
    softwareLicense: SoftwareLicense
  }>()
  const emit = defineEmits<{
    add: []
    saved: []
    'update:visible': [visible: boolean]
    cancelled: []
  }>()

  const i18n = useI18n()
  const form = ref<ComponentExposed<typeof LicenseModelForm> | null>()

  const visible = computed({
    get: () => props.visible,
    set: (val) => emit('update:visible', val),
  })

  const model = ref<{
    license: SoftwareLicense
  }>({
    license: {} as SoftwareLicense,
  })

  async function handleSave() {
    if (!form.value) return

    if (!form.value.validate()) {
      sendToast(i18n.t('validationFailed'), undefined, 'error')
      return
    }

    if (!props.preventSubmit) {
      await form.value.submitForms('update')
      sendToast(i18n.t('licenseSaved'), undefined, 'success')
      emit('saved')
    }
  }

  function handleCancel() {
    visible.value = false
    emit('cancelled')
  }

  watch(
    visible,
    (newValue) => {
      if (newValue === true) {
        model.value.license = props.softwareLicense
      }
    },
    { immediate: true }
  )

  defineExpose({
    form,
  })
</script>

<style scoped>
  .submit-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
</style>
