<template>
  <el-card
    shadow="always"
    :body-style="{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    }">
    <div style="display: flex; float: left">
      <span :style="cardTextStyle">{{
        i18n.t('views.dashboard.optimizations.title')
      }}</span>
    </div>
    <div
      :class="{
        'card-content-wrapper': !props.hideChart,
        'card-content-wrapper-hide-chart': props.hideChart,
      }">
      <div v-if="!props.hideChart" class="chart-box">
        <div
          v-if="optimizations.length === 0"
          style="
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          ">
          <v-icon name="md-verified-outlined" scale="4" />
        </div>

        <ChartBoxComponent v-else :optimizations="optimizations" />
      </div>
      <div v-if="!props.hideChart" class="divider" />
      <div class="list-box">
        <!-- Empty State -->
        <div
          v-if="optimizations.length === 0 && !loadingOptimizations"
          style="
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          ">
          <p>
            <span style="font-size: 1.3rem; font-weight: 600">
              {{ i18n.t('views.dashboard.optimizations.optimized') }}
            </span>
            <br />
            <span style="font-size: 2rem"> 🎉 </span>
          </p>
        </div>

        <OptimizationsList
          :allSoftwareLicenses
          :allPriceInformations
          :optimizations="optimizations"
          :loading="loadingOptimizations"
          @reload="loadOptimizations" />
      </div>
    </div>
  </el-card>
</template>

<script lang="ts" setup>
  import { StyleValue } from 'vue'
  import { useI18n } from 'vue-i18n'

  import {
    Optimization,
    OptimizationStatus,
    OptimizationsService,
    PriceInformation,
    SoftwareLicense,
    SoftwareLicensesService,
  } from '@/client'

  import OptimizationsList from '../OptimizationsList.vue'
  import ChartBoxComponent from './components/OptimizationsCardChartBoxComponent/OptimizationsCardChartBoxComponent.vue'

  const props = defineProps({
    hideChart: {
      type: Boolean,
      default: false,
    },
    softwareId: {
      type: String,
      default: '',
    },
    cardTextStyle: {
      type: Object as PropType<StyleValue>,
      default: () => ({}),
    },
  })

  const i18n = useI18n()

  const loadingOptimizations = ref<boolean>(true)
  const optimizations = ref<Optimization[]>([])
  const allSoftwareLicenses = ref<SoftwareLicense[]>([])
  const allPriceInformations = ref<PriceInformation[]>([])

  function loadOptimizations() {
    OptimizationsService.getOptimizationsApiV1ManagementOptimizationsGet({
      softwareId: props.softwareId || undefined,
      status: OptimizationStatus.OPEN,
      softwareRequiresAction: false,
    }).then((response) => {
      optimizations.value = response
      loadingOptimizations.value = false
    })
  }

  function loadSoftwareLicenses() {
    SoftwareLicensesService.getSoftwareLicensesApiV1SoftwareSoftwareLicensesGet(
      {}
    ).then((response) => {
      allSoftwareLicenses.value = response
    })
    SoftwareLicensesService.getSoftwareLicensePriceInformationsApiV1SoftwareSoftwareLicensePriceInformationsGet(
      {}
    ).then((response) => {
      allPriceInformations.value = response
    })
  }

  onMounted(() => {
    loadOptimizations()
    loadSoftwareLicenses()
  })
</script>

<style scoped>
  .card-header {
    color: var(--el-text-color-secondary);
    font-weight: 600;
  }

  .card-content-wrapper {
    display: grid;
    height: calc(100% - 21px);
    grid-template-columns: 1fr 2px repeat(2, 1fr);
    gap: 1rem;
    grid-template-rows: 100%;
    grid-template-areas: 'chart-box divider list-box list-box';
  }

  .card-content-wrapper-hide-chart {
    display: grid;
    height: calc(100% - 21px);
    grid-template-columns: 1fr 2px repeat(2, 1fr);
    grid-template-rows: 100%;
    grid-template-areas: 'list-box list-box list-box list-box';
  }

  .chart-box {
    grid-area: chart-box;
    height: calc(100% - 21px);
  }

  .divider {
    grid-area: divider;
    height: 100%;
    width: 2px;
    background-color: var(--el-border-color-light);
  }

  .list-box {
    grid-area: list-box;
    height: 100%;
  }
</style>
