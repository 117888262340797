<template>
  <SmDialog
    v-model:visibility="visibility"
    :title="
      props.license.status !== 'disabled'
        ? i18n.t('deactivateAccount')
        : i18n.t('activateAccount')
    ">
    <SmDialogMessage
      :visible="status !== 'loading' && status !== 'idle'"
      :type="status === 'done' ? 'success' : 'error'"
      style="margin-bottom: 10px">
      <!-- Not disabled is active and invited -->
      <div v-if="status === 'done' && license.status !== 'disabled'">
        {{ i18n.t('deactivateAccountSuccess') }}
      </div>
      <div v-else-if="status === 'done' && license.status === 'disabled'">
        {{ i18n.t('activateAccountSuccess') }}
      </div>
      <div v-else-if="status === 'error'">
        {{ i18n.t('deactivateAccountError') }}
      </div>
    </SmDialogMessage>

    <div class="deactivate-content">
      <!-- If markdown is available -->
      <div v-if="markdownStatus === 'done' && markdown" class="markdown">
        <MarkdownContent ref="markdownContent" :markdown="markdown" />
      </div>

      <!-- If markdown is loading/checked if exists -->
      <div v-if="markdownStatus === 'loading'" class="loading">
        <div style="height: 150px; position: relative">
          <SmLoading :loading="true" />
        </div>
      </div>

      <!-- If markdown couldn't be found/not exists -->
      <div
        v-if="markdownStatus === 'error' && license.status !== 'disabled'"
        class="default">
        {{ i18n.t('deactivateAccountGenericDescription') }}
      </div>
      <div
        v-if="markdownStatus === 'error' && license.status === 'disabled'"
        class="default">
        {{ i18n.t('activateAccountGenericDescription') }}
      </div>
    </div>

    <!-- Account to deactivate -->
    <div class="license-overview">
      <h3>{{ i18n.t('account') }}</h3>
      <p>{{ i18n.t('followingAccountChanged') }}</p>
      <div class="license-overview-item">
        <!-- Name -->
        <div class="license-overview-item-label">{{ i18n.t('name') }}</div>
        <div class="license-overview-item-value">
          {{ props.license.name }}
        </div>

        <!-- Status -->
        <div class="license-overview-item-label">{{ i18n.t('status') }}</div>
        <div class="license-overview-item-value">
          <LicenceStatusSmall
            v-if="props.license.status"
            :status="props.license.status" />
        </div>
      </div>
    </div>

    <template #footer>
      <div class="footer">
        <!-- Close Button -->
        <SmButton color="primary" @click="visibility = false">
          {{ i18n.t('general.close') }}
        </SmButton>

        <!-- Deactivate Button -->
        <SmButton
          v-if="
            (status === 'idle' || status === 'loading') &&
            license.status !== 'disabled'
          "
          type="warning"
          :loading="status === 'loading'"
          @click="deactivateCurrentAccount">
          {{ i18n.t('deactivateAccount') }}
        </SmButton>

        <!-- Activate Button -->
        <SmButton
          v-else-if="status === 'idle' || status === 'loading'"
          :loading="status === 'loading'"
          type="secondary"
          @click="activateCurrentAccount">
          {{ i18n.t('activateAccount') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { LicenceOut } from '@/client'

  import MarkdownContent from './MarkdownContent.vue'
  import SmLoading from './sm/SmLoading.vue'
  import { AccountStore } from '@/stores/account.store'
  const i18n = useI18n()

  const markdownStatus = ref<'loading' | 'done' | 'error'>('loading')
  const markdown = ref<string | null>(null)

  const status = ref<'loading' | 'done' | 'error' | 'idle'>('idle')

  const props = defineProps({
    license: {
      type: Object as PropType<LicenceOut>,
      required: true,
    },
  })

  const emit = defineEmits(['update:visibility', 'deactivated'])

  const visibility = defineModel('visibility', {
    type: Boolean,
  })

  const controller = new AbortController()

  // Load the license deactivation content from the CDN
  async function loadLicenseDeactivationContent() {
    // Download the SDK https://cdn.saasmetrix.io/Anleitungen/15FiveToken.md with fetch
    const response = await fetch(
      `https://cdn.saasmetrix.io/contents/deactivate/ToDo.md`,
      {
        signal: controller.signal,
      }
    ).then(
      (response) => response,
      (error) => {
        console.error(error)
        return null
      }
    )
    // If the manual couldn't be found
    if (!response) {
      markdownStatus.value = 'error'
      return null
    }

    markdown.value = await response.text()
    markdownStatus.value = 'done'
    return markdown
  }

  function deactivateCurrentAccount() {
    if (!props.license._id) return // Should never happen
    status.value = 'loading'
    AccountStore.deactivateAccount(props.license._id)
      .then(() => {
        status.value = 'done'
      })
      .catch(() => {
        status.value = 'error'
      })
      .finally(() => {
        emit('deactivated')
      })
  }

  function activateCurrentAccount() {
    if (!props.license._id) return // Should never happen

    status.value = 'loading'
    AccountStore.activateAccount(props.license._id)
      .then(() => {
        status.value = 'done'
      })
      .catch(() => {
        status.value = 'error'
      })
      .finally(() => {
        emit('deactivated')
      })
  }

  onMounted(() => {
    loadLicenseDeactivationContent()
  })

  // Reset the status when the dialog is closed
  watch(visibility, (value) => {
    if (!value) {
      status.value = 'idle'
    }
  })
</script>

<style lang="scss" scoped>
  h3,
  p {
    margin: 0;
  }

  h3 {
    margin-top: 10px;
  }

  .license-overview-item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 10px;

    .license-overview-item-label {
      font-weight: bold;
    }

    // Border
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 4px;

    & > div {
      border-top: 1px solid #e0e0e0;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    // Remove border from first item and second item
    & > div:first-child,
    & > div:nth-child(2) {
      border-top: none;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 10px;
  }
</style>
