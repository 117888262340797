<template>
  <TaskEditDialog
    v-if="taskEditDialogVisible"
    v-model:visible="taskEditDialogVisible"
    :task-id="taskToEdit"
    @close="taskToEdit = ''" />

  <TaskArchiveDialog
    v-if="taskArchiveDialogVisible"
    v-model:visible="taskArchiveDialogVisible"
    :task-id="taskToArchive"
    :background-job-ids="taskToArchiveBackgroundJobIds"
    @archived="(taskToArchive = ''), closeDetailDrawer()" />

  <TaskDrawer
    v-if="taskDrawerVisible && taskToShow"
    v-model:drawer-visibility="taskDrawerVisible"
    :task-id="taskToShow"
    @close="taskToShow = undefined"
    @edit="(id: string) => openEditDialog(id)"
    @archive="
      (id: string, backgroundJobIds: string[]) =>
        openArchiveDialog(id, backgroundJobIds)
    " />

  <!-- Card Header -->
  <div
    v-if="tasks?.length && !loadingTasks"
    class="flex items-center gap-4 py-4">
    <h1 class="mr-auto">{{ capitalize(i18n.t('tasks')) }}</h1>

    <SmButton @click="openCreateDialog()">
      {{ i18n.t('createTask') }}
    </SmButton>
  </div>

  <div v-if="loadingTasks" v-loading="loadingTasks" class="h-full w-full"></div>

  <SmTable
    v-else-if="tasks?.length && !loadingTasks"
    :data="tasks ?? []"
    :columns="columns"
    key-field="_id"
    class="!h-auto overflow-hidden"
    :default-sorting="{
      by: 'created_at',
      asc: true,
    }"
    @scroll-end-reached="loadMoreTasks"
    @cell-click="handleRowClick">
    <template #title="{ row }">
      <div class="flex items-center">
        <SmTooltip
          v-if="row.description"
          :content="row.description"
          placement="top">
          <TextMultiline :text="row.title" />
        </SmTooltip>
        <TextMultiline v-else :text="row.title" />
      </div>
    </template>
    <template #progress="{ row }">
      <TasksProgress :background-job-ids="row.background_job_ids" />
    </template>

    <template #created_at="{ row }">
      <TextMultiline
        :text="displayDate(dayjs(row.created).toDate(), 'long')"
        :show-tool-tip="true" />
    </template>
    <template #actions="{ row }">
      <SmDropdown trigger="click" class="ml-auto mr-4">
        <SmDropdownItem icon="io-eye" @click="openDetailDrawer(row._id)">
          {{ i18n.t('showTask') }}
        </SmDropdownItem>
        <SmDropdownItem
          icon="hi-pencil-alt"
          class="stroke-contrast"
          @click="openEditDialog(row._id)">
          {{ i18n.t('editTask') }}
        </SmDropdownItem>
        <SmDropdownItem
          icon="oi-archive"
          @click="openArchiveDialog(row._id, row.background_job_ids)">
          {{ i18n.t('archiveTask') }}
        </SmDropdownItem>
      </SmDropdown>
    </template>
  </SmTable>

  <EmptyState
    v-else
    icon="oi-workflow"
    :title="capitalize(i18n.t('tasks'))"
    :text="i18n.t('taskEmptyStateText')"
    :create-label="i18n.t('createTask')"
    :show-docs-btn="false"
    @create="taskEditDialogVisible = true" />
</template>

<script setup lang="ts">
  import { Task } from '@/client'
  import { taskStore } from '@/stores/task.store'
  import { useI18n } from 'vue-i18n'
  import { Column } from './sm/SmTable.types'
  import { TaskFilterInstance } from '@/stores/task.utils'
  import { Subscription } from 'rxjs'
  import { displayDate } from '@/common/util/timeUtils'
  import { capitalize } from '@/common/util/formatter'

  import dayjs from 'dayjs'

  const i18n = useI18n()

  const tasks = ref<Task[]>()
  const totalTaskCount = ref(0)
  const filter = ref<TaskFilterInstance[]>([])
  const taskToEdit = ref<string>('')
  const taskToShow = ref<string | undefined>(undefined)
  const taskToArchive = ref<string>('')
  const taskToArchiveBackgroundJobIds = ref<Array<string>>([])
  const taskDrawerVisible = ref<boolean>(false)
  const taskEditDialogVisible = ref<boolean>(false)
  const taskArchiveDialogVisible = ref<boolean>(false)

  onMounted(async () => {
    reloadTasks()
  })

  onUnmounted(() => {
    currentSubscription.value?.unsubscribe()
  })

  /**
   * Row Functions
   */

  const taskCountLabel = computed(() => {
    return `${totalTaskCount.value} ${i18n.t('tasks', { count: totalTaskCount.value })}`
  })

  const columns = computed<Column<Task>[]>(() => [
    {
      key: 'title',
      label: i18n.t('name'),
      sortable: false,
      width: 2,
      footer: taskCountLabel,
    },
    {
      key: 'progress',
      label: i18n.t('progress'),
      sortable: false,
      width: 1,
    },
    {
      key: 'created_at',
      label: i18n.t('createdAt'),
      sortable: true,
      width: 1,
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      width: '50px',
    },
  ])

  function handleRowClick(iRow: number, columnKey: string, row: Task) {
    if (columnKey === 'actions') return
    if (columnKey === 'status') return
    openDetailDrawer(row._id)
    taskToShow.value = row._id
    taskDrawerVisible.value = true
  }

  function openDetailDrawer(id: string) {
    taskToShow.value = id
    taskDrawerVisible.value = true
  }

  function closeDetailDrawer() {
    taskToShow.value = ''
    taskDrawerVisible.value = false
  }

  function openEditDialog(id: string) {
    taskToEdit.value = id
    taskEditDialogVisible.value = true
  }

  function openCreateDialog() {
    taskEditDialogVisible.value = true
    taskToEdit.value = ''
  }

  function openArchiveDialog(id: string, backgroundJobIds: string[]) {
    taskToArchive.value = id
    taskToArchiveBackgroundJobIds.value = backgroundJobIds
    taskArchiveDialogVisible.value = true
  }

  /**
   * Load tasks
   */

  watch(filter.value, () => reloadTasks())

  const currentSubscription = ref<Subscription>()
  const loadingTasks = ref<boolean>(true)

  const INITIAL_ROW_LIMIT = 300
  const ROW_EXPAND_COUNT = 100

  async function countTasks() {
    taskStore
      .getTasksCount()
      .then((count) => (totalTaskCount.value = count.total ?? 0))
  }

  async function reloadTasks() {
    loadingTasks.value = true
    currentSubscription.value?.unsubscribe()

    taskStore
      .getTasksSubscription(filter.value, {
        limit: INITIAL_ROW_LIMIT,
      })
      .then((result) => {
        currentSubscription.value = result?.subscribe((data) => {
          tasks.value = data
          loadingTasks.value = false
          countTasks()
        })
      })
  }

  async function loadMoreTasks() {
    taskStore
      .getTasks(filter.value, {
        limit: ROW_EXPAND_COUNT,
        skip: tasks.value?.length,
      })
      .then((response) => {
        if (response) tasks.value = tasks.value?.concat(response)
      })
  }
</script>
