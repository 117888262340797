<template>
  <div class="flex flex-wrap">
    <div class="m-6 flex gap-5">
      <SmButton @click="loadAll">Load all</SmButton>
      <SmButton @click="getAllAccountsTheOldWay">Load all accounts</SmButton>
      <SmButton @click="storeAllToCache">Store all to cache</SmButton>
      <SmButton @click="deleteAll" type="danger"
        >Delete and Recreate Cache</SmButton
      >
      <SmButton @click="deleteTable" type="danger">Delete Table</SmButton>

      <SmButton @click="getAll">Load all Cache</SmButton>
    </div>
    <div class="m-6 flex flex-row gap-4">
      <div class="flex flex-col">
        <span>Accounts</span>
        <span>{{ allAccounts.length }}</span>
      </div>

      <div class="flex flex-col">
        <span>Accounts (Chache)</span>
        <span>{{ allAccountsSQL.length }}</span>
      </div>
    </div>

    <div class="m-5 flex h-fit min-w-96 flex-col gap-3 rounded border p-3">
      <h1>Test Filter</h1>
      <SmInput size="small" outline v-model="query" />

      <div class="flex align-middle">
        <span class="min-w-24">Limit</span>
        <SmInput v-model="limit" size="small" outline type="number" />
      </div>

      <div>
        <span>Results: </span>
        <span class="font-bold">{{ searchResults.length }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { LicenceOut, UserLicencesService } from '@/client'
  import { useFetch } from '@/common/useFetch'
  import SmButton from '@/components/sm/SmButton.vue'
  import SmInput from '@/components/sm/SmInput.vue'
  import { AccountStore } from '@/stores/account.store'
  import { db, resetDatabase } from '@/stores/database'

  async function loadAll() {
    const results = await db.selectFrom('licence_out').selectAll().execute()
    console.log('RESULTS', results)
  }

  const allAccounts = ref<LicenceOut[]>([])
  async function getAllAccountsTheOldWay() {
    const { data, error, fetchData, loading } = useFetch(
      () => UserLicencesService.getLicencesApiV1SoftwareLicencesGet({}),
      null,
      { sendToast: true }
    )
    return fetchData().then(() => {
      if (data.value) allAccounts.value = data.value
      return { data: data.value, error: error.value, loading: loading.value }
    })
  }

  async function storeAllToCache() {
    const rawAccounts = toRaw(allAccounts.value)
    console.log('Store all licenses')
    console.time('IN')

    const insertResult = await AccountStore.bulkInsertLicenceOuts(rawAccounts)

    console.timeEnd('IN')
    console.log('Stored all accounts', insertResult)
  }

  const allAccountsSQL = ref<LicenceOut[]>([])
  async function getAll() {
    console.time('Get')
    allAccountsSQL.value = await AccountStore.getAllLicenceOuts()
    console.timeEnd('Get')
  }

  // Query
  const query = ref<string>()
  const limit = ref<number>(1000)
  const searchResults = ref<LicenceOut[]>([])
  watch(
    [query, limit],
    async () => {
      console.time('Search')
      searchResults.value = await AccountStore.getAccounts(
        { email: query.value },
        { limit: limit.value !== 0 ? limit.value || undefined : undefined }
      )
      console.timeEnd('Search')
    },
    { immediate: true }
  )

  // Delete Table
  async function deleteTable() {
    db.schema.dropTable('licence_out').ifExists().execute()
  }

  async function deleteAll() {
    resetDatabase()
  }
</script>
