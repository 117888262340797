<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<template>
  <div class="my-3 mt-5 rounded bg-gray-100 p-3 dark:bg-gray-800">
    <div v-if="licences != null && licences.length > 0">
      <h4 style="word-break: break-word" class="mb-3">
        {{
          i18n.t('aliasConnectedAccounts', {
            newAlias: props.alias,
            email: props.user.email,
          })
        }}
      </h4>
    </div>

    <SmTable
      v-if="licences"
      key-field="_id"
      :data="licences"
      :columns="columns">
      <template #application="{ row }">
        {{ getSoftware(row.software_id)?.display_name }}
      </template>
    </SmTable>

    <h4
      v-if="
        user == null &&
        addedUser == null &&
        deletedUser == null &&
        (users == null ||
          (users != null &&
            users.length == 0 &&
            (licences == null || (licences != null && licences.length == 0))))
      ">
      {{ i18n.t('aliasNoSoftwareAssociated', { newAlias: props.alias }) }}
    </h4>
  </div>
</template>

<!-- eslint-disable vue/require-default-prop -->
<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { Licence_Output, LicenceOut } from '@/client'
  import { User } from '@/types/user'
  import { ApplicationStore } from '@/stores/application.store'

  const i18n = useI18n()

  const allApps = ApplicationStore.useApplications().data

  const props = defineProps({
    // Created alias
    alias: {
      type: String,
      required: true,
    },
    // Can the "created" / "deleted"
    action: {
      type: String,
      required: false,
      default: 'created',
    },
    // User that was
    user: {
      type: Object as PropType<User>,
      default: () => null,
    },
    // The addedUser if the backend created a new user from an alias
    addedUser: {
      type: Object as PropType<User>,
      default: () => null,
    },
    // The deletedUser if the backend merged a user into another user
    deletedUser: {
      type: Object as PropType<User>,
      default: () => null,
    },
    // The users where the alias was found to, and where it gonna be removed
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    // The licences where the alias effects the account_id of the licence
    licences: {
      type: Array as PropType<LicenceOut[]>,
      default: () => [],
    },
  })

  const columns = [
    {
      key: 'application',
      label: i18n.t('Application'),
      width: 2,
    },
    {
      key: 'email',
      label: i18n.t('views.licences.table.email'),
      width: 2,
      sortFn: (a: Licence_Output, b: Licence_Output) => {
        return a.email.toLowerCase().localeCompare(b.email.toLowerCase())
      },
    },
  ]

  function getSoftware(id: string) {
    return allApps.value.find((app) => app._id === id)
  }
</script>
