<template>
  <div>
    <i18n-t
      :keypath="getMessage()"
      tag="p"
      :class="{
        'flex items-center gap-1': true,
        'flex-wrap': allowDisplayInMultipleLines === true,
        'gap-3 break-normal': props.tagStyle === 'default',
      }">
      <!-- User Name-->
      <template v-if="userName || loadingUserName" #user>
        <SmTag
          :appearance="props.tagStyle"
          :label="userName"
          icon="md-person-round"
          :class="{
            'cursor-pointer hover:underline':
              props.clickable && props.tagStyle === 'text',
            'cursor-pointer transition hover:bg-gray-300 dark:hover:bg-gray-500':
              props.clickable && props.tagStyle !== 'text',
            'cursor-default': !props.clickable,
          }"
          :hide-tooltip="props.clickable"
          :loading="loadingUserName"
          @click="props.clickable ? openUserDetailsDrawer(userId) : null" />
      </template>

      <!-- Software Name -->
      <template v-if="softwareName || loadingSoftwareName" #software>
        <SmTag
          :appearance="props.tagStyle"
          :label="softwareName"
          icon="md-gridview-round"
          :loading="loadingSoftwareName">
          <template #label>
            <div class="flex gap-2">
              <ApplicationIcon
                v-if="softwareName && props.tagStyle === 'default'"
                :software-name="softwareName"
                size="18px" />
              <TextMultiline :text="softwareName" />
            </div>
          </template>
        </SmTag>
      </template>

      <!-- License Email -->
      <template v-if="licenseEmail || loadingLicenseEmail" #license>
        <SmTag
          :appearance="props.tagStyle"
          :label="licenseEmail"
          icon="md-description-round"
          :loading="loadingLicenseEmail" />
      </template>

      <!-- Software License Name -->
      <template
        v-if="softwareLicenseName || loadingSoftwareLicenseName"
        #softwarelicense>
        <SmTag
          :appearance="props.tagStyle"
          :label="softwareLicenseName"
          :loading="loadingSoftwareLicenseName">
          <template #label>
            <div class="flex gap-2">
              <ApplicationIcon
                v-if="softwareName && props.tagStyle === 'default'"
                :software-name="softwareName"
                size="18px" />
              <TextMultiline :text="softwareLicenseName" />
            </div>
          </template>
        </SmTag>
      </template>

      <!-- Group Name -->
      <template v-if="groupName || loadingGroupName" #group>
        <SmTag
          :appearance="props.tagStyle"
          :label="groupName"
          icon="md-groups-round"
          :loading="loadingGroupName" />
      </template>

      <!-- Specific message addition -->
      <template
        v-if="props.type == BackgroundJobType.SOFTWARE_USER_REMOVE"
        #action>
        <TextMultiline
          v-if="'delete' in props.data && props.data.delete"
          :text="
            i18n.locale.value === 'en'
              ? i18n.t('delete')
              : i18n.t('delete').toLowerCase()
          " />
        <TextMultiline
          v-else-if="'disable' in props.data && props.data.disable"
          :text="
            i18n.locale.value === 'en'
              ? i18n.t('general.disable')
              : i18n.t('general.disable').toLowerCase()
          " />
      </template>
    </i18n-t>
  </div>

  <UsersDrawer
    v-if="userDetailsDrawerVisibility && userDetailsDrawerUserId"
    v-model:drawer-visibility="userDetailsDrawerVisibility"
    :user-id="userDetailsDrawerUserId"
    @close="userDetailsDrawerUserId = undefined" />
</template>

<script setup lang="ts">
  import {
    BackgroundJobAccountGroupAccountsAddedData,
    BackgroundJobAccountGroupAccountsRemovedData,
    BackgroundJobSoftwareUserAddData,
    BackgroundJobSoftwareUserEnableData,
    BackgroundJobSoftwareUserLicenseAddData,
    BackgroundJobSoftwareUserLicenseRemoveData,
    BackgroundJobSoftwareUserRemoveData,
    BackgroundJobType,
    SoftwareLicensesService,
    UserGroupsService,
  } from '@/client'
  import { AccountStore } from '@/stores/account.store'
  import { ApplicationStore } from '@/stores/application.store'
  import { useUserStore } from '@/stores/userStore'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()
  const userStore = useUserStore()

  // Define the props interface
  interface Props {
    type: BackgroundJobType
    data:
      | BackgroundJobSoftwareUserAddData
      | BackgroundJobSoftwareUserRemoveData
      | BackgroundJobSoftwareUserLicenseRemoveData
      | BackgroundJobSoftwareUserLicenseAddData
      | BackgroundJobSoftwareUserEnableData
      | BackgroundJobAccountGroupAccountsRemovedData
      | BackgroundJobAccountGroupAccountsAddedData
    clickable?: boolean
    allowDisplayInMultipleLines?: boolean
    tagStyle?: 'default' | 'text'
  }

  // Use withDefaults to set default values for optional props
  const props = withDefaults(defineProps<Props>(), {
    clickable: false,
    allowDisplayInMultipleLines: false,
    tagStyle: 'default',
  })

  onMounted(async () => {
    if ('account_id' in props.data) loadUserName(props.data.account_id)
    if ('software_id' in props.data) loadSoftwareName(props.data.software_id)
    if ('licence_id' in props.data) loadLicenseEmail(props.data.licence_id)
    if ('software_license_id' in props.data)
      loadSoftwareLicenseName(props.data.software_license_id)
    if ('account_group_id' in props.data)
      loadGroupName(props.data.account_group_id)
    if ('accounts_removed' in props.data && props.data.accounts_removed)
      loadUserName(props.data.accounts_removed[0])
    if ('accounts_added' in props.data && props.data.accounts_added)
      loadUserName(props.data.accounts_added[0])
  })

  const userName = ref<string>()
  const userId = ref<string>('')
  const loadingUserName = ref(false)
  const softwareName = ref<string>()
  const loadingSoftwareName = ref(false)
  const licenseEmail = ref<string>()
  const loadingLicenseEmail = ref(false)
  const softwareLicenseName = ref<string>()
  const loadingSoftwareLicenseName = ref(false)
  const groupName = ref<string>()
  const loadingGroupName = ref(false)

  /**
   * Load Data
   */

  async function loadUserName(id: string) {
    loadingUserName.value = true
    const cache = await userStore.getUserByID(id)
    userName.value = cache?.name || undefined
    userId.value = cache?._id || ''
    loadingUserName.value = false
  }

  async function loadSoftwareName(id: string) {
    const cache = await ApplicationStore.getApplicationById(id)
    loadingSoftwareName.value = true
    softwareName.value = cache?.software_name || undefined
    loadingSoftwareName.value = false
  }

  async function loadLicenseEmail(id: string) {
    const cache = await AccountStore.getAccountById(id)
    loadingLicenseEmail.value = true

    licenseEmail.value = cache?.email || undefined
    loadingLicenseEmail.value = false

    // always load software when license is loaded as well
    if (cache?.software_id) loadSoftwareName(cache?.software_id)
  }

  async function loadGroupName(id: string) {
    loadingGroupName.value = true
    const group =
      await UserGroupsService.getAccountGroupApiV1ManagementGroupsAccountGroupIdGet(
        {
          accountGroupId: id,
          includeDeleted: true,
        }
      )
    groupName.value = group?.name || undefined
    loadingGroupName.value = false
  }

  async function loadSoftwareLicenseName(id: string) {
    loadingSoftwareLicenseName.value = true
    // todo: replace with store function
    const softwareLicense =
      await SoftwareLicensesService.getSoftwareLicenseApiV1SoftwareSoftwareLicensesSoftwareLicenseIdGet(
        {
          softwareLicenseId: id,
        }
      )
    softwareLicenseName.value = softwareLicense?.name || undefined
    loadingSoftwareLicenseName.value = false

    // always load software when software license is loaded as well
    if (softwareLicense.software_id)
      loadSoftwareName(softwareLicense.software_id)
  }

  /**
   * Get localization string
   */

  function getMessage() {
    switch (props.type) {
      case BackgroundJobType.SOFTWARE_USER_ADD:
        return 'jobMessage.software_user_add'
      case BackgroundJobType.SOFTWARE_USER_REMOVE:
        return 'jobMessage.software_user_remove'
      case BackgroundJobType.SOFTWARE_USER_LICENSE_REMOVE:
        return 'jobMessage.software_user_license_remove'
      case BackgroundJobType.SOFTWARE_USER_LICENSE_ADD:
        return 'jobMessage.software_user_license_add'
      case BackgroundJobType.SOFTWARE_USER_ENABLE:
        return 'jobMessage.software_user_enable'
      case BackgroundJobType.ACCOUNT_GROUP_ACCOUNTS_REMOVED:
        return 'jobMessage.account_group_accounts_removed'
      case BackgroundJobType.ACCOUNT_GROUP_ACCOUNTS_ADDED:
        return 'jobMessage.account_group_accounts_added'
      default:
        return ''
    }
  }

  /**
   * Detail Drawers
   */
  const userDetailsDrawerVisibility = ref(false)
  const userDetailsDrawerUserId = ref<string | undefined>()

  function openUserDetailsDrawer(id: string) {
    userDetailsDrawerUserId.value = id
    userDetailsDrawerVisibility.value = true
  }
</script>
