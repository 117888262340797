<template>
  <!-- todo: delete  PriceInformationDivision-->
  <!-- todo: add textarea field for notes -->

  <el-form label-position="top" :model="form" require-asterisk-position="right">
    <!-- Name -->
    <el-form-item
      :error="form.getErrorMessage('name')"
      :label="i18n.t('general.name')"
      prop="form.fields.name"
      label-position="top">
      <el-input
        v-model="form.fields.name"
        data-test-id="software-licence-model-name" />
    </el-form-item>

    <el-form-item
      v-if="!('external_id' in form.fields) || !form.fields.external_id"
      style="flex-direction: column">
      <div>
        <!-- Defaults -->
        <SmCheckbox v-model="form.fields.auto_assign!.add_to_new_user">
          {{ i18n.t('assignToNewUsers') }}
        </SmCheckbox>

        <SmCheckbox v-model="form.fields.auto_assign!.add_to_new_guest">
          {{ i18n.t('assignToNewGuests') }}
        </SmCheckbox>
      </div>
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
  /* eslint @intlify/vue-i18n/no-raw-text: 0 */
  // import dayjs from 'dayjs'
  // import { usePriceInformationStore } from '@/stores/priceInformationStore'
  import { useI18n } from 'vue-i18n'
  import { SoftwareLicense } from '@/client'
  // import { sortCurrencies } from '@/common/util/currencyUtil'
  import type { CustomValidator } from '@/common/util/licenseModel'
  // import PriceInformationForm from './PriceInformationForm.vue'
  // import { nanoid } from 'nanoid'
  import useForm from '@/common/form'
  import { isRequired } from './sm/SmInput/SmInputValidator'
  import {
    saveNewSoftwareLicense,
    updateSoftwareLicense,
  } from '@/common/license'
  import { useApplicationDetailsStore } from '@/views/ApplicationsDetail/ApplicationDetailsView.store'

  // type PriceInformationFormInstance = InstanceType<typeof PriceInformationForm>

  export type LicenseModelFormModel = {
    license: SoftwareLicense
  }

  const props = withDefaults(
    defineProps<{
      labelWidth?: string
      customValidators?: CustomValidator[]
      licenseModelId: string
    }>(),
    { labelWidth: '8rem', customValidators: () => [] }
  )

  const model = defineModel<LicenseModelFormModel>('softwareLicense', {
    required: true,
  })

  const i18n = useI18n()

  if (!model.value.license.auto_assign) model.value.license.auto_assign = {}

  function validate() {
    const results: boolean[] = []

    results.push(form.validate())

    const result = results.every((result) => result === true)
    return result
  }

  if ('auto_assign' in model.value && model.value.auto_assign == undefined)
    model.value.auto_assign = {
      add_to_new_guest: false,
    }

  const form = useForm(
    {
      ...toRaw(model.value.license),
    },
    // Form Hooks
    {},
    // Form Validators
    {
      name: [isRequired],
    },
    {
      name: i18n.t('name'),
    }
  )

  const applicationDetailsStore = useApplicationDetailsStore()

  async function submitForms(method: 'update' | 'create') {
    let licenseId: string = model.value.license._id

    // submit license model form
    await form.submit(async () => {
      // Update in backend
      if (method === 'update') {
        licenseId = model.value.license._id
        if (form.dirty) {
          await updateSoftwareLicense(form.fields, licenseId)
        }
      } else if (method === 'create') {
        // Create in backend
        if (applicationDetailsStore.software?._id) {
          await saveNewSoftwareLicense(
            form.fields,
            applicationDetailsStore.software._id
          ).then((res) => {
            licenseId = (res as SoftwareLicense)._id
          })
        }
      }
    })
  }

  defineExpose({
    validate,
    submitForms,
    form,
  })
</script>

<style scoped lang="scss">
  .grid-2-col {
    display: grid;
    gap: 20px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  .action-btn {
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      fill: var(--el-color-danger);
    }
  }

  .tab-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin: 28px 0 10px 0;
  }

  .tab-warning {
    padding-right: 0.6rem;
  }

  .is-active .tab-nav .pricing {
    font-weight: normal;
    color: var(--el-text-color-primary);
  }

  .tab-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid var(--el-border-color-light);
    text-align: left;

    .pricing {
      font-weight: normal;
      color: var(--text-color-secondary);
    }

    .tag {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      font-weight: normal;
      padding: 0.125rem 0.75rem;
      margin-top: 0.5rem;
      border-radius: var(--border-radius);
      color: theme('colors.gray[400]');
      border: 1px solid theme('colors.gray[400]');
    }
  }

  .tab-bottom {
    display: flex;

    .tab-bottom__header {
      display: block;
      width: 100%;
      max-width: 40%;
      flex-basis: 40%;
      margin-right: 9px;
      border-right: 2px solid var(--el-border-color-light);

      .add-btn {
        padding-top: 20px;
        margin-right: 20px;
        border-top: 1px solid var(--el-border-color-light);
        cursor: pointer;

        &:hover {
          color: var(--sm-primary);
          .ov-icon {
            color: var(--sm-primary);
          }
        }
      }
    }

    .tab-bottom__content {
      display: block;
      width: 100%;
      max-width: 60%;
      flex-basis: 60%;
    }
  }

  :deep(.el-tabs) {
    display: flex;
    width: 100%;
    align-items: stretch;

    &.el-tabs--left {
      .el-tabs__nav-wrap {
        position: absolute;
        left: 0;
        right: 0;
        padding: 0 !important;

        .el-tabs__nav-prev,
        .el-tabs__nav-next {
          display: none;
        }
        .el-tabs__nav-scroll {
          overflow-y: auto;
        }
      }
      .el-tab-pane {
        padding: 0 0 0 20px;
      }

      .el-tabs__item {
        height: auto;
        padding: 0 20px 0 0;

        &:last-of-type .tab-nav {
          border-bottom: unset;
        }
      }

      .el-tabs__header {
        width: 100%;
        max-width: 40%;
        flex-basis: 40%;
        height: auto !important;
      }

      .el-tabs__content {
        width: 100%;
        max-width: 60%;
        flex-basis: 60%;
      }

      .el-tabs__nav {
        width: 100%;
        transform: none !important;
      }
    }
  }
</style>
