import { SoftwareOut } from '@/client'
import {
  deserializeSoftwareOut,
  serializeSoftwareOut,
  SoftwareOutTable,
} from './application.models'
import { db } from './database.js'
import { createUpdateSet, deepUnwrap } from './util'
import { CacheStateRepository, CacheStateTable } from './cacheState.store'
import dayjs from 'dayjs'
import {
  _addApplication,
  _deleteApplication,
  _getApplicationById,
  _getApplications,
  _putApplication,
} from './application.requests'
import {
  ApplicationFilter,
  applyApplicationFilters,
} from './application.filter'

const lastCacheChange = ref<Date>()

type Pagination = {
  limit?: number
  offset?: number
}

type Sort = {
  column?: keyof SoftwareOutTable
  direction?: 'asc' | 'desc'
}

export const ApplicationStore = {
  async bulkInsertSoftwareOut(
    applications: SoftwareOut[],
    batchSize: number = 1000
  ) {
    const serializedLicences = applications.map(serializeSoftwareOut)

    await db.transaction().execute(async (trx) => {
      for (let i = 0; i < serializedLicences.length; i += batchSize) {
        const batch = serializedLicences.slice(i, i + batchSize)
        await trx
          .insertInto('application')
          .values(batch)
          .onConflict((oc) =>
            oc.column('_id').doUpdateSet(createUpdateSet(serializedLicences[0]))
          )
          .execute()
      }
    })

    if (applications.length) {
      lastCacheChange.value = new Date()
    }
    return applications
  },

  async refreshApplicationCacheFromApi(filter: ApplicationFilter = {}) {
    const results = await _getApplications({
      updatedGte: filter.updatedAfter,
      includeDeleted: true,
      excludeLicenceGroups: true,
    })

    if (!results.error && results.data) {
      await this.bulkInsertSoftwareOut(results.data as SoftwareOut[])
    }
    return results
  },

  async updateApplicationCacheFromApi(fullReload: boolean = false) {
    let isFullReload = true

    const lastRefresh =
      await CacheStateRepository.getCacheStateById('application')

    const filter: ApplicationFilter = {}
    if (lastRefresh && lastRefresh.lastUpdate && !fullReload) {
      filter.updatedAfter = lastRefresh.lastUpdate
      isFullReload = false
    }

    // Update updated licenses
    await this.refreshApplicationCacheFromApi(filter)

    type UpdateCacheStateTable = Partial<Omit<CacheStateTable, 'id'>> & {
      id: string
    }
    const update: UpdateCacheStateTable = {
      id: 'application',
      lastUpdate: dayjs().toISOString(),
    }

    if (isFullReload) {
      update.lastCompleteLoad = dayjs().toISOString()
    }

    await CacheStateRepository.upsertCacheState(update)
    lastCacheChange.value = new Date()
  },

  async getApplications(
    filters: ApplicationFilter = { isDeleted: false },
    pagination: Pagination = {},
    sort: Sort = {}
  ): Promise<SoftwareOut[]> {
    let query = db.selectFrom('application').selectAll()

    query = applyApplicationFilters(query, filters)

    if (pagination.limit) {
      query = query.limit(pagination.limit)
    }
    if (pagination.offset) {
      query = query.offset(pagination.offset)
    }
    if (sort.column) {
      query = query.orderBy(sort.column, sort.direction ?? 'asc')
    }

    const results = await query.execute()
    return results.map(deserializeSoftwareOut)
  },

  async getApplicationById(
    id: string,
    useFrontendCache: boolean = true, // Use SQLite Cache
    options: {
      excludeLicenceGroups?: boolean
      refetchFromApplication?: boolean
    } = { excludeLicenceGroups: true, refetchFromApplication: false }
  ) {
    const record = await db
      .selectFrom('application')
      .selectAll()
      .where('_id', '=', id)
      .executeTakeFirst()

    let result = record ? deserializeSoftwareOut(record) : record

    if (!record || !useFrontendCache || options.refetchFromApplication) {
      const response = await _getApplicationById({
        softwareId: id,
        cached: options.refetchFromApplication,
        excludeLicenceGroups: options.excludeLicenceGroups,
        includeDeleted: true,
      })
      if (response.data) {
        result = response.data
        this.bulkInsertSoftwareOut([result])
      }
    }

    return result || null
  },

  async disconnectApplication(id: string) {
    return _deleteApplication({ softwareId: id }).then(async (response) => {
      await db
        .deleteFrom('application')
        .where('application._id', '=', id)
        .execute()
      lastCacheChange.value = new Date()
      return response
    })
  },

  useApplications(
    initialFilters:
      | ApplicationFilter
      | Ref<ApplicationFilter>
      | ComputedRef<ApplicationFilter> = {},
    initialPagination:
      | Pagination
      | Ref<Pagination>
      | ComputedRef<Pagination> = {},
    initialSort: Sort | Ref<Sort> | ComputedRef<Sort> = {}
  ) {
    const data = ref<SoftwareOut[]>([])

    const fetchData = async () => {
      const filters = deepUnwrap(initialFilters) as ApplicationFilter
      const pagination = deepUnwrap(initialPagination) as Pagination
      const sort = deepUnwrap(initialSort) as Sort
      data.value = await this.getApplications(filters, pagination, sort)
    }

    const stopWatch = watch(
      [
        () => unref(initialFilters),
        () => unref(initialPagination),
        () => unref(initialSort),
        lastCacheChange,
      ],
      async () => {
        await fetchData()
      },
      { deep: true }
    )

    const updateParameters = (
      newFilters: Partial<ApplicationFilter> = {},
      newPagination: Partial<Pagination> = {},
      newSort: Partial<Sort> = {}
    ) => {
      const cloneAndMerge = <T extends object>(
        source: T | Ref<T>,
        updates: Partial<T>
      ): T => {
        if (isRef(source)) {
          return { ...source.value, ...updates }
        } else {
          return { ...source, ...updates }
        }
      }

      initialFilters = cloneAndMerge(initialFilters, newFilters)
      initialPagination = cloneAndMerge(initialPagination, newPagination)
      initialSort = cloneAndMerge(initialSort, newSort)

      fetchData()
    }

    fetchData()

    return {
      data,
      unsubscribe: stopWatch,
      updateParameters,
      lastCacheUpdate: lastCacheChange,
    }
  },

  async createApplication(
    newApplication: Parameters<typeof _addApplication>[0]
  ) {
    return _addApplication(newApplication).then(async (response) => {
      if (!response.error && response.data) {
        const serializedApplication = serializeSoftwareOut(response.data)
        await db
          .insertInto('application')
          .values(serializedApplication)
          .execute()
      }
      lastCacheChange.value = new Date()
      return response
    })
  },

  useApplicationByID(
    id: string | Ref<string | null> | ComputedRef<string | null> | null,
    useFrontendCache = true
  ) {
    const application = ref<SoftwareOut | null>(null)
    const loading = ref(false)
    const error = ref<Error | null>(null)

    const fetchApplication = async () => {
      try {
        const applicationID = unref(id) // Extract reactive or static ID
        if (id === null) {
          application.value = null
          return
        }

        loading.value = true

        if (!applicationID) {
          throw new Error('No ID provided for fetching the application.')
        }
        application.value = await ApplicationStore.getApplicationById(
          applicationID,
          useFrontendCache
        )
        error.value = null
      } catch (err) {
        application.value = null
        error.value = err instanceof Error ? err : new Error(String(err))
      } finally {
        loading.value = false
      }
    }

    // Watch for changes in the ID and refetch application
    const stopWatch = watch(
      () => unref(id),
      async () => {
        await fetchApplication()
      },
      { immediate: true }
    )

    return {
      application,
      loading,
      error,
      fetchApplication,
      unsubscribe: stopWatch,
    }
  },

  async updateApplication(
    id: string,
    update: Parameters<typeof _putApplication>[0]['requestBody']
  ) {
    const response = await _putApplication({
      softwareId: id,
      requestBody: update,
    })
    if (!response.error && response.data) {
      await this.bulkInsertSoftwareOut([response.data])
    }
    lastCacheChange.value = new Date()
    return response
  },
}
